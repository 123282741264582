<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>Artikel aus dem Lager anfordern</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="emitCloseProductRelocationDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container class="departments-container" v-if="dialogArticle">
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Produktinformationen
        </v-alert>

        <v-container class="xs12 product-info-container">
          <v-row>
            <v-col>
              <v-img
                  :src="dialogArticle.articleData.imagePath"
                  width="100px"
                  min-width="100px"
              >
                <template v-slot:placeholder>
                  <ImageLoader/>
                </template>
              </v-img>
            </v-col>
            <v-layout align-center>
              <v-col>
                <v-row v-text="dialogArticle.articleData.name"/>
                <v-row v-if="dialogArticle.articleData.configurator" v-text="createNameFromConfigurator(dialogArticle.articleData.configurator)"/>
                <v-row v-text="dialogArticle.articleData.ordernumber"/>
                <v-row v-if="dialogArticle.articleData.agiqonArticleSupplierQuantity" v-text="'VPE: ' + dialogArticle.articleData.agiqonArticleSupplierQuantity "/>
              </v-col>
            </v-layout>
          </v-row>
          <VerticalSpacer/>

          <v-row class="reorder-quantity-container">
            <v-col class="text-right">
              <v-row class="action-label">Im Markt</v-row>
              <v-row class="text-center reorder-needed-quantity">
                <strong>{{ dialogArticle.articleData.availableStock.M.stock }}x</strong>
              </v-row>
            </v-col>
            <div class="horizontal-separator">
            </div>
            <v-col>
              <v-row class="action-label">Anfordern</v-row>
              <v-row justify="center">
                <v-icon color="green" @click="addQuantity">mdi-plus</v-icon>
                <v-text-field v-model="selectedQuantity"
                              min="0"
                              aria-valuemin="0"
                              :max="maxValue"
                              :aria-valuemax="maxValue"
                              type="number"
                              :rules="inputRules"
                              @input="handleInputChange"
                              @change="handleInputChange"
                />
                <v-icon color="red" @click="removeQuantity">mdi-minus</v-icon>
                <v-tooltip
                    v-model="showToolTip"
                    top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-btn>
                  </template>
                  <span>Zuerst den Lagerplatz scannen/auswählen!</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="info--text action-text-container">
            <p v-text="actionText"/>
          </v-row>
          <VerticalSpacer/>

          <v-row>
            <v-spacer/>
            <v-alert
                width="100%"
                dense
                type="info"
                outlined
            >
              Aktueller Status
            </v-alert>
            <v-spacer/>
            <v-simple-table class="table-full-width table-content">
              <template v-slot:default>

                <tbody>
                <tr>
                  <td>Im Lager angefordert</td>
                  <td>{{ dialogArticle.desiredQuantity }}</td>
                </tr>
                <tr>
                  <td>Im Zulauf</td>
                  <td>{{ dialogArticle.isOnWay ? 'Ja' : 'Nein' }}</td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>

          <v-row class="reorder-button-container">
            <v-btn
                v-show="showAddButton"
                small
                color="success"
                class="ma-2 white--text"
                @click="emitArticleEdit('updateManualReorder')"
                ref="add"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Bestätigen
            </v-btn>
            <v-btn
                v-show="showDoNothingButton"
                small
                color="grey"
                class="ma-2 white--text"
                @click="emitArticleEdit('same')"
                ref="same"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Keine Änder.
            </v-btn>
            <v-btn
                v-show="showSubtractButton"
                small
                color="info"
                class="ma-2 white--text"
                @click="emitArticleEdit('updateManualReorder')"
                ref="subtract"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Reduzieren
            </v-btn>
            <vertical-spacer/>
            <v-btn
                small
                color="error"
                class="ma-2 white--text"
                @click="emitCloseProductRelocationDialog"
            >
              <v-icon
                  left
                  dark
              >
                mdi-close-circle
              </v-icon>
              Abbrechen
            </v-btn>
          </v-row>

        </v-container>

      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
import ImageLoader from "../../Others/ImageLoader";
import VerticalSpacer from "../../Others/VerticalSpacer";

export default {
  components: {VerticalSpacer, ImageLoader},
  props: {
    dialog: Boolean,
    dialogArticle: Object,
    paletteBinLocationId: Number,
    paletteName: String,
    inProcess: Boolean,
    isNew: Boolean
  },
  data: () => ({
    actionText: '',
    startQuantity: 0,
    selectedQuantity: 0,
    maxValue: 0,
    errorContent: '',
    showToolTip: false,
    inputRules: [
      value => value >= 0 || 'Wert muss größer oder gleich 0 sein!'
    ],
    showAddButton: false,
    showDoNothingButton: true,
    showSubtractButton: false
  }),
  created() {
    this.maxValue = this.dialogArticle.articleData.availableStock.L.stock;
  },

  beforeMount() {
    this.selectedQuantity = this.dialogArticle.desiredQuantity;
    this.startQuantity = this.dialogArticle.desiredQuantity;
  },
  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    emitArticleEdit(action) {
      if (action === 'updateManualReorder') {
        this.updateManualReorder();
      } else {
        this.$root.$emit('articleDialogDataChange', 'same');
      }
      this.$root.$emit('articleDialogCloseAction', true);
    },
    updateManualReorder() {
      this.$root.$emit('articleDialogDataChangeStart', 'add');
      if (this.isNew) {
        const params = {
          articleDetailId: this.dialogArticle.articleDetailId,
          departmentId: Number(this.dialogArticle.departmentId),
          desiredQuantity: this.selectedQuantity,
          ordererId: Number(localStorage.getItem('userId'))
        };
        this.$axios.post('manualReorder', params).then(response => {
          if (response) {
            this.bookLog(
                'manualReorder',
                params,
                this.selectedQuantity,
                null,
                null,
                null,
                null,
                'new'
            );
            this.$root.$emit('articleDialogDataChange', 'created');
          }
        });
      } else {
        const params = {
          desiredQuantity: this.selectedQuantity,
          ordererId: Number(localStorage.getItem('userId'))
        };
        this.$axios.put('manualReorder/' + this.dialogArticle.id, params).then(response => {
          if (response) {
            this.bookLog(
                'manualReorder/' + this.dialogArticle.id,
                params,
                this.selectedQuantity,
                null,
                null,
                null,
                null,
                'update'
            );
            this.$root.$emit('articleDialogDataChange', 'updated');
          }
        });
      }
    },
    emitCloseProductRelocationDialog() {
      this.$root.$emit('articleDialogCloseAction', true);
    },
    handleInputChange() {
      this.selectedQuantity = Number(this.selectedQuantity);
      if (this.selectedQuantity > this.maxValue && this.selectedQuantity === 0) {
        this.selectedQuantity = this.maxValue;
      }
      if (this.selectedQuantity === this.startQuantity) {
        this.showAddButton = false;
        this.showDoNothingButton = true;
        this.showSubtractButton = false;
        this.actionText = '';

      } else if (this.selectedQuantity > this.startQuantity) {
        this.showAddButton = true;
        this.showDoNothingButton = false;
        this.showSubtractButton = false;
        this.actionText = `Nachbestellmenge wird auf ${this.selectedQuantity} gesetzt`;
      } else {
        this.showAddButton = false;
        this.showDoNothingButton = false;
        this.showSubtractButton = true;
        this.actionText = `Nachbestellmenge wird auf ${this.selectedQuantity} gesetzt`;
      }
    },
    addQuantity() {
      if (this.dialogArticle.articleData.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.dialogArticle.articleData.agiqonArticleSupplierQuantity) <= this.maxValue) {
          if (this.selectedQuantity % this.dialogArticle.articleData.agiqonArticleSupplierQuantity) {
            this.selectedQuantity += this.dialogArticle.articleData.agiqonArticleSupplierQuantity - (this.selectedQuantity % this.dialogArticle.articleData.agiqonArticleSupplierQuantity);
          } else {
            this.selectedQuantity += this.dialogArticle.articleData.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxValue;
        }
      } else {
        if ((this.selectedQuantity + 1) <= this.maxValue) {
          this.selectedQuantity = ++this.selectedQuantity;
        }
      }
      if (this.selectedQuantity > this.maxValue) {
        this.selectedQuantity = this.maxValue;
      }
      this.handleInputChange();
    },
    removeQuantity() {
      if (this.dialogArticle.articleData.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.startQuantity - this.dialogArticle.articleData.agiqonArticleSupplierQuantity) >= 0) {
          if (this.selectedQuantity % this.dialogArticle.articleData.agiqonArticleSupplierQuantity) {
            const rest = this.dialogArticle.articleData.agiqonArticleSupplierQuantity - this.selectedQuantity % this.dialogArticle.articleData.agiqonArticleSupplierQuantity;
            this.selectedQuantity = this.selectedQuantity - (this.dialogArticle.articleData.agiqonArticleSupplierQuantity - rest);
          } else {
            this.selectedQuantity -= this.dialogArticle.articleData.agiqonArticleSupplierQuantity;
          }
        }
      } else {
        if ((this.selectedQuantity - 1) >= 0) {
          this.selectedQuantity = --this.selectedQuantity;
        }
      }
      if (this.selectedQuantity < 0) {
        this.selectedQuantity = 0;
      }
      this.handleInputChange();
    },
    async bookLog(payloadUrl, payload, quantity, binLocationCodeFrom, binLocationCodeTo, warehouseCodeFrom, warehouseCodeTo, addition) {
      await this.$axios.post('bookingLog', {
        userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
        articleId: Number(this.dialogArticle.articleData.articleID),
        articleDetailId: Number(this.dialogArticle.articleData.articleDetailId),
        articleNumber: this.dialogArticle.articleData.ordernumber,
        articleName: this.dialogArticle.articleData.name,
        quantity,
        binLocationCodeFrom: binLocationCodeFrom,
        binLocationCodeTo: binLocationCodeTo,
        warehouseCodeFrom,
        warehouseCodeTo,
        payload,
        payloadUrl,
        place: `Manual Nachbestellung (${addition})`
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.action-label {
  font-size: 12px;
}

.table-content {
  td {
    font-size: large !important;
  }
}
</style>