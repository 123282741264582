<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template class="rearrange-palette-container" v-if="isLoaded">
      <template class="rearrange-result-container" v-if="result">
        <template v-if="result.data.success === true">
          <v-alert
              dense
              outlined
              type="success"
          >
            <strong>{{ selectedPalette.names.displayName }}</strong> wurde erfolgreich in den <strong>{{
              warehouseToObj.name
            }}</strong> umgebucht.
          </v-alert>
        </template>
        <template v-else>
          <v-alert
              dense
              outlined
              type="error"
          >
            {{ result.data.message }}
          </v-alert>
        </template>
        <v-row>
          <v-spacer/>
          <v-btn
              color="success"
              @click="()=>{this.$router.go()}"
          >
            OK
          </v-btn>
          <v-spacer/>
        </v-row>
      </template>
      <template v-else>
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Von {{ warehouseFromObj.name }} nach {{ warehouseToObj.name }}
        </v-alert>

        <v-container class="palette-information-container">
          <v-alert
              color="primary"
              elevation="24"
              outlined
              v-model="showStartInfo"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
            <p>Scannen Sie die Palette, die vom {{ warehouseFromObj.name }} nach {{ warehouseToObj.name }}
              umgebucht werden soll, um den Prozess abzuschließen.</p>
          </v-alert>
          <v-layout justify-center>
            <v-alert
                color="primary"
                elevation="24"
                outlined
                width="60px"
                v-model="showIconOnly"
                @click="toggleInfo"
            >
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-alert>
          </v-layout>
        </v-container>

        <v-container class="text-align-center">
          <p>{{ selectedPalette ? selectedPalette.names.displayName : 'Palette wählen' }}</p>
          <v-text-field
              :value="selectedPalette ? selectedPalette.code : ''"
              label="Palette scannen"
              outlined
              readonly
              dense
              ref="scannerResult"
              style="margin: auto"
              prepend-icon="mdi-barcode-scan"
          ></v-text-field>
          <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              :disabled="selectedPalette === null"
              @click="relocatePalette"
          >
            <v-icon
                left
                dark
            >
              mdi-cart-arrow-right
            </v-icon>
            Palette<br/>
            umbuchen
          </v-btn>
        </v-container>
        <div v-if="this.palettes && this.palettes.length > 0">
          <h5>Manuelle Auswahl der Palette</h5>
          <v-virtual-scroll
              :items="palettes"
              :item-height="55"
              :height="200"
              class="palette-scroll"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.code" class="palette-list-item">
                <v-btn
                    @click="selectPalette(item)"
                    block
                    color="secondary"
                    class="white--text palette-button"
                    :disabled="item.isEmpty || item.usedIn[0].warehouseCode !== warehouseFromObj.code"
                >
                  {{ item.names.displayName }} <br/>
                </v-btn>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </div>
        <div v-else>
          <v-alert
              dense
              outlined
              type="error"
          >
            Keine Paletten gefunden im {{ warehouseFromObj.name }}!
          </v-alert>
        </div>
      </template>
    </template>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <VerticalSpacer/>
    <v-row>
      <v-spacer/>
      <v-btn
          :to="{ name: 'rearrange.palette', params: {id: this.id} }"
          outlined
          color="primary"
      >
        Zurück
      </v-btn>
      <v-spacer/>
    </v-row>
  </v-container>
</template>


<script>
import VerticalSpacer from "../../Others/VerticalSpacer";
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, LoginRequired, VerticalSpacer},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    warehouses: null,
    warehouseFrom: '',
    warehouseTo: '',
    warehouseFromObj: null,
    warehouseToObj: null,
    isLoaded: false,
    result: null,
    isError: false,
    errorContent: '',
    id: 0,
    palettes: null
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);

    this.warehouseFrom = this.$route.params.from;
    this.warehouseTo = this.$route.params.to;

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
        this.palettes = this.warehouseFromObj.palettes;
        this.palettes = this.palettes.filter(palette => !palette.isEmpty && palette.usedIn[0].warehouseCode === this.warehouseFromObj.code);
        this.warehouseToObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseTo)[0];
        this.isLoaded = true;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    selectPalette(palette) {
      this.selectedPalette = palette;
    },
    relocatePalette() {
      this.isLoaded = false;
      const sourceBinLocation = this.warehouseFromObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0]['id'];
      const destinationBinLocation = this.warehouseToObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0]['id'];

      const sourceBinLocationCode = this.warehouseFromObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0]['code'];
      const destinationBinLocationCode = this.warehouseToObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0]['code'];

      const address = 'palette/' + this.$uuid.v4();

      const params = [{
        sourceBinLocation: Number(sourceBinLocation),
        destinationBinLocation: Number(destinationBinLocation),
        bookingLog: {
          payload: {
            sourceBinLocation: Number(sourceBinLocation),
            destinationBinLocation: Number(destinationBinLocation)
          },
          payloadUrl: address,
          place: 'Paletten umlagern',
          userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
          userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
          binLocationCodeFrom: sourceBinLocationCode,
          binLocationCodeTo: destinationBinLocationCode,
          warehouseCodeFrom: this.warehouseFrom,
          warehouseCodeTo: this.warehouseTo
        }
      }];

      this.$axios.put(address, params).then(response => {
        if (response) {
          this.result = response;
          this.isLoaded = true;
        }
      });

    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const paletteCode = scannerInput.dataAsText.split('$')[1];
      if (!paletteCode) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`${scannerInput.dataAsText} ist keine gültige Eingabe für Lagerplatz!`);
      }
      const targetPaletteFrom = this.warehouseFromObj.palettes.filter(palette => palette.code === paletteCode)[0];
      const targetPaletteTo = this.warehouseToObj.palettes.filter(palette => palette.code === paletteCode)[0];

      if (!targetPaletteFrom || !targetPaletteTo) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} kann nicht gefunden werden!`);
      }

      if (targetPaletteFrom.isEmpty || (!targetPaletteFrom.isEmpty && targetPaletteFrom.usedIn[0].warehouseCode !== this.warehouseFromObj.code)) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} ist leer in ${this.warehouseFromObj.name}!`);
      }

      if (!targetPaletteTo.isEmpty && targetPaletteTo.usedIn[0].warehouseCode === this.warehouseTo.code) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} ist nicht leer in ${this.warehouseToObj.name}!`);
      }

      this.selectPalette(targetPaletteFrom);
      this.$refs.scannerResult.blur();
    },

    handleCloseErrorDialog() {
      this.isError = false;
    },

    setScannerError(message) {
      this.isError = true;
      this.errorContent = message;
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
  }
}
</script>