<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog :show="isError" :errorContent="errorContent"/>
    <LoadingIndicator :loading="isLoading"/>
    <template v-if="result && result.data && result.data.success === true">
      <v-row>
        <v-alert
            dense
            outlined
            type="success"
        >
          <strong>{{ fromPaletteName }}</strong> wurde erfolgreich in das
          <strong>Lager ({{ radioGroup ? radioGroup.names.displayName : 'undefined' }})</strong> umgebucht.
        </v-alert>
      </v-row>
      <v-row>
        <v-spacer/>
        <v-btn
            color="success"
            :to="{name: 'rearrange.warehouse'}"
        >
          OK
        </v-btn>
        <v-spacer/>
      </v-row>

    </template>

    <template v-else>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Lagerplatz im Lager scannen <br/>
        Ausgewählte Palette: {{ `${fromPaletteName} (${fromWarehouseName})` }}
      </v-alert>

      <v-container class="palette-information-container">
        <v-alert
            color="primary"
            elevation="24"
            outlined
            v-model="showStartInfo"
            @click="toggleInfo"
        >
          <v-icon color="primary">mdi-information-outline</v-icon>
          <p>Scannen Sie den Lagerplatz im Lager, auf den die Artikel, die auf der Palette
            <strong>{{ `${fromPaletteName} (aus ${fromWarehouseName})` }}</strong> sind, gebucht werden sollen </p>
        </v-alert>
        <v-layout justify-center>
          <v-alert
              color="primary"
              elevation="24"
              outlined
              width="60px"
              v-model="showIconOnly"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-alert>
        </v-layout>
      </v-container>


      <template v-if="binLocations">
        <v-row>
          <v-text-field
              label="Lagerplatz scannen"
              outlined
              readonly
              dense
              ref="scannerResult"
              prepend-icon="mdi-barcode-scan"
              :value="radioGroup ? radioGroup.code : ''"
          ></v-text-field>
        </v-row>
        <v-row>
          <v-spacer/>
          <v-alert
              dense
              type="info"
              outlined
          >
            Lagerplätze im Lager
          </v-alert>
          <v-spacer/>
          <v-simple-table class="table-full-width table-content">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Lagerplatz
                </th>
                <th class="text-left">
                  Auswählen
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="binLocation in binLocations"
                  :key="binLocation.code"
              >
                <td>{{ binLocation.code }}</td>
                <td>
                  <v-radio-group v-model="radioGroup">
                    <v-radio
                        :key="binLocation.code"
                        :value="binLocation"/>
                  </v-radio-group>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
        <VerticalSpacer/>


      </template>
      <v-row>
        <v-btn
            @click="relocateToWarehouse"
            :disabled="!radioGroup"
            color="success"
        >
          Umlagern
        </v-btn>
        <v-spacer/>
        <v-btn
            :to="{ name: 'rearrange.warehouse' }"
            outlined
            color="primary"
        >
          Zurück
        </v-btn>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";
import LoadingIndicator from "../../Others/LoadingIndicator";
import VerticalSpacer from "../../Others/VerticalSpacer";

export default {
  components: {VerticalSpacer, LoadingIndicator, ErrorDialog, Scanner, LoginRequired},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    isLoading: true,
    result: null,
    isError: false,
    errorContent: '',
    radioGroup: null,
    fromBinLocationId: null,
    fromWarehouseName: '',
    fromPaletteName: '',
    binLocations: null,
    scannerResult: ''
  }),

  created() {
    this.fromBinLocationId = Number(this.$route.params.binLocationId);
    this.fromPaletteName = decodeURI(this.$route.params.paletteName);
    this.fromWarehouseName = decodeURI(this.$route.params.warehouseName);
    this.fromWarehouseCode = decodeURI(this.$route.params.warehouseCode);

    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        const mainWarehouse = response.data.data.filter(warehouse => warehouse.code === 'L')[0];
        this.binLocations = mainWarehouse.binLocations;
        this.isLoading = false;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const binLocationCode = scannerInput.dataAsText.split('$')[1];

      if (!binLocationCode) {
        this.$refs.scannerResult.blur();
        return this.setScannerError(`${scannerInput.dataAsText} ist keine gültige Eingabe für den Lagerplatz!`);
      }

      const doesExists = this.binLocations.filter(binLocation => binLocation.code === binLocationCode).length > 0;
      if (!doesExists) {
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Lagerplatz ${binLocationCode} nicht im Lager gefunden!`);
      }

      this.isError = false;
      this.scannerResult = binLocationCode;
      this.radioGroup = this.binLocations.filter(binLocation => binLocation.code === binLocationCode)[0];
      this.$refs.scannerResult.blur();
    },

    handleCloseErrorDialog() {
      this.isError = false;
    },

    setScannerError(message) {
      this.isError = true;
      this.radioGroup = null;
      this.errorContent = message;
      this.scannerResult = '';
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },

    relocateToWarehouse() {
      this.isLoading = true;

      const address = 'palette/' + this.$uuid.v4() + '?ignoreDestinationPalette=true';

      const params = [{
        sourceBinLocation: this.fromBinLocationId,
        destinationBinLocation: this.radioGroup ? Number(this.radioGroup.id) : null,
        bookingLog: {
          payload: {
            sourceBinLocation: Number(this.fromBinLocationId),
            destinationBinLocation: Number(this.radioGroup.id)
          },
          payloadUrl: address,
          place: 'In Lager einlagern',
          userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
          userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
          binLocationCodeFrom: this.fromPaletteName,
          binLocationCodeTo: this.radioGroup.code,
          warehouseCodeFrom: this.fromWarehouseCode,
          warehouseCodeTo: 'L'
        }
      }];

      this.$axios.put(address, params).then(response => {
        if (response) {
          this.result = response;
          this.isLoading = false;
        }
      });
    },
  }

}
</script>

<style lang="scss">
.table-content {
  max-height: 300px;
  overflow-y: scroll;
}

.palette-information-container {
  margin-bottom: 35px;
}
</style>