<template>
  <v-container>
    <Scanner
      :should-emit="!dialog"
    />
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <LoadingIndicator
        :loading="isLoading"
    />
    <div class="tooltip-container"
         v-if="showToolTip"
         v-on:click.stop.prevent="closeToolTip"
    >
      <v-tooltip
          class="bin-location-tooltip"
          v-model="showToolTip"
          absolute
          :position-x="toolTipX"
          :position-y="toolTipY"
          bottom
          :z-index="998"
          v-on:click.stop.prevent.native="closeToolTip"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
          </v-btn>
        </template>
        <p class="mb-0" v-for="toolTipContentRow in toolTipContent" :key="toolTipContentRow">
          {{ toolTipContentRow }}
        </p>
      </v-tooltip>
    </div>
    <ConfirmDialog ref="confirm"/>
    <template class="departments-container" v-if="department && palettes && picklist">
      <ArticleEditDialog
          v-if="dialogArticle"
          :dialog="dialog"
          :dialogArticle="dialogArticle"
          :paletteBinLocationId="Number(selectedPalette.binLocations.BhfL)"
          :paletteName="selectedPalette.names.displayName"
          :proposedReorder="dialogArticle.proposedReorder && dialogArticle.proposedReorder > 0 ? dialogArticle.proposedReorder : 0"
          :packedAmount="dialogArticle.packedAmount"
      />
      <VerticalSpacer/>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Pickliste: <br /> {{ department.picklistDisplay }} <span
          class="float-right">{{ department.picklist.length + '/' + department.picklist.totalCount }}</span><br/>
        <span>Aktiv: <strong>{{ selectedPalette.names.displayName }}</strong></span>
      </v-alert>

      <v-btn-toggle
          v-model="productFilter"
          @change="filterPicklist"
          multiple
          rounded
          dense
          active-class="is--active"
          class="float-right"
      >
        <v-btn>
          <v-icon color="#009900">mdi-circle</v-icon>
          Komp.
        </v-btn>
        <v-btn>
          <v-icon color="#0066ff">mdi-circle-slice-4</v-icon>
          Teil.
        </v-btn>
        <v-btn>
          <v-icon color="#ff8c00">mdi-circle-outline</v-icon>
          Offen
        </v-btn>
      </v-btn-toggle>

      <VerticalSpacer/>
      <VerticalSpacer/>

      <v-btn-toggle
          v-model="manualFilter"
          @change="filterPicklist"
          multiple
          rounded
          dense
          active-class="is--active"
          class="float-right"
      >
        <v-btn>
          <v-icon color="#01ca01">mdi-alpha-m-box</v-icon>
          Manuell
        </v-btn>
        <v-btn>
          <v-icon color="#e36b02">mdi-alpha-a-box</v-icon>
          Auto
        </v-btn>
      </v-btn-toggle>

      <VerticalSpacer/>
      <VerticalSpacer/>

      <v-text-field
          label="Artikel scannen"
          outlined
          dense
          prepend-icon="mdi-barcode-scan"
          @change="onScannerInputHandler"
          :value="scannedProduct ? scannedProduct.ordernumber : ''"
          ref="scannerResult"
      ></v-text-field>

    </template>

    <v-card
        width="100%"
        class="mx-auto"
        v-if="filteredPicklist.length > 0 && !virtualScrollUpdated"
    >
      <v-virtual-scroll
          :items="filteredPicklist"
          item-height="130"
          min-height="300"
          ref="virtualScroll"
      >
        <template v-slot:default="{ item }">
          <v-list-item
              :key="item.number"
              @click="openEditDialog(item)"
              class="picklist-item"
          >

            <ArticleStatus
                :color="getProductStatusColor(getProductStatus(item))"
            />
            <v-list-item-action>
              <v-card
                  class="pa-2 product-image-container"
                  outlined
                  tile
                  width="50"
                  height="50"
              >
                <v-img :src="item.imagePath">
                  <template v-slot:placeholder>
                    <ImageLoader/>
                  </template>
                </v-img>
              </v-card>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-title v-if="item.configurator">
                {{ createNameFromConfigurator(item.configurator) }}
              </v-list-item-title>
              <v-list-item-title>
                {{ item.ordernumber }}
              </v-list-item-title>
              <v-list-item-title v-if="item.agiqonArticleSupplierQuantity && parseInt(item.agiqonArticleSupplierQuantity) > 0">
                VPE Lager: {{ item.agiqonArticleSupplierQuantity }}
              </v-list-item-title>
              <v-list-item-title>
                Lagerplatz
                <v-icon v-on:click.stop.prevent="displayBinLocationsToolTip(item, $event)">mdi-dots-horizontal</v-icon>
              </v-list-item-title>
              <span v-if="item.manualReorderAmount"
                    class="manually-reordered-info">
              Manuell nachbestellt: {{ item.manualReorderAmount }}
            </span>

            </v-list-item-content>
            <div class="quantity-box-container">
              <div class="quantity-box proposed-quantity"><span
                  class="proposed-quantity-value">{{
                  item.proposedReorder && item.proposedReorder > 0 ? item.proposedReorder : 0
                }}</span>x
              </div>
              <br />
              <div class="quantity-box packed-quantity"><span class="packed-quantity-value">{{
                  item.packedAmount
                }}</span>x
              </div>
            </div>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>

    <VerticalSpacer/>

    <v-row>
      <v-btn
          @click="confirmFinishPicklist"
          color="success"
      >
        Abschließen
      </v-btn>
      <v-spacer/>
      <v-btn
          :to="{ name: 'department', params: {id: this.id} }"
          outlined
          color="primary"
      >
        Zurück
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>

import VerticalSpacer from "../Others/VerticalSpacer";
import ImageLoader from "../Others/ImageLoader";
import ArticleStatus from "../Others/ArticleStatus";
import Scanner from "../Others/Scanner";
import ErrorDialog from "../Others/ErrorDialog";
import ConfirmDialog from "../Others/ConfirmDialog";
import ArticleEditDialog from "./ArticleEditDialog";
import LoadingIndicator from "../Others/LoadingIndicator";

export default {
  components: {
    LoadingIndicator,
    ArticleEditDialog, ConfirmDialog, ErrorDialog, Scanner, ArticleStatus, ImageLoader, VerticalSpacer
  },
  data: () => ({
    id: null,
    paletteCode: null,
    department: null,
    palettes: null,
    selectedPalette: null,
    showStartInfo: false,
    showIconOnly: true,
    productFilter: [0, 1, 2],
    manualFilter: [0, 1],
    picklist: [],
    filteredPicklist: [],
    dialog: false,
    dialogArticle: null,
    isError: false,
    errorContent: '',
    scannedProduct: null,
    virtualScrollUpdated: false,
    paletteContent: null,
    isLoading: false,
    showToolTip: false,
    toolTipContent: [],
    toolTipX: 0,
    toolTipY: 0,
    toolTipTarget: null
  }),
  created() {
    this.isLoading = true;
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$on('articleDialogDataChange', this.handleArticleUpdate);
    this.$root.$on('articleDialogDataChangeStart', this.setLoadingIndicator);


    const routeParams = this.$route.params;
    this.id = routeParams.id;
    this.paletteCode = routeParams.selectedPalette;

    this.$axios.get('departments/' + this.id).then(response => {
      if (response && response.data && response.data.data) {
        if (response.data.data.departments) {
          this.department = response.data.data.departments[0];
          this.calculatePicklist();
          this.calculatePackedAmount();
          this.filterPicklist();
          this.isLoading = false;
        }
        if (response.data.data.palettes) {
          this.palettes = response.data.data.palettes;
          this.selectedPalette = this.palettes.filter(palette => palette.code === this.paletteCode)[0];
          this.getActualPaletteItems();
        }
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$off('articleDialogDataChange', this.handleArticleUpdate);
    this.$root.$off('articleDialogDataChangeStart', this.setLoadingIndicator);
  },

  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    handleArticleUpdate() {
      this.resetDepartmentsEndpoint();
    },
    setLoadingIndicator() {
      this.isLoading = true;
    },
    resetDepartmentsEndpoint() {
      this.isLoading = true;
      this.$axios.get('departments/' + this.id).then(response => {
        if (response && response.data && response.data.data) {
          if (response.data.data.departments) {
            this.department = response.data.data.departments[0];
            this.calculatePicklist();
            this.calculatePackedAmount();
            this.filterPicklist();
            this.isLoading = false;
          }
          if (response.data.data.palettes) {
            this.palettes = response.data.data.palettes;
            this.selectedPalette = this.palettes.filter(palette => palette.code === this.paletteCode)[0];
            this.getActualPaletteItems();
          }
        }
      })
    },
    mergePaletteWithPicklist() {
      for (const product of this.paletteContent) {
        const productPicklist = this.picklist.filter(x => Number(x.articleDetailId) === product.articleDetailId);
        if (productPicklist && productPicklist[0]) {
          productPicklist[0].packedAmount = product.stock;
        } else {
          this.addArticleFromPalette(product);
        }
      }
      this.reloadVirtualScroll();
    },
    calculatePicklist() {
      this.mergeManualReorders();

      let pickListTotalCount = 0;
      for (const product of this.department.picklist) {
        pickListTotalCount += product.proposedReorder;
      }
      this.department.picklist.totalCount = pickListTotalCount;
      this.picklist = this.department.picklist.slice(0);
      this.picklist.totalCount = pickListTotalCount;
    },
    calculatePackedAmount() {
      for (const product of this.picklist) {
        product.packedAmount = 0;
      }
    },
    getProductStatusColor(status) {
      switch (status) {
        case 0:
          return '#009900';
        case 1:
          return '#0066ff';
        case 2:
          return '#ff8c00';
      }
    },
    getProductStatus(item) {
      if (item.packedAmount === 0) {
        return 2;
      } else if (item.packedAmount > 0 && item.proposedReorder > 0) {
        return 1;
      }
      return 0;
    },
    openEditDialog(product) {
      if (typeof product === 'string') {
        this.$axios.get('productInfo/' + product).then(response => {
          if (response.data && response.data.data) {
            this.dialog = true;
            this.dialogArticle = response.data.data;
          }
        })
      } else {
        this.dialog = true;
        this.dialogArticle = product;
      }
    },
    closeProductRelocationDialog() {
      this.dialog = false;
      this.dialogArticle = null;
    },
    onScannerInputHandler(scannerInputRaw) {
      if(!scannerInputRaw) {
        return;
      }

      const scannerInput = this.parseJson(scannerInputRaw);
      let productNumber = scannerInput.dataAsText;

      if(productNumber === 'none' || !productNumber) {
        productNumber = scannerInputRaw
      }

      this.$axios.get('productInfo/' + productNumber).then(response => {
        if (response.data && response.data.data) {
          if(response.data.data.availableStock['L'].stock - response.data.data.availableStock['L'].reservedStock > 0) {
            this.isError = false;
            this.scannedProduct = response.data.data;
            this.updatePicklistWithArticle();
          } else {
           this.isError = true;
           this.scannedProduct = null;
           this.errorContent = `Produkt (${response.data.data.ordernumber}) hat keinen verfügbaren Bestand im Hauptlager...`;
          }
        } else if (response.data && response.data.success === false) {
          this.isError = true;
          this.errorContent = response.data.message;
          this.scannedProduct = null;
        }
        this.$refs.scannerResult.blur();
      })
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    updatePicklistWithArticle() {
      if (!this.getArticleFromPicklist()) {
        this.$refs.confirm.open('Nicht enthalten', 'Der gescannte Artikel ist nicht in der Auswahlliste aufgeführt. Möchten Sie ihn trotzdem hinzufügen?', {color: 'info'}).then((confirm) => {
          if (confirm === true) {
            const addedArticle = this.addArticleToPicklist();
            this.openEditDialog(addedArticle);
          }
        });
      } else {
        const articleFromPicklist = this.getArticleFromPicklist();
        if (articleFromPicklist) {
          this.openEditDialog(articleFromPicklist);
        }
      }
    },
    getArticleFromPicklist() {
      return this.picklist.filter(article => article.ordernumber === this.scannedProduct.ordernumber)[0];
    },
    addArticleToPicklist() {
      this.isLoading = true;
      this.picklist.push(this.scannedProduct);
      this.calculatePackedAmount();
      this.mergePaletteWithPicklist();
      this.filterPicklist();
      this.reloadVirtualScroll();
      this.isLoading = false;
      return this.scannedProduct;
    },
    getActualPaletteItems() {
      this.isLoading = true;
      this.$axios.get('binLocations/' + this.selectedPalette.binLocations.BhfL).then(response => {
        if (response.data && response.data.data) {
          this.paletteContent = response.data.data.articleDetailBinLocationMappings;
          this.mergePaletteWithPicklist();
          this.isLoading = false;
        }
      });
    },
    mergeManualReorders() {
      const manualReordersToAdd = [];
      for (const manualReorder of this.department.manualReorders) {
        const targetPicklist = this.department.picklist.filter(
            article => Number(article.articleDetailId) === Number(manualReorder.articleDetailId)
        );
        if (targetPicklist.length > 0) {
          targetPicklist[0].proposedReorder += Number(manualReorder.reorderedQuantity);
        } else {
          manualReorder.proposedReorder = Number(manualReorder.reorderedQuantity);
          manualReorder.manualReorderAmount = Number(manualReorder.reorderedQuantity);
          manualReordersToAdd.push(manualReorder);
        }
      }

      this.department.picklist = [...manualReordersToAdd, ...this.department.picklist];

      if (this.department.picklist && this.department.picklist.length > 0) {
        for (const picklistArticle of this.department.picklist) {
          picklistArticle.proposedReorder -= picklistArticle.actuallyPicked;
        }
        this.department.picklist = this.department.picklist.filter(article => article.proposedReorder > 0);
      }

      const manualReorders = this.department.picklist.filter(article => article.manualReorderAmount > 0);
      this.department.picklist = this.department.picklist.filter(article => article.manualReorderAmount <= 0);

      manualReorders.sort((a, b) => {
        if (!a.availableStock.L.binLocations || a.availableStock.L.binLocations.length === 0) {
          return 1;
        }

        if (!b.availableStock.L.binLocations || b.availableStock.L.binLocations.length === 0) {
          return -1;
        }

        let binLocationA = a.availableStock.L.binLocations[0].code;
        let binLocationB = b.availableStock.L.binLocations[0].code;

        if (this.isNumeric(binLocationA) && !this.isNumeric(binLocationB)) {
          return -1;
        }

        if (!this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return 1;
        }

        if (this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return binLocationA - binLocationB;
        }

        return binLocationA.localeCompare(binLocationB);
      });

      this.department.picklist = [...manualReorders, ...this.department.picklist];
    },
    isNumeric(value) {
      return !isNaN(value) && isFinite(value);
    },
    reloadVirtualScroll() {
      this.virtualScrollUpdated = true;
      setTimeout(() => {
        this.virtualScrollUpdated = false
      }, 1);
    },
    filterPicklist() {
      this.isLoading = true;
      let completed = [];
      let partiallyCompleted = [];
      let open = [];

      if (this.productFilter.includes(0)) {
        completed = this.picklist.filter(item =>
            item.proposedReorder === 0
        );
      }

      if (this.productFilter.includes(1)) {
        partiallyCompleted = this.picklist.filter(item =>
            item.packedAmount > 0 && item.proposedReorder > 0
        );
      }

      if (this.productFilter.includes(2)) {
        open = this.picklist.filter(item =>
            item.packedAmount === 0
        );
      }

      this.filteredPicklist = [...completed, ...partiallyCompleted, ...open].unique();

      if(this.manualFilter.includes(0) && !this.manualFilter.includes(1)) {
        this.filteredPicklist = this.filteredPicklist.filter(item =>
          item.manualReorderAmount > 0
        );
      } else if(!this.manualFilter.includes(0) && this.manualFilter.includes(1)) {
        this.filteredPicklist = this.filteredPicklist.filter(item =>
          item.manualReorderAmount <= 0
        );
      } else if(!this.manualFilter.includes(0) && !this.manualFilter.includes(1)) {
        this.filteredPicklist = [];
      }

      this.reloadVirtualScroll();
      this.isLoading = false;
    },
    addArticleFromPalette(product) {
      this.$axios.get('productInfo/' + Number(product.articleDetailId) + '?useId=true').then(response => {
        if (response.data && response.data.data) {
          response.data.data.packedAmount = Number(product.stock);
          this.picklist.push(response.data.data);
          this.filterPicklist();
          this.reloadVirtualScroll();
        }
      })
    },
    confirmFinishPicklist() {
      this.$refs.confirm.open('Pickliste', 'Möchten Sie die Pickliste wirklich abschließen?', {color: 'info'}).then((confirm) => {
        if (confirm === true) {
          this.$router.push({name: 'departments'});
        }
      });
    },
    displayBinLocationsToolTip(item, event) {
      this.toolTipContent = [];
      for (const binLocation of item.availableStock.L.binLocations) {
        this.toolTipContent.push(binLocation.nameData.displayName);
      }
      if (event.target !== this.toolTipTarget) {
        this.showToolTip = true;
      } else {
        this.showToolTip = !this.showToolTip;
      }
      this.toolTipX = event.x;
      this.toolTipY = event.y;
      this.toolTipTarget = event.target;
    },
    closeToolTip() {
      this.showToolTip = false;
      this.toolTipContent = [];
    }
  }
};
</script>

<style lang="scss">
.tooltip-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin: 0;
  padding: 0;
  border: 0;
}

.is--active {
  background-color: aqua;
}

.quantity-box {
  width: 60px;
  height: 50px;
  border: 1px solid black;
  border-radius: 3px;
  font-weight: bold;
  text-align: right;
  display: inline-block;
}

.quantity-box-container {
  margin-right: 15px;
}

.packed-quantity {
  border-top: none;
}

.picklist-item {
  padding: 0 !important;
  min-height: 150px;
}
</style>
