<template>
  <div>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Produkt scannen
      </v-alert>
      <v-alert
          v-if="result"
          dismissible
          type="info"
      >
        {{ result }}
      </v-alert>

      <v-container class="text-align-center">
        <p>Produkt scannen</p>
        <v-text-field
            v-model="scannerResult"
            label="Produkt Barcode Wert"
            ref="scannerResult"
            outlined
            class="product-number"
            dense
            @change="loadScannedProduct"
            style="margin: auto"
        ></v-text-field>
      </v-container>
    </template>
      <div class="centerize">
      <v-btn
          :to="{ name: 'rearrange.between_warehouse' }"
          outlined
          color="primary"
      >
        Zurück
      </v-btn>
        <br />
        <br />
      <v-btn
          :to="{ name: 'rearrange.between_warehouse_select_from', params: {
              warehouseId: this.warehouseId,
              warehouseCode: this.warehouseCode,
              articleNumber: this.articleData ? this.articleData.ordernumber : 'unknown',
              articleDetailId: this.articleData ? this.articleData.articleDetailId : 'unknown'
          }}"
          color="success"
          :disabled="!articleData"
      >
        Lagerplatz auswählen
      </v-btn>
      </div>
  </div>
</template>


<script>
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, LoginRequired},
  data: () => ({
    selectedPalette: null,
    selectedProduct: null,
    warehouseCode: null,
    warehouseId: null,
    isLoaded: false,
    result: null,
    scannerResult: '',
    isError: false,
    errorContent: '',
    articleData: null
  }),
  created() {
    this.selectedPalette = this.$route.params.selectedPalette;
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.warehouseCode = this.$route.params.warehouseCode;
    this.warehouseId = parseInt(this.$route.params.warehouseId);
  },

  beforeDestroy() {
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  methods: {
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    onScannerInputHandler(scannerResponse) {
      scannerResponse = this.parseJson(scannerResponse);
      this.articleData = null;
      this.scannerResult = scannerResponse.dataAsText;
      this.$refs.scannerResult.$emit('change');
    },
    loadScannedProduct() {
      if (this.scannerResult) {
        this.isLoading = true;
        this.$axios.get('productInfo/' + this.scannerResult).then(response => {
          this.isLoading = false;
          if (response.data && response.data.success === false) {
            this.isError = true;
            this.errorContent = response.data.message;
            this.scannerResult = '';
            this.articleData = null;
          }
          if (response.data && response.data.data) {
            this.isError = false;
            this.errorContent = '';
            this.articleData = response.data.data;
          }
          this.$refs.scannerResult.blur();
        })
      }
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    }
  }
}
</script>

<style scoped>
.centerize {
  text-align: center;
  margin: auto;
}
</style>