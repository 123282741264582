<template>
  <v-dialog v-model="loading" fullscreen class="loading-indicator">
    <v-container fluid fill-height style="background-color: rgba(115,114,114,0.5);">
      <v-layout justify-center align-center>
        <v-progress-circular
            indeterminate
            color="primary">
        </v-progress-circular>
      </v-layout>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  props: {
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
.loading-indicator {
  z-index: 99999;
}
</style>