<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
        v-if="!dialogArticle"
    />
    <LoadingIndicator
        :loading="isLoading"
    />
    <ConfirmDialog ref="confirm"/>
    <EditDialog
        v-if="dialogArticle"
        :dialog="dialog"
        :dialogArticle="dialogArticle"
        :isNew="isNew"
        :inProgress="isOnPalette(dialogArticle)"
    />

    <v-alert
        color="primary"
        elevation="24"
        icon="mdi-information-outline"
        type="info"
    >
      Manuelle Anforderung
    </v-alert>

    <v-btn-toggle
        v-model="productFilter"
        @change="filterManualReorderData"
        multiple
        rounded
        dense
        active-class="is--active"
        class="float-right"
    >
      <v-btn>
        <v-icon color="#0066ff">mdi-airplane</v-icon>
        Im Zulauf
      </v-btn>
      <v-btn>
        <v-icon color="#ff8c00">mdi-circle-outline</v-icon>
        Offen
      </v-btn>
    </v-btn-toggle>

    <VerticalSpacer/>
    <VerticalSpacer/>

    <v-row>
      <v-text-field
          label="Artikel scannen"
          :value="scannedProduct ? scannedProduct.ordernumber : ''"
          outlined
          ref="scannerResult"
          dense
          @change="onScannerInputHandler"
          prepend-icon="mdi-barcode-scan"

      ></v-text-field>
    </v-row>
    <VerticalSpacer/>
    <v-card
        width="100%"
        class="mx-auto"
        v-if="filteredManualReorderData && !virtualScrollUpdated"
    >
      <v-virtual-scroll
          :items="filteredManualReorderData"
          item-height="150"
          ref="virtualScroll"
      >
        <template v-slot:default="{ item }">
          <v-list-item
              :key="item.articleData.number"
              @click="openEditDialog(item, false)"
              class="picklist-item"
          >

            <ArticleStatus
                style="margin-top: 33px;"
                :color="getProductStatusColor(getProductStatus(item))"
            />
              <v-card
                  class="pa-2 product-image-container"
                  outlined
                  tile
                  width="50"
                  height="50"
              >
                <v-img :src="item.articleData.imagePath">
                  <template v-slot:placeholder>
                    <ImageLoader/>
                  </template>
                </v-img>
              </v-card>
              <v-icon v-if="!isOnPalette(item)" v-on:click.stop.prevent="confirmDeleteItem(item)" class="delete-icon">
                mdi-delete
              </v-icon>
              <v-icon v-else class="delete-icon hidden">mdi-delete</v-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ item.articleData.name }}
              </v-list-item-title>
              <v-list-item-title v-if="item.articleData.configurator">
                {{ createNameFromConfigurator(item.articleData.configurator) }}
              </v-list-item-title>
              <v-list-item-title>
                {{ item.articleData.ordernumber }}
              </v-list-item-title>
              <v-list-item-title v-if="item.articleData.agiqonArticleSupplierQuantity">
                VPE Lager: {{ item.articleData.agiqonArticleSupplierQuantity }}
              </v-list-item-title>
              <v-list-item-title>
                Lagerplatz {{ item.articleData.availableStock.M.binLocations[0].nameData.displayName }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-action>
              <v-col class="quantity-box proposed-quantity"><span
                  class="proposed-quantity-value">{{
                  item.desiredQuantity
                }}</span>x
              </v-col>
              <v-col class="quantity-box packed-quantity"><span class="packed-quantity-value">{{
                  item.articleData.availableStock.M.stock
                }}</span>x
              </v-col>
            </v-list-item-action>

          </v-list-item>


          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";
import LoadingIndicator from "../../Others/LoadingIndicator";
import ConfirmDialog from "../../Others/ConfirmDialog";
import VerticalSpacer from "../../Others/VerticalSpacer";
import ArticleStatus from "../../Others/ArticleStatus";
import ImageLoader from "../../Others/ImageLoader";
import EditDialog from "./EditDialog";

export default {
  components: {
    EditDialog,
    ImageLoader,
    ArticleStatus, VerticalSpacer, ConfirmDialog, LoadingIndicator, ErrorDialog, Scanner, LoginRequired
  },
  data: () => ({
    isError: false,
    errorContent: '',
    isLoading: true,
    productFilter: [0, 1],
    scannedProduct: '',
    manualReorderData: [],
    filteredManualReorderData: [],
    virtualScrollUpdated: false,
    dialog: true,
    dialogArticle: null,
    bahnhofLagerPalettes: [],
    isNew: false
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$on('articleDialogDataChangeStart', this.onArticleDialogDataChangeStart);
    this.$root.$on('articleDialogDataChange', this.onArticleDialogDataChange);

    this.getManualReorders();
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$off('articleDialogDataChangeStart', this.onArticleDialogDataChangeStart);
    this.$root.$off('articleDialogDataChange', this.onArticleDialogDataChange);
  },

  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    onArticleDialogDataChangeStart() {
      this.isLoading = true;
    },
    onArticleDialogDataChange() {
      this.getManualReorders();
    },
    getManualReorders() {
      const me = this;
      this.$axios.get('manualReorder').then(response => {
        if (response.data && response.data.data) {
          this.manualReorderData = response.data.data;
          this.$axios.get('palette').then(response => {
            if (response && response.data && response.data.data) {
              me.bahnhofLagerPalettes = (response.data.data).filter(warehouse => warehouse.code === 'BhfL')[0];
              me.assignInDelivery();
              me.filterManualReorderData();
              me.reloadVirtualScroll();
              me.isLoading = false;
              if (me.$route.params.articleData) {
                me.$root.$emit('onScannerInput', JSON.stringify({dataAsText: me.$route.params.articleData.ordernumber}));
                me.$route.params.articleData = null;
              }
            }
          });
        }
      });
    },
    assignInDelivery() {
      for (let i = 0; i < this.manualReorderData.length; i++) {
        // [AN-36] Right now we check whole stocks from BhfM or BhfL
        // const isTargetOnPalette = this.bahnhofLagerPalettes.palettes.filter(
        //     palette => palette.items && palette.items.filter(
        //         item => Number(item.articleDetailId) === Number(this.manualReorderData[i].articleDetailId)
        //     ).length > 0
        // );

        const stocks = this.manualReorderData[i]['articleData']['availableStock'];
        const bhfmStock = stocks['BhfM']['stock'] - stocks['BhfM']['reservedStock'];
        const bhflStock = stocks['BhfL']['stock'] - stocks['BhfL']['reservedStock'];

        this.manualReorderData[i]['isOnWay'] = bhfmStock > 0 || bhflStock > 0;
      }
    },
    getProductStatusColor(status) {
      switch (status) {
        case 0:
          return '#0066ff';
        case 1:
          return '#ff8c00';
      }
    },
    getProductStatus(item) {
      if (this.isOnPalette(item)) {
        return 0;
      } else {
        return 1;
      }
    },
    filterManualReorderData() {
      let onWay = [];
      let open = [];

      if (this.productFilter.includes(0)) {
        onWay = this.manualReorderData.filter(item => item.isOnWay);
      }

      if (this.productFilter.includes(1)) {
        open = this.manualReorderData.filter(item => !item.isOnWay);
      }

      this.filteredManualReorderData = [...onWay, ...open].unique();
      this.reloadVirtualScroll();
    },
    openEditDialog(product, isNew = null) {
      this.dialog = true;
      this.dialogArticle = product;
      if (isNew !== null) {
        this.isNew = isNew;
      }
    },
    reloadVirtualScroll() {
      this.virtualScrollUpdated = true;
      setTimeout(() => {
        this.virtualScrollUpdated = false;
      }, 1);
    },
    closeProductRelocationDialog() {
      this.dialog = false;
      this.dialogArticle = null;
    },
    confirmDeleteItem(item) {
      this.$refs.confirm.open('Bestellung löschen',
          `Möchten Sie die offene Bestellung über ${item.desiredQuantity}x (${item.articleData.ordernumber}) wirklich löschen?`, {color: 'info'}).then((confirm) => {
        if (confirm === true) {
          this.deleteItem(item);
        }
      })
    },
    deleteItem(item) {
      this.isLoading = true;
      this.$axios.delete('manualReorder/' + item.id).then(response => {
        if (response.data && response.data.data) {
          this.isError = false;
          this.errorContent = '';
          this.getManualReorders();
        } else {
          this.isError = true;
          this.errorContent = response.data.message;
        }
      })
    },
    isOnPalette(item) {
      if (!item) {
        return false;
      }
      return item.isOnWay === true;
    },
    onScannerInputHandler(scannerInputRaw) {
      if(!scannerInputRaw) {
        return;
      }
      const scannerInput = this.parseJson(scannerInputRaw);
      let productNumber = scannerInput.dataAsText;

      if(productNumber === 'none' || !productNumber) {
        productNumber = scannerInputRaw;
      }
      this.$axios.get('productInfo/' + productNumber).then(response => {
        if (response.data && response.data.data) {
          if(response.data.data.availableStock['L'].stock - response.data.data.availableStock['L'].reservedStock > 0) {
            this.isError = false;
            this.scannedProduct = response.data.data;
            this.updatePicklistWithArticle();
          } else {
            this.isError = true;
            this.scannedProduct = null;
            this.errorContent = `Produkt (${response.data.data.ordernumber}) hat keinen verfügbaren Bestand im Hauptlager...`;
          }
        } else if (response.data && response.data.success === false) {
          this.isError = true;
          this.errorContent = response.data.message;
          this.scannedProduct = null;
        }
        this.$refs.scannerResult.blur();
      });
    },
    updatePicklistWithArticle() {
      if (!this.getArticleFromPicklist()) {
        const addedArticle = this.addArticleToPicklist();
        this.isNew = true;
        this.openEditDialog(addedArticle);
      } else {
        const articleFromPicklist = this.getArticleFromPicklist();
        this.isNew = false;
        if (articleFromPicklist) {
          this.openEditDialog(articleFromPicklist);
        }
      }
    },
    getArticleFromPicklist() {
      return this.manualReorderData.filter(article => article.articleData.ordernumber === this.scannedProduct.ordernumber)[0];
    },
    addArticleToPicklist() {
      return {
        articleData: this.scannedProduct,
        articleDetailId: Number(this.scannedProduct.articleDetailId),
        departmentId: this.getDepartmentIdFromScannedArticle(),
        id: Number(this.scannedProduct.articleID),
        desiredQuantity: 0
      };
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    handleCloseErrorDialog() {
      this.isError = false;
      this.errorContent = '';
    },

    getDepartmentIdFromScannedArticle() {
      const marketBinLocations = this.scannedProduct.availableStock.M.binLocations;
      const department = marketBinLocations.filter(binLocation => binLocation.code.substring(0, 3) !== '99_');
      if (department.length > 0) {
        return department[0].id;
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.article-status {
  margin-bottom: 32px;
}

.delete-icon {
  margin-right: 30px;
}

.hidden {
  visibility: hidden;
}

</style>
