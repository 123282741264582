<template>
  <v-container>
    <LoginRequired/>
    <LoadingIndicator
        :loading="isLoading"/>
    <ErrorDialog
        :show="showError"
        :errorContent="errorContent"/>
    <v-alert
        color="primary"
        elevation="24"
        icon="mdi-information-outline"
        type="info"
    >
      Im {{ selectedWarehouse ? selectedWarehouse.name :  '...' }} umlagern
    </v-alert>
    <VerticalSpacer/>

    <template v-if="!isLoading">
      <v-select
          v-model="selectedWarehouse"
          :items="warehouses"
          item-value="code"
          item-text="name"
          label="Lager auswählen"
          solo
          return-object
      ></v-select>
      <VerticalSpacer/>
      <VerticalSpacer/>

      <v-btn
          color="success"
          elevation="2"
          large
          block
          :disabled="!selectedWarehouse"
          :to="{name: 'rearrange.between_warehouse_select_product', params: {
            warehouseId: selectedWarehouse ? selectedWarehouse.id : 'unknown',
            warehouseCode: selectedWarehouse ? selectedWarehouse.code : 'unknown'
          }}"
      >Bestätigen
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import LoadingIndicator from "../../Others/LoadingIndicator";
import ErrorDialog from "../../Others/ErrorDialog";
import VerticalSpacer from "../../Others/VerticalSpacer";

export default {
  components: {VerticalSpacer, ErrorDialog, LoadingIndicator, LoginRequired},
  data: () => ({
    isLoading: true,
    warehouses: null,
    showError: false,
    errorContent: '',
    selectedWarehouse: null
  }),
  created() {
    this.$axios.get('warehouses').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        this.selectedWarehouse = response.data.data.filter(warehouse => warehouse.code === 'L')[0];
      } else {
        this.showError = true;
        this.errorContent = response.data.message;
      }
      this.isLoading = false;
    });
  }
}
</script>

<style lang="scss">
.v-list-item--disabled {
  display: none;
}
</style>