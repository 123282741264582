<template>
  <v-dialog
      v-model="show"
      fullscreen
      :overlay-opacity="0"
      class="dialog"
      transition="dialog-bottom-transition"
  >
    <v-card class="dialog-v-card" height="100%">
      <v-container class="complaint-detail-container" v-if="complaint" id="printMe">
        <v-container class="xs12 product-info-container">
          <p>{{ complaint.createdDate }} <br />
            <strong>{{ complaint.quantity }} x {{ complaint.articleName }}</strong> <br />
            <span v-if="complaint.configurator">
              {{createNameFromConfigurator(complaint.configurator) }}<br/></span>

            {{ complaint.articleNumber }} <br />

            Reklamationsgrund: <strong>{{ complaint.reason }}</strong> <br />
            <span v-if="complaint.deliveryNumber">
          Lieferscheinnummer: <strong>{{ complaint.deliveryNumber }}</strong> <br />
        </span>


            <span v-if="complaint.problem">
              Reklamationsdetail: <strong>{{ complaint.problem }}</strong> <br/>
            </span>
          </p>

          <template v-if="complaint.description">
          <p>Problembeschreibung: <br />
            {{ complaint.description }}
          </p>
          </template>

          <span v-if="complaint.images && !complaint.images[0].noPicture">
          Bilder: <br />
          <v-row class="image-grid-container">
            <v-col
                v-for="image in complaint.images"
                :key="image.id"
                :value="image"
                class="d-flex child-flex"
                cols="4"
            >
              <img
                  alt=""
                  :src="image.path"
                  class="grey lighten-2"
                  width="150"
              />
            </v-col>
          </v-row>

          <VerticalSpacer />
          </span>

        </v-container>

        <v-row class="no-print">
          <v-spacer/>
          <v-btn
              @click="onAfterPrint"
              color="secondary"
              class="no-print"
          >
            Schließen
          </v-btn>
          <v-spacer/>
        </v-row>

      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import VerticalSpacer from "../../Others/VerticalSpacer";
export default {
  components: {VerticalSpacer},
  props: {
    show: Boolean,
    complaint: Object
  },

  mounted() {
    window.scrollTo(0, 0);
    setTimeout(function() {window.print()}, 300);
  },

  methods: {
    onAfterPrint() {
      this.$root.$emit('detailDialogClosed', true);
      this.$root.$emit('printDialogClosed', true);
    },
    closeErrorDialog() {
      this.$root.$emit('detailDialogClosed', true);
    },
    createNameFromConfigurator(configurator) {
      let name = '';
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    }
  }

}
</script>

<style lang="scss">
.preview-container {
  background-color: black;
}

@media print
{
  .dialog-v-card {
    width: 100%;
    height: 100%;
    display: flex !important;
    z-index: 9999;
  }

  .no-print, .no-print *
  {
    display: none !important;
  }
}
</style>