var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('LoginRequired'),(!_vm.booked)?_c('Scanner'):_vm._e(),_c('LoadingIndicator',{attrs:{"loading":_vm.isLoading}}),_c('ErrorDialog',{attrs:{"show":_vm.showError,"errorContent":_vm.errorContent}}),_c('v-alert',{attrs:{"color":"primary","elevation":"24","icon":"mdi-information-outline","type":"info"}},[_vm._v(" Artikel "+_vm._s(_vm.articleNumber)+" in Lager ("+_vm._s(_vm.warehouseCode)+") von "+_vm._s(_vm.selectedBinLocation ? _vm.selectedBinLocation.nameData.displayName : '...')+" mit Menge "+_vm._s(_vm.selectedQuantity)+" nach "+_vm._s(_vm.targetBinLocation ? _vm.targetBinLocation.nameData.displayName : '...')+" ")]),_c('VerticalSpacer'),(!_vm.booked)?_c('v-container',{staticClass:"scanner-input"},[_c('v-row',[_c('v-text-field',{ref:"scannerResult",attrs:{"label":"Lagerplatz scannen","prepend-icon":"mdi-barcode-scan","outlined":"","dense":"","value":this.targetBinLocation ? this.targetBinLocation.code : ''},on:{"change":_vm.selectBinLocation}})],1)],1):_vm._e(),_c('VerticalSpacer'),(!_vm.isLoading)?[(!_vm.booked)?_c('div',[_c('VerticalSpacer'),_c('VerticalSpacer'),_c('div',{staticClass:"centerize"},[_c('v-btn',{attrs:{"to":{ name: 'rearrange.between_warehouse_select_from', params: {
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            articleNumber: this.articleNumber,
            articleDetailId: this.articleDetailId
          }},"outlined":"","color":"primary"}},[_vm._v(" Zurück ")]),_c('br'),_c('br'),_c('v-btn',{attrs:{"color":"success","elevation":"2","large":"","block":"","disabled":!_vm.targetBinLocation},on:{"click":_vm.book}},[_vm._v("Buchen ")])],1)],1):_c('div',[_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" Erfolgreich umgelagert. ")]),_c('VerticalSpacer'),_c('VerticalSpacer'),_c('v-btn',{attrs:{"color":"success","elevation":"2","large":"","block":"","to":{ name: 'rearrange.between_warehouse_select_from', params: {
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            articleNumber: this.articleNumber,
            articleDetailId: this.articleDetailId
          }}}},[_vm._v("OK ")])],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }