<template>
  <v-container>
    <LoginRequired/>
    <LoadingIndicator
        :loading="isLoading"/>
    <ErrorDialog
        :show="showError"
        :errorContent="errorContent"/>
    <v-alert
        color="primary"
        elevation="24"
        icon="mdi-information-outline"
        type="info"
    >
      Komplette Palette von {{ selectedFromCode ? selectedFromCode.name : '...' }} nach
      {{ selectedToCode ? selectedToCode.name : '...' }} umlagern
    </v-alert>
    <VerticalSpacer/>

    <template v-if="!isLoading">
      <v-select
          v-model="selectedFromCode"
          :items="warehouses"
          :item-disabled="disableFromItem"
          item-value="code"
          item-text="name"
          label="Von"
          solo
          return-object
          @change="onFromChange"
      ></v-select>

      <v-select
          v-model="selectedToCode"
          :items="warehouses"
          :item-disabled="disableToItem"
          item-value="code"
          item-text="name"
          label="Nach"
          solo
          return-object
      ></v-select>
      <VerticalSpacer/>
      <VerticalSpacer/>

      <v-btn
          color="success"
          elevation="2"
          large
          block
          :disabled="!selectedFromCode || !selectedToCode"
          :to="{name: 'rearrange.palette.select_palette', params: {
            from: selectedFromCode ? selectedFromCode.code : 'unknown',
            to: selectedToCode ? selectedToCode.code : 'unknown'
          }}"
      >Bestätigen
      </v-btn>
    </template>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import LoadingIndicator from "../../Others/LoadingIndicator";
import ErrorDialog from "../../Others/ErrorDialog";
import VerticalSpacer from "../../Others/VerticalSpacer";

export default {
  components: {VerticalSpacer, ErrorDialog, LoadingIndicator, LoginRequired},
  data: () => ({
    isLoading: true,
    warehouses: null,
    showError: false,
    errorContent: '',
    selectedFromCode: null,
    selectedToCode: null
  }),
  created() {
    this.$axios.get('warehouses').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data.filter(warehouse => warehouse.code !== 'L');
      } else {
        this.showError = true;
        this.errorContent = response.data.message;
      }
      this.isLoading = false;
    });
  },

  methods: {
    disableFromItem(warehouse) {
      return warehouse && warehouse.code === this.selectedToCode?.code;
    },
    disableToItem(warehouse) {
      return warehouse && warehouse.code === this.selectedFromCode?.code;
    },
    onFromChange(warehouse) {
      if (!warehouse) {
        return;
      }
      switch (warehouse.code) {
        case 'BhfL':
          this.selectedToCode = this.warehouses.filter(warehouse => warehouse.code === 'BhfM')[0];
          break;
        case 'BhfM':
          this.selectedToCode = this.warehouses.filter(warehouse => warehouse.code === 'M')[0];
          break;
        case 'M':
          this.selectedToCode = this.warehouses.filter(warehouse => warehouse.code === 'BhfM')[0];
          break;
      }
    }
  }
}
</script>

<style lang="scss">
.v-list-item--disabled {
  display: none;
}
</style>