<template>
  <v-container fluid>
    <LoginRequired/>
    <LoadingIndicator :loading="isLoading"/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <SuccessDialog
        :show="isSuccess"
        :content="successContent"
    />
    <ConfirmDialog ref="confirm"/>
    <TakePhoto :show="showTakePhotoDialog"/>
    <PreviewPhoto :show="showImagePreview" :imageSrc="imagePreviewSrc"/>

    <v-container>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Reklamation anmelden
      </v-alert>
    </v-container>

    <v-container class="scanner-input">
      <v-row>
        <v-text-field
            v-model="articleNumber"
            @change="loadScannedProduct"
            label="Artikel scannen"
            prepend-icon="mdi-barcode-scan"
            outlined
            ref="scannerResult"
            dense
        >

        </v-text-field>
      </v-row>
    </v-container>

    <v-container v-if="articleData" class="xs12 product-info-container">
      <v-row>
        <v-col>
          <v-img
              :src="articleData.imagePath"
              width="100px"
              min-width="100px"
          >
            <template v-slot:placeholder>
              <ImageLoader/>
            </template>
          </v-img>
        </v-col>
        <v-layout align-center>
          <v-col>
            <v-row v-text="articleData.name"/>
            <v-row v-if="articleData.configurator" v-text="createNameFromConfigurator(articleData.configurator)"/>
            <v-row v-text="articleData.ordernumber"/>
            <v-row v-if="articleData.agiqonArticleSupplierQuantity" v-text="'VPE Lager: ' + articleData.agiqonArticleSupplierQuantity "/>
          </v-col>
        </v-layout>
      </v-row>
      <VerticalSpacer/>
      <VerticalSpacer/>
      <v-form
          ref="form"
          v-model="isValid"
          lazy-validation
      >
        <v-row>
          <v-col cols="2">
            <v-icon class="quantity-button" color="green" @click="addQuantity">mdi-plus</v-icon>
          </v-col>
          <v-col cols="8">
            <v-text-field v-model="selectedQuantity"
                          :min="minValue"
                          label="Anzahl"
                          :aria-valuemin="0"
                          @change="handleQuantityChange"
                          type="number"
                          filled
                          class="quantity-field"
                          :rules="rules"
                          required
            />
          </v-col>
          <v-col cols="2">
            <v-icon class="quantity-button" color="red" @click="removeQuantity">mdi-minus</v-icon>
          </v-col>
        </v-row>

        <v-select
            v-model="selectedReason"
            :items="reason"
            label="Reklamationsgrund"
            filled
            :rules="rules"
            required
            @change="onReasonChange"
        />

        <v-select
            v-model="selectedProblem"
            :items="problem"
            label="Detailierung"
            filled
            @change="onProblemChange"
            v-show="showProblemField"
            :rules="showProblemField ? rules : []"
        />

        <v-textarea
            v-model="description"
            label="Problembeschreibung"
            auto-grow
            ref="description"
            filled
            placeholder="Weitere Informationen eintragen"
            clearable
            prepend-inner-icon="mdi-comment"
            v-show="showDescriptionField"
            :rules="showDescriptionField ? rules : []"
        ></v-textarea>

        <v-text-field
            v-model="deliveryNumber"
            label="Lieferscheinnummer (optional)"
            filled
            block
            clearable
        />


        <v-btn
            @click="addPhoto"
            color="secondary"
            block
        >
          Bild hinzufügen
        </v-btn>

        <VerticalSpacer/>

        <v-row class="image-grid-container">
          <v-col
              v-for="image in images"
              :key="image.id"
              :value="image"
              class="d-flex child-flex"
              cols="4"
          >
            <v-img
                :src="image.blob"
                aspect-ratio="1"
                class="grey lighten-2"
                @click="showContextMenuAction($event, image)"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-menu
              v-model="showContextMenu"
              :position-x="x"
              :position-y="y"
              absolute
              offset-y
          >
            <v-list>
              <v-list-item
                  v-for="(item, index) in contextMenuItems"
                  :key="index"
                  @click="item.action"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
      </v-form>

      <VerticalSpacer/>
      <VerticalSpacer/>

      <v-btn
          @click="sendComplaint"
          color="primary"
          block
      >
        Absenden
      </v-btn>


    </v-container>
  </v-container>
</template>

<script>
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";
import LoadingIndicator from "../../Others/LoadingIndicator";
import VerticalSpacer from "../../Others/VerticalSpacer";
import ImageLoader from "../../Others/ImageLoader";
import TakePhoto from "./TakePhoto";
import SuccessDialog from "../../Others/SuccessDialog";
import LoginRequired from "../../Others/LoginRequired";
import PreviewPhoto from "./PreviewPhoto";
import ConfirmDialog from "../../Others/ConfirmDialog";

export default {
  components: {
    ConfirmDialog,
    PreviewPhoto,
    LoginRequired,
    SuccessDialog, TakePhoto, ImageLoader, VerticalSpacer, LoadingIndicator, ErrorDialog, Scanner
  },

  data: () => ({
    ean: null,
    articleNumber: '',
    selectedQuantity: 1,
    minValue: 1,
    articleData: null,
    isLoading: false,
    isError: false,
    errorContent: '',
    isSuccess: false,
    successContent: '',
    description: '',
    showProblemField: false,
    showDescriptionField: false,
    reason: ['Defekt', 'Übermenge', 'Untermenge'],
    problem: [
        'Bruch',
        'Defekte Ware',
        'Schimmel',
        'Schädlingsbefall',
        'Keine Funktion',
        'Rücksendung',
        'Sonstiges'
    ],
    selectedReason: '',
    selectedProblem: '',
    deliveryNumber: '',
    showTakePhotoDialog: false,
    images: [],
    imageIdIterator: 0,
    rules: [
      v => !!v || 'Pflichtfeld!'
    ],
    isValid: false,
    showImagePreview: false,
    imagePreviewSrc: null,
    showContextMenu: false,
    x: 0,
    y: 0,
    contextMenuItems: [],
    targetImage: null
  }),

  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('photoStart', this.onPhotoStart);
    this.$root.$on('photoTaken', this.onPhotoTaken);
    this.$root.$on('onCloseSuccessDialog', this.onCloseSuccessDialog);
    this.$root.$on('imagePreviewClosed', this.onImagePreviewClosed);
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('photoTaken', this.onPhotoTaken);
    this.$root.$off('photoStart', this.onPhotoStart);
    this.$root.$off('onCloseSuccessDialog', this.onCloseSuccessDialog);
    this.$root.$off('imagePreviewClosed', this.onImagePreviewClosed);
  },

  mounted() {
    this.contextMenuItems = [
      {title: 'Vorschau', action: this.openImagePreview},
      {title: 'Löschen', action: this.removeImage}
    ];
  },

  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    showContextMenuAction(event, image) {
      this.x = event.clientX;
      this.y = event.clientY;
      this.targetImage = image;
      this.showContextMenu = !this.showContextMenu;
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    onPhotoStart() {
      this.isLoading = true;
      this.showTakePhotoDialog = false;
    },
    onPhotoTaken(blob) {
      this.showTakePhotoDialog = false;
      this.addImage(blob);
      this.isLoading = false;
    },
    onImagePreviewClosed() {
      this.showImagePreview = false;
      this.imagePreviewSrc = null;
    },
    openImagePreview() {
      this.imagePreviewSrc = this.targetImage.blob;
      this.showImagePreview = true;
    },
    addImage(blob) {
      this.images.push({
        id: this.imageIdIterator,
        blob: blob,
        name: this.generateImageName(),
        extension: 'jpg'
      });
      this.imageIdIterator++;
    },
    removeImage() {
      this.$refs.confirm.open('Löschen', 'Möchten Sie das Bild wirklich löschen?', {color: 'info'}).then((confirm) => {
        if (confirm === true) {
          for (let i = 0; i < this.images.length; i++) {
            if (this.images[i] === this.targetImage) {
              this.images.splice(i, 1);
            }
          }
        }
      });
    },
    generateImageName() {
      return new Date().getTime();
    },
    loadScannedProduct() {
      if (this.articleNumber) {
        this.isLoading = true;
        this.$axios.get('productInfo/' + this.articleNumber).then(response => {
          this.isLoading = false;
          if (response.data && response.data.success === false) {
            this.isError = true;
            this.errorContent = response.data.message;
            this.articleNumber = '';
          }
          if (response.data && response.data.data) {
            this.isError = false;
            this.errorContent = '';
            this.articleData = response.data.data;
          }
        })
      }
      this.$refs.scannerResult.blur();
    },
    onScannerInputHandler(scannerOutput) {
      scannerOutput = this.parseJson(scannerOutput);
      this.articleData = null;
      this.articleNumber = scannerOutput.dataAsText;
      this.$refs.scannerResult.$emit('change');
    },
    addQuantity() {
      this.selectedQuantity++;
    },
    removeQuantity() {
      if (this.minValue <= this.selectedQuantity - 1) {
        this.selectedQuantity--;
      }
    },
    handleQuantityChange() {
      if (this.minValue > this.selectedQuantity) {
        this.selectedQuantity = this.minValue;
      }
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    sendComplaint() {
      if (this.validateComplaint()) {
        const params = {
          quantity: this.selectedQuantity,
          description: this.description,
          reason: this.selectedReason,
          problem: this.selectedProblem,
          deliveryNumber: this.deliveryNumber,
          images: this.images,
          articleDetailId: this.articleData.articleDetailId,
          ordererId: Number(localStorage.getItem('userId'))
        };


        this.isLoading = true;
        this.$axios.post('complaint', params).then(response => {
          this.isLoading = false;
          if (response.data && response.data.success === false) {
            this.isError = true;
            this.errorContent = response.data.message;
          }
          if (response.data && response.data.data) {
            this.isError = false;
            this.errorContent = '';
            this.isSuccess = true;
            this.successContent = response.data.message;
          }
        })
      }
    },
    validateComplaint() {
      return this.$refs.form.validate();
    },
    addPhoto() {
      this.showTakePhotoDialog = true;
    },
    onCloseSuccessDialog() {
      this.$router.go();
    },
    onReasonChange(value) {
      this.showProblemField = value === 'Defekt';
      if(!this.showProblemField) {
        this.selectedProblem = '';
        this.onProblemChange('');
      }
    },
    onProblemChange(value) {
      this.showDescriptionField = value === 'Sonstiges';
      if(!this.showDescriptionField) {
        this.description = '';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quantity-field {
  width: 50px;
  display: inline;
}

.quantity-button {
  margin-top: 20px;
}

.image-grid-container {
  max-height: 200px;
  overflow-y: auto;
}
</style>
