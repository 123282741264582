<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <div v-if="isLoading" class="text-center">
        <v-overlay :value="isLoading">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
      </div>
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>Bestandsgrenzen anpassen</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              :disabled="isLoading"
              icon
              dark
              @click="emitCloseStockEditDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container class="departments-container" v-if="dialogArticle">
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Bestandsgrenzen anpassen
        </v-alert>
        <v-container class="xs12 product-info-container">
          <v-row>
            <v-col>
              <v-img
                  :src="dialogArticle.imagePath"
                  width="100px"
                  min-width="100px"
              >
                <template v-slot:placeholder>
                  <ImageLoader/>
                </template>
              </v-img>
            </v-col>
            <v-layout align-center>
              <v-col>
                <v-row v-text="dialogArticle.name"/>
                <v-row v-if="dialogArticle.configurator"
                       v-text="createNameFromConfigurator(dialogArticle.configurator)"/>
                <v-row v-text="dialogArticle.ordernumber"/>
                <v-row v-if="dialogArticle.agiqonArticleSupplierQuantity"
                       v-text="'VPE: ' + dialogArticle.agiqonArticleSupplierQuantity "/>
              </v-col>
            </v-layout>
          </v-row>
          <VerticalSpacer/>

          <v-text-field
              label="Mindestbestand"
              v-model="minimumStock"
              :min="0"
              :step="dialogArticle.agiqonArticleSupplierQuantity ? dialogArticle.agiqonArticleSupplierQuantity : 1"
              type="number"
              :disabled="isLoading"
              @change="handleVpeWarning"
          />
          <v-row v-if="showVpeMinimumStockWarning">
            <br/>
            <v-alert
                dense
                border="right"
                colored-border
                type="error"
                elevation="2"
            >
              Mindestbestand stimmt nicht mit der VPE ({{ dialogArticle.agiqonArticleSupplierQuantity }}) überein
            </v-alert>
          </v-row>

          <VerticalSpacer />

          <v-text-field
              label="Sollbestand"
              v-model="targetStock"
              :min="0"
              :step="dialogArticle.agiqonArticleSupplierQuantity ? dialogArticle.agiqonArticleSupplierQuantity : 1"
              type="number"
              :disabled="isLoading"
              @change="handleVpeWarning"
          />

          <v-row v-if="showVpeTargetStockWarning">
            <br/>
            <v-alert
                dense
                border="right"
                colored-border
                type="error"
                elevation="2"
            >
              Sollbestand stimmt nicht mit der VPE ({{ dialogArticle.agiqonArticleSupplierQuantity }}) überein
            </v-alert>
          </v-row>

          <VerticalSpacer/>

          <v-row>
            <v-btn
                small
                color="success"
                class="ma-2 white--text"
                @click="changeStockInformation"
                ref="subtract"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Anpassen
            </v-btn>
            <vertical-spacer/>
            <v-btn
                small
                color="error"
                class="ma-2 white--text"
                @click="emitCloseStockEditDialog"
            >
              <v-icon
                  left
                  dark
              >
                mdi-close-circle
              </v-icon>
              Abbrechen
            </v-btn>
          </v-row>
        </v-container>

      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>

import VerticalSpacer from "../Others/VerticalSpacer";
import ImageLoader from "../Others/ImageLoader";

export default {
  components: {ImageLoader, VerticalSpacer},
  props: {
    dialog: Boolean,
    dialogArticle: Object,
  },
  data: () => ({
    minimumStock: 0,
    targetStock: 0,
    step: 0,
    isLoading: false,
    showVpeMinimumStockWarning: false,
    showVpeTargetStockWarning: false
  }),
  beforeMount() {
    this.minimumStock = Number(this.dialogArticle.salesArea.minimumStock);
    this.targetStock = Number(this.dialogArticle.salesArea.targetStock);
    this.handleVpeWarning();
  },
  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if (!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    handleVpeWarning() {
      this.showVpeMinimumStockWarning = !!(this.dialogArticle.agiqonArticleSupplierQuantity && this.minimumStock % this.dialogArticle.agiqonArticleSupplierQuantity !== 0);
      this.showVpeTargetStockWarning = !!(this.dialogArticle.agiqonArticleSupplierQuantity && this.targetStock % this.dialogArticle.agiqonArticleSupplierQuantity !== 0);
    },
    emitCloseStockEditDialog() {
      this.$root.$emit('onStockEditDialogClosed', true);
    },
    changeStockInformation() {
      this.isLoading = true;
      const params = {
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        minimumStock: this.minimumStock,
        targetStock: this.targetStock
      };

      this.$axios.post('warehouseStockConfig/', params).then(response => {
        let isError = false;
        if (response.data.success === false) {
          isError = true;
        }

        if(!isError) {
          this.bookLog(
              'warehouseStockConfig',
              params,
              null,
              null,
              null,
                'M',
              'M'
          );
        }

        this.isLoading = false;
        this.$root.$emit('onStockProcessed', !isError, isError ? response.data.message : null);
      })
    },

    async bookLog(payloadUrl, payload, quantity, binLocationCodeFrom, binLocationCodeTo, warehouseCodeFrom, warehouseCodeTo) {
      await this.$axios.post('bookingLog', {
        userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
        articleId: Number(this.dialogArticle.articleID),
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        articleNumber: this.dialogArticle.ordernumber,
        articleName: this.dialogArticle.name,
        quantity,
        binLocationCodeFrom: binLocationCodeFrom,
        binLocationCodeTo: binLocationCodeTo,
        warehouseCodeFrom,
        warehouseCodeTo,
        payload,
        payloadUrl,
        place: 'Bestandsgrenzen'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.action-label {
  font-size: 12px;
}

.table-content {
  td {
    font-size: large !important;
  }
}
</style>