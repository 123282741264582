<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <LoadingIndicator
        :loading="isLoading"/>
    <ErrorDialog
        :show="showError"
        :errorContent="errorContent"/>
    <v-alert
        color="primary"
        elevation="24"
        icon="mdi-information-outline"
        type="info"
    >
      Artikel {{ articleNumber }} in Lager ({{ warehouseCode }}) von
      {{ selectedBinLocation ? selectedBinLocation.nameData.displayName : '...' }}
      mit Menge {{ selectedQuantity }}
    </v-alert>
    <VerticalSpacer/>

    <v-container class="scanner-input">
      <v-row>
        <v-text-field
            @change="selectBinLocation"
            label="Lagerplatz scannen"
            prepend-icon="mdi-barcode-scan"
            outlined
            ref="scannerResult"
            dense
            :value="selectedBinLocation ? selectedBinLocation.code : ''"
        >
        </v-text-field>
      </v-row>
    </v-container>

    <VerticalSpacer />

    <template v-if="!isLoading">
      <v-simple-table
          fixed-header
          height="300px"
          class="table-full-width table-content">
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Lagerplatz
            </th>
            <th class="text-left">
              Verfügbare Anzahl
            </th>
            <th class="text-left">
              Auswählen
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="binLocation in binLocations"
              :key="binLocation.code"
          >
            <td>{{ binLocation.nameData.displayName }}</td>
            <td>{{ binLocation.stock }}</td>
            <td>
              <v-radio-group v-model="selectedBinLocation" @change="handleRadioGroup">
                <v-radio
                    :key="binLocation.code"
                    :value="binLocation"
                    :disabled="Number(binLocation.stock) <= 0"
                />
              </v-radio-group>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-col>
        <v-row justify="center">Menge</v-row>
        <v-row justify="center">
          <v-icon :disabled="!selectedBinLocation"
                  color="green"
                  x-large
                  class="left-icon"
                  left
                  @click="addQuantity">
            mdi-plus
          </v-icon>

          <v-text-field
              class="center-input"
              v-model="selectedQuantity"
              :min="minQuantity"
              :aria-valuemin="minQuantity"
              :max="maxQuantity"
              :aria-valuemax="maxQuantity"
              type="number"
              width="50px"
              :disabled="!selectedBinLocation"
              @change="handleInputChange"
          />

          <v-icon :disabled="!selectedBinLocation"
                  color="red"
                  x-large
                  right
                  class="right-icon"
                  @click="removeQuantity">
            mdi-minus
          </v-icon>
        </v-row>
      </v-col>

      <v-row v-if="showVpeWarning">
        <br/>
        <v-alert
            border="right"
            colored-border
            type="error"
            elevation="2"
        >
          Menge stimmt nicht mit der VPE ({{ articleData.agiqonArticleSupplierQuantity }}) überein
        </v-alert>
      </v-row>

      <VerticalSpacer/>
      <VerticalSpacer/>
      <div class="centerize">
        <v-btn
            :to="{ name: 'rearrange.between_warehouse_select_product', params: {
              warehouseId: this.warehouseId,
              warehouseCode: this.warehouseCode
            }}"
            outlined
            color="primary"
        >
          Zurück
        </v-btn>
        <br/>
        <br/>
        <v-btn
            color="success"
            elevation="2"
            large
            block
            :disabled="!selectedQuantity || !selectedBinLocation"
            :to="{name: 'rearrange.between_warehouse_select_to', params: {
            warehouseId: this.warehouseId,
            warehouseCode: this.warehouseCode,
            articleNumber: this.articleNumber,
            articleDetailId: this.articleDetailId,
            quantity: this.selectedQuantity ? this.selectedQuantity : 'unknown',
            binLocationId: this.selectedBinLocation ? this.selectedBinLocation.id : 'unknown',
            binLocationCode: this.selectedBinLocation ? this.selectedBinLocation.code : 'unknown'
          }}"
        >Ziel Lagerplatz auswählen
        </v-btn>
      </div>
    </template>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import LoadingIndicator from "../../Others/LoadingIndicator";
import ErrorDialog from "../../Others/ErrorDialog";
import VerticalSpacer from "../../Others/VerticalSpacer";
import Scanner from "../../Others/Scanner";

export default {
  components: {Scanner, VerticalSpacer, ErrorDialog, LoadingIndicator, LoginRequired},
  data: () => ({
    isLoading: true,
    showError: false,
    errorContent: '',
    warehouseId: null,
    warehouseCode: null,
    articleNumber: null,
    articleDetailId: null,
    articleData: null,
    binLocations: null,
    selectedBinLocation: null,
    selectedQuantity: 0,
    minQuantity: 0,
    maxQuantity: 0,
    stepQuantity: 0,
    showVpeWarning: false
  }),

  created() {
    this.warehouseId = parseInt(this.$route.params.warehouseId);
    this.warehouseCode = this.$route.params.warehouseCode;
    this.articleNumber = this.$route.params.articleNumber;
    this.articleDetailId = parseInt(this.$route.params.articleDetailId);

    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('onScannerInput', this.onScannerInputHandler);

    this.loadBinLocations();
  },

  beforeDestroy() {
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  methods: {
    loadBinLocations() {
      this.$axios.get('productInfo/' + this.articleNumber).then(response => {
        if (response.data && response.data.success === false) {
          this.isError = true;
          this.errorContent = response.data.message;
          this.articleData = null;
          this.isLoading = false;
        }
        if (response.data && response.data.data) {
          this.isError = false;
          this.errorContent = '';
          this.articleData = response.data.data;
          this.buildBinLocations();
          this.stepQuantity = this.articleData.agiqonArticleSupplierQuantity ? this.articleData.agiqonArticleSupplierQuantity : 1;
          this.minQuantity = 1;
          this.isLoading = false;
        }
      })
    },
    handleCloseErrorDialog(state) {
      this.showError = state;
    },
    handleRadioGroup() {
      this.maxQuantity = parseInt(this.selectedBinLocation.stock) - parseInt(this.selectedBinLocation.reservedStock);
      this.handleInputChange();
    },
    buildBinLocations() {
      this.binLocations = [];
      this.binLocations = this.articleData.availableStock[this.warehouseCode].binLocations;
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    selectBinLocation(binLocationCode) {
      const doesExists = (this.binLocations.filter(binLocation => binLocation.code === binLocationCode)).length > 0
      if (doesExists) {
        this.showError = false;
        this.selectedBinLocation = this.binLocations.filter(binLocation => binLocation.code === binLocationCode)[0];
        this.selectedQuantity = this.articleData.agiqonArticleSupplierQuantity ? this.articleData.agiqonArticleSupplierQuantity : this.minQuantity;
        this.handleRadioGroup();
        this.handleInputChange();
      } else {
        this.showError = true;
        this.selectedBinLocation = null;
        this.selectedQuantity = 0;
        this.maxQuantity = 0;
        this.errorContent = binLocationCode + ' nicht gefunden im ' + this.articleData.availableStock[this.warehouseCode].warehouseName + '!';
      }
      this.$refs.scannerResult.blur();
    },
    onScannerInputHandler(scannerResponse) {
      const scannerInput = this.parseJson(scannerResponse);
      const binLocationCode = scannerInput.dataAsText.split('$')[1];
      if (!binLocationCode) {
        this.showError = true;
        this.selectedBinLocation = null;
        this.selectedQuantity = 0;
        this.maxQuantity = 0;
        return this.errorContent = `${scannerInput.dataAsText} ist kein gültiger Lagerplatz!`;
      }

      this.selectBinLocation(binLocationCode);
    },
    addQuantity() {
      if (this.articleData.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.articleData.agiqonArticleSupplierQuantity) <= this.maxQuantity) {
          if (this.selectedQuantity % this.articleData.agiqonArticleSupplierQuantity) {
            this.selectedQuantity += this.articleData.agiqonArticleSupplierQuantity - (this.selectedQuantity % this.articleData.agiqonArticleSupplierQuantity);
          } else {
            this.selectedQuantity += this.articleData.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxQuantity;
        }
      } else {
        if ((this.selectedQuantity + 1) <= this.maxQuantity) {
          this.selectedQuantity = ++this.selectedQuantity;
        }
      }
      if (this.selectedQuantity > this.maxQuantity) {
        this.selectedQuantity = this.maxQuantity;
      }
      this.handleInputChange();
    },
    removeQuantity() {
      if (this.articleData.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.minQuantity - this.articleData.agiqonArticleSupplierQuantity) >= 0) {
          if (this.selectedQuantity % this.articleData.agiqonArticleSupplierQuantity) {
            const rest = this.articleData.agiqonArticleSupplierQuantity - this.selectedQuantity % this.articleData.agiqonArticleSupplierQuantity;
            this.selectedQuantity = this.selectedQuantity - (this.articleData.agiqonArticleSupplierQuantity - rest);
          } else {
            this.selectedQuantity -= this.articleData.agiqonArticleSupplierQuantity;
          }
        }
      } else {
        if ((this.selectedQuantity - 1) >= this.minQuantity) {
          --this.selectedQuantity;
        }
      }
      if (this.selectedQuantity < 0) {
        this.selectedQuantity = 0;
      }
      this.handleInputChange();
    },
    handleInputChange() {
      this.selectedQuantity = parseInt(this.selectedQuantity);
      this.handleVpeWarning();
      setTimeout(this.handleVpeWarning, 100);

      if (this.selectedQuantity > this.maxQuantity) {
        this.selectedQuantity = this.maxQuantity;
      }

      if (this.selectedQuantity < this.minQuantity) {
        this.selectedQuantity = this.minQuantity;
      }
    },
    handleVpeWarning() {
      this.showVpeWarning = !!(this.articleData.agiqonArticleSupplierQuantity && this.selectedQuantity % this.articleData.agiqonArticleSupplierQuantity !== 0);
    }
  }
}
</script>

<style lang="scss">
.v-list-item--disabled {
  display: none;
}
</style>

<style scoped>
/deep/ .v-input.center-input {
  text-align: center;
  max-width: 50%;
}

.centerize {
  text-align: center;
  margin: auto;
}

.center-input >>> input {
  text-align: center;
}

.left-icon {
  margin-right: 25px;
}

.right-icon {
  margin-left: 25px;
}
</style>