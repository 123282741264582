<template>
  <v-container>
    <LoginRequired/>
    <Scanner v-if="!booked"/>
    <LoadingIndicator
        :loading="isLoading"/>
    <ErrorDialog
        :show="showError"
        :errorContent="errorContent"/>
    <v-alert
        color="primary"
        elevation="24"
        icon="mdi-information-outline"
        type="info"
    >
      Artikel {{ articleNumber }} in Lager ({{ warehouseCode }}) von
      {{ selectedBinLocation ? selectedBinLocation.nameData.displayName : '...' }}
      mit Menge {{ selectedQuantity }} nach {{ targetBinLocation ? targetBinLocation.nameData.displayName : '...' }}
    </v-alert>
    <VerticalSpacer/>
    <v-container class="scanner-input" v-if="!booked">
      <v-row>
        <v-text-field
            @change="selectBinLocation"
            label="Lagerplatz scannen"
            prepend-icon="mdi-barcode-scan"
            outlined
            ref="scannerResult"
            dense
            :value="this.targetBinLocation ? this.targetBinLocation.code : ''"
        >
        </v-text-field>
      </v-row>
    </v-container>
    <VerticalSpacer/>

    <template v-if="!isLoading">
      <div v-if="!booked">
        <VerticalSpacer/>
        <VerticalSpacer/>
        <div class="centerize">
          <v-btn
              :to="{ name: 'rearrange.between_warehouse_select_from', params: {
              warehouseId: this.warehouseId,
              warehouseCode: this.warehouseCode,
              articleNumber: this.articleNumber,
              articleDetailId: this.articleDetailId
            }}"
              outlined
              color="primary"
          >
            Zurück
          </v-btn>
          <br/>
          <br/>
          <v-btn
              color="success"
              elevation="2"
              large
              block
              :disabled="!targetBinLocation"
              @click="book"
          >Buchen
          </v-btn>
        </div>
      </div>
      <div v-else>
        <v-alert
            dense
            text
            type="success"
        >
          Erfolgreich umgelagert.
        </v-alert>
        <VerticalSpacer/>
        <VerticalSpacer/>
        <v-btn
            color="success"
            elevation="2"
            large
            block
            :to="{ name: 'rearrange.between_warehouse_select_from', params: {
              warehouseId: this.warehouseId,
              warehouseCode: this.warehouseCode,
              articleNumber: this.articleNumber,
              articleDetailId: this.articleDetailId
            }}"
        >OK
        </v-btn>
      </div>
    </template>
  </v-container>
</template>

<script>
import LoginRequired from "../../Others/LoginRequired";
import LoadingIndicator from "../../Others/LoadingIndicator";
import ErrorDialog from "../../Others/ErrorDialog";
import VerticalSpacer from "../../Others/VerticalSpacer";
import Scanner from "../../Others/Scanner";

export default {
  components: {Scanner, VerticalSpacer, ErrorDialog, LoadingIndicator, LoginRequired},
  data: () => ({
    isLoading: true,
    showError: false,
    errorContent: '',
    warehouseId: null,
    warehouseCode: null,
    articleNumber: null,
    articleDetailId: null,
    articleData: null,
    binLocations: null,
    selectedBinLocationId: null,
    selectedBinLocation: null,
    targetBinLocation: null,
    selectedQuantity: 0,
    minQuantity: 0,
    maxQuantity: 0,
    stepQuantity: 0,
    showVpeWarning: false,
    booked: false
  }),

  created() {
    this.warehouseId = parseInt(this.$route.params.warehouseId);
    this.warehouseCode = this.$route.params.warehouseCode;
    this.articleNumber = this.$route.params.articleNumber;
    this.articleDetailId = parseInt(this.$route.params.articleDetailId);
    this.selectedQuantity = parseInt(this.$route.params.quantity);
    this.selectedBinLocationId = parseInt(this.$route.params.binLocationId);
    this.selectedBinLocationCode = this.$route.params.binLocationCode;

    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('onScannerInput', this.onScannerInputHandler);

    this.loadBinLocations();
  },

  beforeDestroy() {
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  methods: {
    loadBinLocations() {
      this.$axios.get('productInfo/' + this.articleNumber).then(response => {
        if (response.data && response.data.success === false) {
          this.showError = true;
          this.errorContent = response.data.message;
          this.articleData = null;
          this.isLoading = false;
        }
        if (response.data && response.data.data) {
          this.showError = false;
          this.errorContent = '';
          this.articleData = response.data.data;
          this.buildBinLocations();
          this.isLoading = false;
        }
      })
    },
    buildBinLocations() {
      this.selectedBinLocation = this.articleData.availableStock[this.warehouseCode].binLocations.filter(binLocation =>
          parseInt(binLocation.id) === this.selectedBinLocationId
      )[0];
    },
    handleCloseErrorDialog(state) {
      this.showError = state;
    },
    selectBinLocation(binLocationCode) {
      this.isLoading = true;
      const params = {
        fromBinLocationId: this.selectedBinLocationId,
        warehouseId: this.warehouseId,
        targetBinLocationCode: binLocationCode
      };

      this.$axios.post('singleBinLocation', params).then(response => {
        if (response.data && response.data.success === false) {
          this.showError = true;
          this.errorContent = response.data.message;
          this.targetBinLocation = null;
        }
        if (response.data && response.data.data) {
          this.showError = false;
          this.errorContent = '';
          this.targetBinLocation = response.data.data;
        }
        this.isLoading = false;
        this.$refs.scannerResult.blur();
      });

    },
    onScannerInputHandler(scannerResponse) {
      const scannerInput = this.parseJson(scannerResponse);
      const binLocationCode = scannerInput.dataAsText.split('$')[1];
      if (!binLocationCode) {
        this.showError = true;
        this.targetBinLocation = null;
        return this.errorContent = `${scannerInput.dataAsText} ist kein gültiger Lagerplatz!`;
      }

      this.selectBinLocation(binLocationCode);
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    book() {
      this.isLoading = true;
      const params = [{
        articleDetailId: this.articleDetailId,
        quantity: this.selectedQuantity,
        source: {
          binLocation: {
            id: this.selectedBinLocationId
          }
        },
        destination: {
          binLocation: {
            id: Number(this.targetBinLocation.binLocationId)
          }
        }
      }];
      const address = 'stock/relocate/' + this.$uuid.v4();
      this.$axios.put(address, params).then(() => {
        this.booked = true;
        this.bookLog(
            address,
            params,
            this.selectedQuantity,
            this.selectedBinLocationCode,
            this.targetBinLocation.code,
            this.warehouseCode,
            this.warehouseCode
        );
        this.isLoading = false;
      });
    },
    async bookLog(payloadUrl, payload, quantity, binLocationCodeFrom, binLocationCodeTo, warehouseCodeFrom, warehouseCodeTo) {
      await this.$axios.post('bookingLog', {
        userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
        articleId: Number(this.articleData.articleID),
        articleDetailId: Number(this.articleData.articleDetailId),
        articleNumber: this.articleData.ordernumber,
        articleName: this.articleData.name,
        quantity,
        binLocationCodeFrom: binLocationCodeFrom,
        binLocationCodeTo: binLocationCodeTo,
        warehouseCodeFrom,
        warehouseCodeTo,
        payload,
        payloadUrl,
        place: 'Artikel umlagern'
      });
    }
  }
}
</script>

<style lang="scss">
.v-list-item--disabled {
  display: none;
}
</style>

<style scoped>
.centerize {
  text-align: center;
  margin: auto;
}

.center-input >>> input {
  text-align: center;
}

.left-icon {
  margin-right: 25px;
}

.right-icon {
  margin-left: 25px;
}
</style>