<template>
  <v-container>
    <Scanner
      :should-emit="!dialog"
    />
    <ConfirmDialog ref="confirm"/>
    <LoadingIndicator
        :loading="isLoading || !picklist"
    />
    <div class="tooltip-container"
         v-if="showToolTip"
         v-on:click.stop.prevent="closeToolTip"
    >
      <v-tooltip
          class="bin-location-tooltip"
          v-model="showToolTip"
          absolute
          :position-x="toolTipX"
          :position-y="toolTipY"
          bottom
          :z-index="998"
          v-on:click.stop.prevent.native="closeToolTip"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-bind="attrs"
              v-on="on"
          >
          </v-btn>
        </template>
        <p class="mb-0" v-for="toolTipContentRow in toolTipContent" :key="toolTipContentRow">
          {{ toolTipContentRow }}
        </p>
      </v-tooltip>
    </div>

    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template class="departments-container" v-if="paletteContent">
      <EditDialog
          v-if="dialogArticle"
          :dialog="dialog"
          :dialogArticle="dialogArticle"
          :paletteBinLocationId="binLocationId"
          :paletteName="paletteName"
          :warehouseCodeFrom="warehouseCodeFrom"
      />
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Einlagerung vom Markt zum Lager
      </v-alert>
      <v-row>
        <v-col>
          <h5>Aktiver Vorgang auf: {{ paletteName }}</h5>
        </v-col>
      </v-row>

      <v-row>
        <v-text-field
            label="Artikel scannen"
            :value="scannedProduct ? scannedProduct.ordernumber : ''"
            outlined
            ref="scannerResult"
            @change="onScannerInputHandler"
            dense
            prepend-icon="mdi-barcode-scan"

        ></v-text-field>
      </v-row>
      <VerticalSpacer/>
      <v-card
          width="100%"
          class="mx-auto"
          v-if="picklist && !virtualScrollUpdated"
      >
        <v-virtual-scroll
            :items="picklist"
            item-height="150"
            ref="virtualScroll"
        >
          <template v-slot:default="{ item }">
            <v-list-item
                :key="item.number"
                @click="openEditDialog(item)"
                class="picklist-item"
            >

              <span class="packed-amount">x {{ item.packedAmount }}</span>
              <v-list-item-action>
                <v-card
                    class="pa-2 product-image-container"
                    outlined
                    tile
                    width="50"
                    height="50"
                >
                  <v-img :src="item.imagePath">
                    <template v-slot:placeholder>
                      <ImageLoader/>
                    </template>
                  </v-img>
                </v-card>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-title v-if="item.configurator">
                  {{ createNameFromConfigurator(item.configurator) }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ item.ordernumber }}
                </v-list-item-title>
                <v-list-item-title  v-if="item.agiqonArticleSupplierQuantity && parseInt(item.agiqonArticleSupplierQuantity) > 0">
                  VPE Lager: {{ item.agiqonArticleSupplierQuantity }}
                </v-list-item-title>
                <v-list-item-title>
                  Lagerplatz
                  <v-icon v-on:click.stop.prevent="displayBinLocationsToolTip(item, $event)">mdi-dots-horizontal
                  </v-icon>
                </v-list-item-title>


              </v-list-item-content>

            </v-list-item>


            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card>

    </template>

    <VerticalSpacer/>

    <v-row>
      <v-btn
          block
          @click="confirmFinishPicklist"
          color="success"
      >
        Vorgang abschließen
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>

import VerticalSpacer from "../../Others/VerticalSpacer";
import ImageLoader from "../../Others/ImageLoader";
import ConfirmDialog from "../../Others/ConfirmDialog";
import LoadingIndicator from "../../Others/LoadingIndicator";
import EditDialog from "./EditDialog";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, EditDialog, LoadingIndicator, ConfirmDialog, ImageLoader, VerticalSpacer},
  data: () => ({
    id: null,
    isLoading: true,
    paletteCode: null,
    binLocationId: 0,
    department: null,
    selectedPalette: null,
    showStartInfo: false,
    showDialog: false,
    paletteName: '',
    scannedProduct: '',
    paletteContent: [],
    dialog: false,
    dialogArticle: null,
    picklist: [],
    virtualScrollUpdated: false,
    isError: false,
    errorContent: '',
    toolTipContent: [],
    showToolTip: false,
    toolTipX: 0,
    toolTipY: 0,
    toolTipTarget: null,
    warehouseCodeFrom: ''
  }),
  created() {
    this.paletteName = decodeURI(this.$route.params.paletteName);
    this.binLocationId = Number(this.$route.params.binLocationId);
    this.warehouseCodeFrom = decodeURI(this.$route.params.warehouseCodeFrom);

    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$on('articleDialogDataChange', this.handleArticleUpdate);
    this.$root.$on('articleDialogDataChangeStart', this.setLoadingIndicator);

    this.getActualPaletteItems();
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('articleDialogCloseAction', this.closeProductRelocationDialog);
    this.$root.$off('articleDialogDataChange', this.handleArticleUpdate);
    this.$root.$off('articleDialogDataChangeStart', this.setLoadingIndicator);
  },

  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    setLoadingIndicator(value = true) {
      if (typeof value === 'boolean') {
        this.isLoading = value;
      } else {
        this.isLoading = true;
      }
    },
    handleArticleUpdate() {
      this.getActualPaletteItems();
    },
    getActualPaletteItems() {
      this.isLoading = true;
      this.$axios.get('binLocations/' + this.binLocationId).then(response => {
        if (response.data && response.data.data) {
          this.paletteContent = response.data.data.articleDetailBinLocationMappings;
          this.mergePaletteWithPicklist();
          this.isLoading = false;
        }
      });
    },
    mergePaletteWithPicklist() {
      this.picklist = [];
      for (const product of this.paletteContent) {
        this.addArticleFromPalette(product);
      }
      this.reloadVirtualScroll();
    },
    addArticleFromPalette(product) {
      this.setLoadingIndicator(true);
      this.$axios.get('productInfo/' + Number(product.articleDetailId) + '?useId=true&departmentsOnly=true').then(response => {
        if (response.data && response.data.data) {
          response.data.data.packedAmount = Number(product.stock);
          this.picklist.push(response.data.data);
          this.reloadVirtualScroll();
          this.setLoadingIndicator(false);
        }
      })
    },
    reloadVirtualScroll() {
      this.virtualScrollUpdated = true;
      setTimeout(() => {
        this.virtualScrollUpdated = false
      }, 1);
    },
    confirmFinishPicklist() {
      this.$refs.confirm.open('Einlagerung', 'Möchten Sie einlagerung wirklich abschließen?', {color: 'info'}).then((confirm) => {
        if (confirm === true) {
          this.$router.push({name: 'return.articles'});
        }
      });
    },
    openEditDialog(product) {
      if (typeof product === 'string') {
        this.$axios.get('productInfo/' + product + '?departmentsOnly=true').then(response => {
          if (response.data && response.data.data) {
            this.dialog = true;

            this.dialogArticle = response.data.data;
          }
        })
      } else {
        this.dialog = true;
        this.dialogArticle = product;
      }
    },
    closeProductRelocationDialog() {
      this.dialog = false;
      this.dialogArticle = null;
    },
    onScannerInputHandler(scannerInputRaw) {
      if(!scannerInputRaw) {
        return;
      }
      const scannerInput = this.parseJson(scannerInputRaw);
      let productNumber = scannerInput.dataAsText;

      if(productNumber === 'none' || !productNumber) {
        productNumber = scannerInputRaw
      }
      this.$axios.get('productInfo/' + productNumber + '?departmentsOnly=true').then(response => {
        if (response.data && response.data.data) {
          this.isError = false;
          this.scannedProduct = response.data.data;
          this.updatePicklistWithArticle();
        } else if (response.data && response.data.success === false) {
          this.isError = true;
          this.errorContent = response.data.message;
          this.scannedProduct = null;
        }
        this.$refs.scannerResult.blur();
      });
    },
    updatePicklistWithArticle() {
      if (!this.getArticleFromPicklist()) {
        const addedArticle = this.addArticleToPicklist();
        this.openEditDialog(addedArticle);
      } else {
        const articleFromPicklist = this.getArticleFromPicklist();
        if (articleFromPicklist) {
          this.openEditDialog(articleFromPicklist);
        }
      }
    },
    addArticleToPicklist() {
      this.isLoading = true;
      this.picklist.push(this.scannedProduct);
      this.calculatePackedAmount();
      this.mergePaletteWithPicklist();
      this.reloadVirtualScroll();
      this.isLoading = false;
      return this.scannedProduct;
    },
    getArticleFromPicklist() {
      return this.picklist.filter(article => article.ordernumber === this.scannedProduct.ordernumber)[0];
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    calculatePackedAmount() {
      for (const product of this.picklist) {
        product.packedAmount = 0;
      }
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    displayBinLocationsToolTip(item, event) {
      this.toolTipContent = [];
      for (const binLocation of item.availableStock.M.binLocations) {
        this.toolTipContent.push(binLocation.nameData.displayName);
      }
      if (event.target !== this.toolTipTarget) {
        this.showToolTip = true;
      } else {
        this.showToolTip = !this.showToolTip;
      }
      this.toolTipX = event.x;
      this.toolTipY = event.y;
      this.toolTipTarget = event.target;
    },
    closeToolTip() {
      this.showToolTip = false;
      this.toolTipContent = [];
    }
  }
};
</script>

<style lang="scss" scoped>
.is--active {
  background-color: aqua;
}

.packed-amount {
  margin-left: 15px;
  width: 35px;
}

.tooltip-container {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin: 0;
  padding: 0;
  border: 0;
}
</style>