<template class="main-app-container">
  <v-app>
    <Navigation />
    <AppBar />

    <v-main>
      <v-container fluid>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import Navigation from './components/Navigation';
import AppBar from "./components/AppBar";

export default {
  components: {AppBar, Navigation},
}
</script>

<style lang="scss">
.container.container--fluid {
  padding: 2px !important;
}
</style>
