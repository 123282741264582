<template>
  <v-container fluid>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <StockEditDialog
      v-if="stockEditArticle && stockEditDialog"
      :dialog="stockEditDialog"
      :dialogArticle="stockEditArticle"
    />
    <v-container class="scanner-input">
      <v-row>
        <v-text-field
            v-model="articleNumber"
            @change="loadScannedProduct"
            label="Artikel scannen"
            prepend-icon="mdi-barcode-scan"
            outlined
            ref="scannerResult"
            dense
        >
        </v-text-field>
      </v-row>
    </v-container>

    <v-layout v-if="isLoading" align-content-center justify-center align-center>
      <v-progress-circular indeterminate
                           color="primary"/>
    </v-layout>
    <v-container class="departments-container" v-else-if="articleData">
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Produktinformationen
      </v-alert>

      <div v-if="scannedPalette" class="back-button">
      <v-btn color="secondary"
             outlined
             rounded
             :to="{name: 'binLocations', params: {scannedPalette}}">
        Zurück zur <br />
        Lagerplatzansicht
      </v-btn>
        <VerticalSpacer/>
      </div>

      <v-container class="xs12 product-info-container">
        <v-row>
          <v-col>
            <v-img
                :src="articleData.imagePath"
                width="100px"
                min-width="100px"
            >
              <template v-slot:placeholder>
                <ImageLoader/>
              </template>
            </v-img>
          </v-col>
          <v-layout align-center>
            <v-col>
              <v-row v-text="articleData.name"/>
              <v-row v-if="articleData.configurator" v-text="createNameFromConfigurator(articleData.configurator)"/>
              <v-row v-text="articleData.ordernumber"/>
              <v-row v-if="articleData.agiqonArticleSupplierQuantity" v-text="'VPE: ' + articleData.agiqonArticleSupplierQuantity "/>
            </v-col>
          </v-layout>
        </v-row>
        <v-row>
          <span>Bestand im Markt: <span class="font-weight-bold status-value">{{
              articleData.salesArea.stock
            }}</span></span>
        </v-row>
        <v-row>
          <span>Mindest-Bestand Markt: <span class="font-weight-bold status-value">{{
              articleData.salesArea.minimumStock
            }}</span></span>
        </v-row>
        <v-row>
          <span>Soll-Bestand Markt: <span class="font-weight-bold status-value">{{
              articleData.salesArea.targetStock
            }}</span></span>
        </v-row>

        <VerticalSpacer/>

        <v-btn
            :loading="isLoading"
            :disabled="isLoading"
            color="blue-grey"
            class="ma-2 white--text"
            @click="openStockEditDialog"
        >
          <v-icon
              left
              dark
          >
            mdi-pencil
          </v-icon>
          Bestandsgrenzen
        </v-btn>

        <VerticalSpacer/>
        <v-row>
          <v-spacer/>
          <v-alert
              dense
              type="info"
              outlined
          >
            Bestand im Lager
          </v-alert>
          <v-spacer/>
          <v-simple-table class="table-full-width table-content">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Lagerplatz
                </th>
                <th class="text-left">
                  Bestand
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="binLocation in articleData.availableStock.L.binLocations"
                  :key="binLocation.code"
              >
                <td>{{ binLocation.nameData.displayName }}</td>
                <td>{{ binLocation.stock }}</td>
              </tr>
              <tr>
                <td>Gesamt</td>
                <td>{{ total }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
        <VerticalSpacer/>
        <v-row v-if="(articleData.restVariants).length > 0">
          <v-spacer/>
          <v-alert
              dense
              type="info"
              outlined
          >
            Artikelvarianten
          </v-alert>
          <v-spacer/>
          <v-simple-table class="table-full-width table-content">
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Artikelvariante
                </th>
                <th class="text-left">
                  Bestand Markt
                </th>
                <th class="text-left">
                  Bestand Lager
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="articleVariant in articleData.restVariants"
                  :key="articleVariant.articleDetailId"
              >
                <td>[{{ articleVariant.ordernumber }}] {{
                    createNameFromConfigurator(articleVariant.configurator)
                  }}
                </td>
                <td>{{ articleVariant.salesStock.stock }}</td>
                <td>{{ articleVariant.warehouseStock }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-row>
        <v-row class="reorder-button-container">
          <v-spacer/>
          <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              :to="{name: 'reorder', params: { articleData }}"
          >
            <v-icon
                left
                dark
            >
              mdi-cart-arrow-right
            </v-icon>
            Artikel anfordern
          </v-btn>
          <v-spacer/>
        </v-row>

      </v-container>

    </v-container>
  </v-container>
</template>

<script>
import ImageLoader from "../Others/ImageLoader";
import VerticalSpacer from "../Others/VerticalSpacer";
import Scanner from "../Others/Scanner";
import ErrorDialog from "../Others/ErrorDialog";
import StockEditDialog from "./StockEditDialog";

export default {
  components: {StockEditDialog, ErrorDialog, Scanner, VerticalSpacer, ImageLoader},
  data: () => ({
    showSearchIcon: false,
    ean: null,
    articleNumber: '',
    articleData: null,
    isLoading: false,
    isError: false,
    errorContent: '',
    total: 0,
    scannedPalette: null,
    stockEditDialog: false,
    stockEditArticle: null
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('onStockEditDialogClosed', this.handleCloseStockEditDialog);
    this.$root.$on('onStockProcessed', this.handleStockProcessed);
  },

  mounted() {
    this.processInitialProductNumber();
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('onStockEditDialogClosed', this.handleCloseStockEditDialog);
    this.$root.$off('onStockProcessed', this.handleStockProcessed);
  },

  methods: {
    openStockEditDialog() {
      this.stockEditDialog = true;
      this.stockEditArticle = this.articleData;
    },

    handleStockProcessed(isSuccess, errorMessage) {
      this.closeStockEditDialog();
      if(isSuccess) {
        this.isError = false;
        this.errorContent = '';
        this.loadScannedProduct();
      } else {
        this.isError = true;
        this.errorContent = errorMessage;
      }
    },

    closeStockEditDialog() {
      this.stockEditDialog = false;
      this.stockEditArticle = null;
    },

    processInitialProductNumber() {
      if(this.$route.params && this.$route.params.initialProductNumber) {
        this.onScannerInputHandler(this.stringifyScannerInput(this.$route.params.initialProductNumber));
      }
      if(this.$route.params && this.$route.params.scannedPalette) {
        this.scannedPalette = this.$route.params.scannedPalette;
      }
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    handleCloseStockEditDialog() {
      this.closeStockEditDialog();
    },
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    loadScannedProduct() {
      if (this.articleNumber) {
        this.isLoading = true;
        this.$axios.get('productInfo/' + this.articleNumber).then(response => {
          this.isLoading = false;
          if (response.data && response.data.success === false) {
            this.isError = true;
            this.errorContent = response.data.message;
            this.articleNumber = '';
          }
          if (response.data && response.data.data) {
            this.isError = false;
            this.errorContent = '';
            this.articleData = response.data.data;
            this.calculateTotalStock();
          }
          this.$refs.scannerResult.blur();
        })
      }
    },
    onScannerInputHandler(scannerOutput) {
      scannerOutput = this.parseJson(scannerOutput);
      this.articleData = null;
      this.articleNumber = scannerOutput.dataAsText;
      this.$refs.scannerResult.$emit('change');
    },
    stringifyScannerInput(value) {
      return JSON.stringify({dataAsText: value});
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    calculateTotalStock() {
      let total = 0;
      for (const binLocation of this.articleData.availableStock.L.binLocations) {
        total += Number(binLocation.stock);
      }
      this.total = total;
    }
  }
};
</script>

<style lang="scss">
.table-full-width {
  width: 100%;
}

.reorder-button-container {
  margin-top: 50px !important;
}

.status-value {
  margin-left: 10px;
  font-size: large;
}

.table-content {
  max-height: 300px;
  overflow-y: scroll;
}

.back-button {
  text-align: center;
}
</style>