<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template class="rearrange-palette-container" v-if="isLoaded">
      <template>
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Bahnhof Markt
        </v-alert>


        <v-container class="palette-information-container">
          <v-alert
              color="primary"
              elevation="24"
              outlined
              v-model="showStartInfo"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
            <p>Scannen Sie die Palette welche von dem <strong>Bahnhof Lager</strong> geliefert wurde um diese als
              geliefert zu
              bestätigen.</p>
          </v-alert>
          <v-layout justify-center>
            <v-alert
                color="primary"
                elevation="24"
                outlined
                width="60px"
                v-model="showIconOnly"
                @click="toggleInfo"
            >
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-alert>
          </v-layout>
        </v-container>
        <v-container class="text-align-center">
          <p>{{ selectedPalette ? selectedPalette.names.displayName : 'Palette scannen' }}</p>
          <v-text-field
              v-model="scannerResult"
              label="Palette Barcode Wert"
              outlined
              ref="scannerResult"
              readonly
              dense
              style="margin: auto"
          ></v-text-field>
          <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              :disabled="!scannerResult"
              :to="{name: 'rearrange.product.product', params: {selectedPalette: scannerResult}}"
          >
            <v-icon
                left
                dark
            >
              mdi-cart-arrow-right
            </v-icon>
            Palette<br/>
            bestätigen
          </v-btn>
        </v-container>
        <h5>Manuelle Auswahl der Palette</h5>
        <v-virtual-scroll
            :items="warehouseFromObj.palettes"
            :item-height="55"
            :height="200"
            class="palette-scroll"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.code" class="palette-list-item">
              <v-btn
                  @click="selectPalette(item)"
                  block
                  color="secondary"
                  class="white--text palette-button"
              >
                {{ item.names.displayName }} <br/>
              </v-btn>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </template>
    </template>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <VerticalSpacer/>

  </v-container>
</template>


<script>
import VerticalSpacer from "../../Others/VerticalSpacer";
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, LoginRequired, VerticalSpacer},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    warehouses: null,
    warehouseFrom: 'BhfL',
    warehouseTo: 'BhfM',
    warehouseFromObj: null,
    warehouseToObj: null,
    isLoaded: false,
    warehouseNames: [],
    result: null,
    scannerResult: '',
    isError: false,
    errorContent: ''
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        for (const warehouse of this.warehouses) {
          this.warehouseNames.push(warehouse.name);
        }
        this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
        this.warehouseToObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseTo)[0];
        this.isLoaded = true;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    selectPalette(palette) {
      this.scannerResult = palette.code;
    },
    onScannerInputHandler(scannerResponse) {
      scannerResponse = this.parseJson(scannerResponse);
      const palettes = this.warehouseFromObj.palettes;
      const paletteCode = scannerResponse.dataAsText.split('$')[1];
      const doesExists = palettes.filter(palette => palette.code === paletteCode).length > 0;
      if (doesExists) {
        this.isError = false;
        this.scannerResult = paletteCode;
      } else {
        this.isError = true;
        this.errorContent = paletteCode + ' nicht gefunden im ' + this.warehouseFrom + '!';
        this.scannerResult = '';
      }
      this.$refs.scannerResult.blur();
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    }
  }
}
</script>