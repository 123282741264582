<template>
  <v-container fluid>
    <v-layout>
      <v-flex xs12>
        <v-card class="elevation-12" v-if="credentials && credentials.isLogged">
          <v-card-text>
            <v-alert
                outlined
                type="success"
                text
            >
              Angemeldet als: {{ credentials.userName }} ({{ credentials.userLogin }})
            </v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="logout">Abmelden</v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
        <template v-else>
          <Scanner/>
          <v-alert v-if="$route.params.errorMsg"
                   dense
                   outlined
                   type="error"
                   v-text="$route.params.errorMsg"
          />
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Anmelden</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-alert
                  dense
                  close-text="Schließen"
                  outlined
                  type="error"
                  dismissible
                  v-model="isError"
              > {{ error }}
              </v-alert>
              <v-alert v-if="scannerError"
                       dense
                       outlined
                       type="error"
              >
                <div class="close-button v-btn--absolute">
                  <v-btn
                      icon
                      right
                      @click="hideScannerError"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                {{ scannerErrorText }}
              </v-alert>
              <v-form>
                <v-text-field
                    prepend-icon="mdi-account"
                    name="username"
                    label="Username"
                    type="text"
                    v-model="user"
                ></v-text-field>
                <v-text-field
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Passwort"
                    type="password"
                    v-model="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">Anmelden</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import Scanner from "./Others/Scanner";

export default {
  name: 'Login',
  components: {Scanner},
  props: {
    source: String,
  },
  data: () => ({
    user: null,
    password: null,
    isError: false,
    error: null,
    credentials: null,
    scannerError: false,
    scannerErrorText: ''
  }),

  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  mounted() {
    this.credentials = this.getCredentials();
  },

  methods: {
    onScannerInputHandler(scannerOutputRaw) {
      const scannerOutput = this.parseJson(scannerOutputRaw);

      const scannerOutputArray = scannerOutput.dataAsText.split(';');

      if (scannerOutputArray <= 1) {
        return this.showScannerError(scannerOutput.dataAsText);
      }

      const userName = scannerOutputArray[0];
      const password = scannerOutputArray.slice(1).join(';');

      this.hideScannerError();
      this.user = userName.trim();
      this.password = password.trim();
      this.login();
    },

    showScannerError(scannedCredentials) {
      this.scannerErrorText = `Kann nicht anmelden. ${scannedCredentials}  ist kein gültiger Scanner-Barcode für die Authentifizierung.`;
      this.scannerError = true;
    },

    hideScannerError() {
      this.scannerError = false;
      this.scannerErrorText = '';
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },

    login() {
      const params = {user: this.user, password: this.password};
      this.$axios.post('apiLogin', params).then(response => {
            this.isError = false;
            this.error = null;
            this.isLogged = true;
            this.setCredentials(response.data.data);
            if (this.$route.params.target) {
              this.$router.push({name: this.$route.params.target}, () => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }, error => {
            this.isError = true;
            this.error = error.response.data.message;
            this.isLogged = false;
            this.unsetCredentials();
          }
      );
    },

    logout() {
      this.unsetCredentials();
      window.location.reload();
    },

    setCredentials(credentials) {
      localStorage.setItem('apiAuthKey', credentials.apiAuthKey);
      localStorage.setItem('userLogin', credentials.userLogin);
      localStorage.setItem('userName', credentials.userName)
      localStorage.setItem('userId', credentials.userId);
      localStorage.setItem('isLogged', '1');
      this.$axios.defaults.headers.common['Authorization'] = 'Basic ' + credentials.apiAuthKey;
      this.credentials = this.getCredentials();
    },

    unsetCredentials() {
      localStorage.removeItem('apiAuthKey');
      localStorage.removeItem('userLogin');
      localStorage.removeItem('userName');
      localStorage.removeItem('isLogged')
      localStorage.removeItem('userId');

      this.$axios.defaults.headers.common['Authorization'] = process.env.VUE_APP_API_AUTH;
      this.credentials = this.getCredentials();
    },

    getCredentials() {
      return {
        apiAuthKey: localStorage.getItem('apiAuthKey'),
        userLogin: localStorage.getItem('userLogin'),
        userName: localStorage.getItem('userName'),
        isLogged: localStorage.getItem('isLogged'),
        userId: localStorage.getItem('userId')
      };
    }
  }
};
</script>

<style scoped>
 .close-button {
   right: 1px;
   top: 1px;
 }
</style>
