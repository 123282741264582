<template>
  <div>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Bahnhof Markt: <strong>{{ selectedPalette }}</strong>
      </v-alert>
      <v-alert
          v-if="result"
          dismissible
          type="info"
      >
        {{ result }}
      </v-alert>


      <v-container class="palette-information-container">
        <v-alert
            color="primary"
            elevation="24"
            outlined
            v-model="showStartInfo"
            @click="toggleInfo"
        >
          <v-icon color="primary">mdi-information-outline</v-icon>
          <p>Scannen Sie das Produkt und wählen Sie die Menge aus, die in den {{ warehouseToObj.name }}
            {{ selectedPalette }} verlagert werden soll</p>
        </v-alert>
        <v-layout justify-center>
          <v-alert
              color="primary"
              elevation="24"
              outlined
              width="60px"
              v-model="showIconOnly"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-alert>
        </v-layout>
      </v-container>

      <v-container class="text-align-center">
        <p>Produkt scannen</p>
        <v-text-field
            v-model="scannerResult"
            label="Produkt Barcode Wert"
            ref="scannerResult"
            outlined
            readonly
            dense
            style="margin: auto"
        ></v-text-field>
        <v-text-field
            v-model="articleQuantity"
            :value="1"
            :min="1"
            :max="5000"
            type="number"
            label="Produkt Menge"
            outlined
            dense
            style="margin: auto"
        ></v-text-field>
      </v-container>
    </template>
    <v-row>
      <v-btn
          :to="{ name: 'rearrange.product.palette' }"
          outlined
          color="primary"
      >
        Palette <br/>
        auswählen
      </v-btn>
      <v-divider/>
      <v-btn
          @click="relocateProduct"
          class="ma-2 success"
          outlined
          color="primary"
          :disabled="scannerResult === ''"
      >
        Umziehen
      </v-btn>
    </v-row>
  </div>
</template>


<script>
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, LoginRequired},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    selectedProduct: null,
    warehouses: null,
    warehouseFrom: 'BhfL',
    warehouseTo: 'BhfM',
    warehouseFromObj: null,
    warehouseToObj: null,
    isLoaded: false,
    warehouseNames: [],
    result: null,
    scannerResult: '',
    isError: false,
    errorContent: '',
    articleQuantity: 1,
    productsToRelocate: []
  }),
  created() {
    this.selectedPalette = this.$route.params.selectedPalette;
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$on('onScannerInput', this.onScannerInputHandler);

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        for (const warehouse of this.warehouses) {
          this.warehouseNames.push(warehouse.name);
        }
        this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
        this.warehouseToObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseTo)[0];

        this.isLoaded = true;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },

  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    relocateProduct() {
      this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
      this.warehouseToObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseTo)[0];

      // const sourceBinLocation = this.warehouseFromObj.palettes.filter(palette => palette.code === this.selectedPalette)[0]['id'];
      const destinationBinLocation = this.warehouseToObj.palettes.filter(palette => palette.code === this.selectedPalette)[0]['id'];

      const params = [{
        articleDetailId: Number(this.selectedProduct.articleDetailId),
        quantity: this.articleQuantity,
        source: {
          warehouse: {
            id: Number(this.warehouseFromObj.id)
          }
        },
        destination: {
          binLocation: {
            id: Number(destinationBinLocation)
          }
        }
      }];

      const address = 'stock/relocate/' + this.$uuid.v4();
      this.$axios.put(address, params).then(response => {
        if (response.data && response.data.success === true) {
          this.result = `Artikel ${this.selectedProduct.number} mit menge ${this.articleQuantity}
        wurde verlegt nach: ${this.warehouseToObj.name} ${this.selectedPalette}`;
          this.scannerResult = null;
        } else if (response.data && response.data.success === false) {
          this.result = response.data.message;
        }

      });
    },
    onScannerInputHandler(scannerResponse) {
      scannerResponse = this.parseJson(scannerResponse);
      const productCode = scannerResponse.dataAsText;
      this.result = null;
      this.$axios.get('productInfo/' + productCode).then(response => {
        this.isLoading = false;
        if (response.data && response.data.success === false) {
          this.isError = true;
          this.errorContent = response.data.message;
          this.$refs.scannerResult.focus();
          this.scannerResult = '';
          this.selectedProduct = null;
        } else if (response.data && response.data.data) {
          this.isError = false;
          this.errorContent = '';
          this.selectedProduct = response.data.data;
          this.scannerResult = productCode;
          this.$refs.scannerResult.focus();
        }
        this.$refs.scannerResult.blur();
      })

    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    }
  }
}
</script>