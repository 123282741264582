<template>
  <v-app-bar
      app
      color="primary"
      dark
      dense
      class="no-print"
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer()" v-click-outside="disableDrawer"/>

    <!--    todo make the text size to match the box, do not truncate it!-->
    <v-app-bar-title v-text="appBarTitle"/>

    <v-spacer/>

    <v-btn icon class="no-print">
      <v-icon>mdi-dots-vertical</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  watch: {
    group() {
      this.drawer = false;
      this.emmitDrawerState();
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    appBarTitle: null
  }),
  created() {
    this.$root.$on('navigationItemChanged', this.onNavigationItemChanged);
    this.$root.$on('drawStatusChanged', this.onDrawStatusChanged);
  },

  beforeDestroy() {
    this.$root.$off('navigationItemChanged', this.onNavigationItemChanged);
    this.$root.$off('drawStatusChanged', this.onDrawStatusChanged);
  },

  methods: {
    onNavigationItemChanged(activeTabValue) {
      this.appBarTitle = activeTabValue;
    },

    onDrawStatusChanged(drawer) {
      this.drawer = drawer;
    },

    toggleDrawer(value = undefined) {
      if (value !== undefined) {
        this.drawer = value;
      } else {
        this.drawer = !this.drawer;
      }
      this.emmitDrawerState();
    },
    disableDrawer() {
      this.drawer = false;
    },
    emmitDrawerState() {
      this.$root.$emit('drawStatusChanged', this.drawer);
    }
  }
}
</script>

<style lang="scss">
.v-toolbar__title {
  padding: 0;
}
</style>