<template>
  <v-container fluid>
    <LoginRequired/>
    <v-layout>
      <v-container class="departments-container" v-if="departments" fluid>
        <v-list width="100%">
          <v-alert
              color="primary"
              elevation="24"
              icon="mdi-information-outline"
              type="info"
          >
            Auswahl der Pickliste
          </v-alert>
          <v-list-item-group
              color="primary">
            <v-list-item
                :to="{ name: 'department', params: { id: department.id, department, palettes } }"
                class="department-list-item"
                v-for="(department, i) in departments"
                :key="i"
                :disabled="department.picklist.length === 0"
            >
              <v-list-item-icon>
                <v-icon>mdi-iframe-array-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="department.picklistDisplay"/>
                <v-list-item-subtitle
                    v-text="department.picklist.length === 0 ? '0/0' : department.picklist.length + '/' + department.picklist.totalCount"
                    class="bin-location-status"
                />
              </v-list-item-content>
            </v-list-item>

          </v-list-item-group>
        </v-list>
      </v-container>
      <v-layout v-else align-content-center justify-center align-center>
        <v-progress-circular indeterminate
                             color="primary"/>
      </v-layout>
    </v-layout>
  </v-container>
</template>
<script>

import LoginRequired from "../Others/LoginRequired";

export default {
  components: {LoginRequired},
  data: () => ({
    drawer: false,
    group: null,
    departments: null,
    palettes: null
  }),
  created() {
    this.$axios.get('departments').then(response => {
      if (response && response.data && response.data.data) {
        if (response.data.data.departments) {
          this.departments = response.data.data.departments;
          this.mergeManualReorders();
          this.calculatePicklist();
        }
        if (response.data.data.palettes) {
          this.palettes = response.data.data.palettes;
        }
      }
    })
  },
  methods: {
    mergeManualReorders() {
      for (const department of this.departments) {
        const manualReordersToAdd = [];
        for (const manualReorder of department.manualReorders) {
          const targetPicklist = department.picklist.filter(
              article => Number(article.articleDetailId) === Number(manualReorder.articleDetailId)
          );
          if (targetPicklist.length > 0) {
            targetPicklist[0].proposedReorder += Number(manualReorder.reorderedQuantity);
          } else {
            manualReorder.proposedReorder = Number(manualReorder.reorderedQuantity);
            manualReorder.manualReorderAmount = Number(manualReorder.reorderedQuantity);
            manualReordersToAdd.push(manualReorder);
          }
        }

        department.picklist = [...manualReordersToAdd, ...department.picklist];

        if (department.picklist && department.picklist.length > 0) {
          for (const picklistArticle of department.picklist) {
            picklistArticle.proposedReorder -= picklistArticle.actuallyPicked;
          }
          department.picklist = department.picklist.filter(article => article.proposedReorder > 0);
        }

        const manualReorders = department.picklist.filter(article => article.manualReorderAmount > 0);
        department.picklist = department.picklist.filter(article => article.manualReorderAmount <= 0);

        manualReorders.sort((a, b) => {
          if (!a.availableStock.L.binLocations || a.availableStock.L.binLocations.length === 0) {
            return 1;
          }

          if (!b.availableStock.L.binLocations || b.availableStock.L.binLocations.length === 0) {
            return -1;
          }

          let binLocationA = a.availableStock.L.binLocations[0].code;
          let binLocationB = b.availableStock.L.binLocations[0].code;

          if (this.isNumeric(binLocationA) && !this.isNumeric(binLocationB)) {
            return -1;
          }

          if (!this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
            return 1;
          }

          if (this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
            return binLocationA - binLocationB;
          }

          return binLocationA.localeCompare(binLocationB);
        });

        department.picklist = [...manualReorders, ...department.picklist];
      }
    },
    isNumeric(value) {
      return !isNaN(value) && isFinite(value);
    },
    calculatePicklist() {
      for (const department of this.departments) {
        let pickListTotalCount = 0;
        for (const product of department.picklist) {
          pickListTotalCount += product.proposedReorder;
        }
        department.picklist.totalCount = pickListTotalCount;
      }
    }
  }
}
</script>

<style lang="scss">
</style>
