<template>
  <v-container>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template class="departments-container" v-if="department && palettes">
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Pickliste: <br /> {{ department.picklistDisplay }} <span
          class="float-right">{{ department.picklist.length + '/' + department.picklist.totalCount }}</span>
      </v-alert>

      <v-container class="palette-information-container">
        <v-alert
            color="primary"
            elevation="24"
            outlined
            v-model="showStartInfo"
            @click="toggleInfo"
        >
          <v-icon color="primary">mdi-information-outline</v-icon>
          <p>Scannen Sie die gewünschte Palette ein, um den Pickvorgang für
            <strong>{{ department.picklistDisplay }}</strong> zu starten.</p>
          <p>Den Barcode finden Sie auf der linken Seite neben dem EUR Zeichen.</p>
          <p>Alternativ können Sie die Palette unten manuell auswählen.</p>
        </v-alert>
        <v-layout justify-center>
          <v-alert
              color="primary"
              elevation="24"
              outlined
              width="60px"
              v-model="showIconOnly"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
          </v-alert>
        </v-layout>
      </v-container>

      <v-container class="text-align-center">
        <p>{{ selectedPalette ? selectedPalette.names.displayName : 'Palette scannen' }}</p>
        <v-text-field
            :value="selectedPalette ? selectedPalette.code : null"
            prepend-icon="mdi-barcode-scan"
            label="Palette scannen"
            outlined
            readonly
            dense
            style="margin: auto"
            ref="scannerResult"
        ></v-text-field>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            :to="{ name: 'picklist', params: {id: this.department.id, selectedPalette: this.selectedPalette ? this.selectedPalette.code : '99_Palette_1'} }"
            :disabled="selectedPalette === null"
        >
          <v-icon
              left
              dark
          >
            mdi-cart-arrow-right
          </v-icon>
          Palette<br/>
          auswählen
        </v-btn>
      </v-container>
      <v-virtual-scroll
          :items="palettes"
          :item-height="55"
          :height="200"
          class="palette-scroll"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item.code" class="palette-list-item">
            <v-btn
                @click="selectPalette(item)"
                block
                color="secondary"
                class="white--text palette-button"
                :disabled="!item.isEmpty && item.usedIn[0].warehouseCode !== 'BhfL'">
              {{ item.names.displayName }} <br/>
              <div v-if="item.usedIn.length > 0" class="palette-button-subtitle">{{
                  item.usedIn[0].warehouseCode === 'BhfL' ? ' - Fortsetzen' : '[' + item.usedIn[0].warehouseName + ']'
                }}
              </div>
            </v-btn>
          </v-list-item>
        </template>
      </v-virtual-scroll>

    </template>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <VerticalSpacer/>

    <v-row>
      <v-spacer/>
      <v-btn
          :to="{ name: 'department', params: {id: this.id} }"
          outlined
          color="primary"
      >
        Zurück
      </v-btn>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>

import VerticalSpacer from "../Others/VerticalSpacer";
import Scanner from "../Others/Scanner";
import ErrorDialog from "../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, VerticalSpacer},
  data: () => ({
    id: null,
    department: null,
    palettes: null,
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    isError: false,
    errorContent: ''
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);

    const routeParams = this.$route.params;
    this.id = routeParams.id;
    this.department = routeParams.department;
    this.palettes = routeParams.palettes;

    if (!this.$route.params.department) {
      this.$axios.get('departments/' + this.id).then(response => {
        if (response && response.data && response.data.data) {
          if (response.data.data.departments) {
            this.department = response.data.data.departments[0];
            this.mergeManualReorders();
            this.calculatePicklist();
          }
          if (response.data.data.palettes) {
            this.palettes = response.data.data.palettes.filter(palette =>
              palette.isEmpty || palette.usedIn[0].warehouseCode === 'BhfL'
            );
          }

          if (this.$route.params.scannerInputRaw) {
            this.$root.$emit('onScannerInput', this.$route.params.scannerInputRaw);
            this.$route.params.scannerInputRaw = null;
          }
        }
      })
    }
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  methods: {
    mergeManualReorders() {
      const manualReordersToAdd = [];
      for (const manualReorder of this.department.manualReorders) {
        const targetPicklist = this.department.picklist.filter(
            article => Number(article.articleDetailId) === Number(manualReorder.articleDetailId)
        );
        if (targetPicklist.length > 0) {
          targetPicklist[0].proposedReorder += Number(manualReorder.reorderedQuantity);
        } else {
          manualReorder.proposedReorder = Number(manualReorder.reorderedQuantity);
          manualReorder.manualReorderAmount = Number(manualReorder.reorderedQuantity);
          manualReordersToAdd.push(manualReorder);
        }
      }

      this.department.picklist = [...manualReordersToAdd, ...this.department.picklist];

      if (this.department.picklist && this.department.picklist.length > 0) {
        for (const picklistArticle of this.department.picklist) {
          picklistArticle.proposedReorder -= picklistArticle.actuallyPicked;
        }
        this.department.picklist = this.department.picklist.filter(article => article.proposedReorder > 0);
      }

      const manualReorders = this.department.picklist.filter(article => article.manualReorderAmount > 0);
      this.department.picklist = this.department.picklist.filter(article => article.manualReorderAmount <= 0);

      manualReorders.sort((a, b) => {
        if (!a.availableStock.L.binLocations || a.availableStock.L.binLocations.length === 0) {
          return 1;
        }

        if (!b.availableStock.L.binLocations || b.availableStock.L.binLocations.length === 0) {
          return -1;
        }

        let binLocationA = a.availableStock.L.binLocations[0].code;
        let binLocationB = b.availableStock.L.binLocations[0].code;

        if (this.isNumeric(binLocationA) && !this.isNumeric(binLocationB)) {
          return -1;
        }

        if (!this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return 1;
        }

        if (this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return binLocationA - binLocationB;
        }

        return binLocationA.localeCompare(binLocationB);
      });

      this.department.picklist = [...manualReorders, ...this.department.picklist];
    },
    isNumeric(value) {
      return !isNaN(value) && isFinite(value);
    },
    calculatePicklist() {
      let pickListTotalCount = 0;
      for (const product of this.department.picklist) {
        pickListTotalCount += product.proposedReorder;
      }
      this.department.picklist.totalCount = pickListTotalCount;
    },
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    selectPalette(palette) {
      this.selectedPalette = palette;
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const paletteCode = scannerInput.dataAsText.split('$')[1];
      if (!paletteCode) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`${scannerInput.dataAsText} ist keine gültige Eingabe für den Lagerplatz!`);
      }
      const targetPalette = this.palettes.filter(palette => palette.code === paletteCode)[0];

      if (!targetPalette) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} kann nicht gefunden werden!`);
      }

      if (!targetPalette.isEmpty && !this.isUsedInBahnhoffLager(targetPalette)) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} ist in Verwendung: ${targetPalette.usedIn[0].warehouseName}!`);
      }
      this.selectPalette(targetPalette);
      this.$refs.scannerResult.blur();
    },

    handleCloseErrorDialog() {
      this.isError = false;
    },

    setScannerError(message) {
      this.isError = true;
      this.errorContent = message;
    },

    isUsedInBahnhoffLager(targetPalette) {
      return targetPalette.usedIn.length > 0 ? targetPalette.usedIn[0].warehouseCode === 'BhfL' : false;
    }

  }
};
</script>

<style lang="scss">
.text-align-center {
  text-align: center;
}

.text-field-holder {
  width: 250px;
}

.palette-list-item {
  padding: 0;
}

.palette-button {
  white-space: break-spaces !important;
}
</style>
