<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />
    <template class="rearrange-palette-container" v-if="isLoaded">
      <template>
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Komplette Palette einlagern
        </v-alert>

        <v-container class="palette-information-container">
          <v-alert
              color="primary"
              elevation="24"
              outlined
              v-model="showStartInfo"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
            <p>Scannen Sie die Palette, deren kompletten Artikelbestand Sie auf einen Lagerplatz im Lager buchen wollen.</p>
          </v-alert>
          <v-layout justify-center>
            <v-alert
                color="primary"
                elevation="24"
                outlined
                width="60px"
                v-model="showIconOnly"
                @click="toggleInfo"
            >
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-alert>
          </v-layout>
        </v-container>

        <v-container class="text-align-center" v-if="palettes">
          <v-text-field
              :value="selectedPalette ? selectedPalette.code : ''"
              label="Palette scannen"
              outlined
              readonly
              ref="scannerResult"
              dense
              prepend-icon="mdi-barcode-scan"
              style="margin: auto"
          ></v-text-field>
          <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              :disabled="selectedPalette === null"
              :to="{
                name: 'rearrange.warehouse.select_bin_location',
                params: selectedPalette ? {
                  binLocationId: selectedPalette.usedIn[0]['binLocationId'],
                  paletteName: selectedPalette.names.displayName,
                  warehouseName: selectedPalette.warehouseName,
                  warehouseCode: selectedPalette.usedIn[0]['warehouseCode'],
                } : {
                  binLocationId: 0,
                  paletteName: 'none',
                  warehouseName: 'none',
                  warehouseCode: 'none'
                }}"
          >
            <v-icon
                left
                dark
            >
              mdi-cart-arrow-right
            </v-icon>
            Palette<br/>
            auswählen
          </v-btn>
        </v-container>
        <h5>Manuelle Auswahl der Palette</h5>
        <v-virtual-scroll
            :items="palettes"
            :item-height="55"
            :height="200"
            class="palette-scroll"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.code" class="palette-list-item">
              <v-btn
                  @click="selectPalette(item)"
                  block
                  color="secondary"
                  class="white--text palette-button"
                  :disabled="item.isEmpty"
              >
                {{ item.names.displayName }}<span v-if="item.warehouseName"> - [{{ item.warehouseName }}]</span>
              </v-btn>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </template>
    </template>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <VerticalSpacer/>

  </v-container>
</template>


<script>
import VerticalSpacer from "../../Others/VerticalSpacer";
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, LoginRequired, VerticalSpacer},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    warehouses: null,
    warehouseFrom: 'BhfM',
    warehouseTo: 'L',
    warehouseFromObj: null,
    warehouseToObj: null,
    isLoaded: false,
    result: null,
    isError: false,
    errorContent: '',
    palettes: [],
    selectedWarehouse: {name: 'Test'}
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        this.mergeWarehousePalettes();
        this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
        this.warehouseToObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseTo)[0];
        this.isLoaded = true;
      }
    })
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },
  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    selectPalette(palette) {
      this.selectedPalette = palette;
    },

    mergeWarehousePalettes() {
      const market = this.warehouses.filter(warehouse => warehouse.code === 'M')[0];
      const palettes = market.palettes.slice(0);
      for (const warehouse of this.warehouses) {
        for (const [index, palette] of (warehouse.palettes).entries()) {
          if (!palette.isEmpty) {
            palette.warehouseId = warehouse.id
            palette.warehouseName = warehouse.name
            palettes[index] = palette;
          }
        }
      }
      this.palettes = palettes.filter(palette => !palette.isEmpty);
    },

    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const paletteCode = scannerInput.dataAsText.split('$')[1];
      if (!paletteCode) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`${scannerInput.dataAsText} ist keine gültige Eingabe für einen Lagerplatz!`);
      }
      const sourcePalette = this.warehouseFromObj.palettes.filter(palette => palette.code === paletteCode)[0];

      if (!sourcePalette) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} kann nicht gefunden werden!`);
      }

      if (sourcePalette.isEmpty) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} ist leer!`);
      }

      this.selectPalette(sourcePalette);
      this.$refs.scannerResult.blur();
    },

    handleCloseErrorDialog() {
      this.isError = false;
    },

    setScannerError(message) {
      this.isError = true;
      this.errorContent = message;
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
  }
}
</script>