<template>
  <v-container>
    <Scanner/>
    <v-container class="departments-container" v-if="department && palettes">

      <div class="tooltip-container"
           v-if="showToolTip"
           v-on:click.stop.prevent="closeToolTip"
      >
        <v-tooltip
            v-model="showToolTip"
            class="bin-location-tooltip"
            absolute
            :position-x="toolTipX"
            :position-y="toolTipY"
            bottom
            :z-index="998"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
            </v-btn>
          </template>
          <p class="mb-0" v-for="toolTipContentRow in toolTipContent" :key="toolTipContentRow">
            {{ toolTipContentRow }}
          </p>
        </v-tooltip>
      </div>

      <v-alert
          color="primary"
          icon="mdi-information-outline"
          type="info"
          dense
      >
        Pickliste: <br /> {{ department.picklistDisplay }} <span
          class="float-right">{{ department.picklist.length + '/' + department.picklist.totalCount }}</span>
      </v-alert>
      <v-row>
        <v-spacer/>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text text-wrap"
            :to="{ name: 'selectPalette' }"
        >
          <v-icon
              left
              dark
          >
            mdi-barcode-scan
          </v-icon>
          Palette scannen<br/>
          zum Starten
        </v-btn>
        <v-spacer/>
      </v-row>
      <VerticalSpacer/>
      <v-card
          width="100%"
          class="mx-auto"
      >
        <v-virtual-scroll
            :items="department.picklist"
            item-height="150"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.number" class="list-item">
              <v-list-item-action class="quantity">
                <v-list-item-action-text class="proposed-reorder">{{ item.proposedReorder }}x</v-list-item-action-text>
              </v-list-item-action>

              <v-list-item-action class="image">
                <v-card
                    class="pa-2 product-image-container"
                    outlined
                    tile
                    width="50"
                    height="50"
                >
                  <v-img :src="item.imagePath">
                    <template v-slot:placeholder>
                      <ImageLoader/>
                    </template>
                  </v-img>
                </v-card>

              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-title v-if="item.configurator">
                  {{ createNameFromConfigurator(item.configurator) }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ item.ordernumber }}
                </v-list-item-title>
                <v-list-item-title v-if="item.agiqonArticleSupplierQuantity && parseInt(item.agiqonArticleSupplierQuantity) > 0">
                  VPE Lager: {{ item.agiqonArticleSupplierQuantity }}
                </v-list-item-title>
                <v-list-item-title>

                  Lagerplatz
                  <v-icon v-on:click.stop.prevent="displayBinLocationsToolTip(item, $event)">mdi-dots-horizontal
                  </v-icon>
                  <!--                  {{item.availableStock.L.binLocations[0].nameData.displayName }}-->
                </v-list-item-title>


                <span v-if="item.manualReorderAmount"
                      class="manually-reordered-info">
              Manuell nachbestellt: {{ item.manualReorderAmount }}
            </span>
              </v-list-item-content>
            </v-list-item>
          <v-divider/>
          </template>
        </v-virtual-scroll>
      </v-card>

    </v-container>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <br />
    <v-row>
      <v-spacer/>
      <v-btn
          :to="{ name: 'departments' }"
          outlined
          color="primary"
      >
        Zurück
      </v-btn>
      <v-spacer/>
    </v-row>
    <!--    </v-layout>-->
  </v-container>
</template>

<script>
import ImageLoader from "../Others/ImageLoader";
import VerticalSpacer from "../Others/VerticalSpacer";
import Scanner from "../Others/Scanner";

export default {
  components: {Scanner, VerticalSpacer, ImageLoader},
  data: () => ({
    id: null,
    department: null,
    palettes: null,
    showToolTip: false,
    toolTipX: 0,
    toolTipY: 0,
    toolTipContent: [],
    toolTipTarget: null
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);

    const routeParams = this.$route.params;
    this.id = routeParams.id;
    this.department = routeParams.department;
    this.palettes = routeParams.palettes;

    if (!this.$route.params.department) {
      this.$axios.get('departments/' + this.id).then(response => {
        if (response && response.data && response.data.data) {
          if (response.data.data.departments) {
            this.department = response.data.data.departments[0];
            this.mergeManualReorders();
            this.calculatePicklist();
          }
          if (response.data.data.palettes) {
            this.palettes = response.data.data.palettes;
          }
        }
      })
    }
  },
  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
  },
  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    onScannerInputHandler(scannerInputRaw) {
      this.$router.push({name: 'selectPalette', params: {scannerInputRaw}}).catch(() => {
      });
    },
    mergeManualReorders() {
      const manualReordersToAdd = [];
      for (const manualReorder of this.department.manualReorders) {
        const targetPicklist = this.department.picklist.filter(
            article => Number(article.articleDetailId) === Number(manualReorder.articleDetailId)
        );
        if (targetPicklist.length > 0) {
          targetPicklist[0].proposedReorder += Number(manualReorder.reorderedQuantity);
        } else {
          manualReorder.proposedReorder = Number(manualReorder.reorderedQuantity);
          manualReorder.manualReorderAmount = Number(manualReorder.reorderedQuantity);
          manualReordersToAdd.push(manualReorder);
        }
      }

      this.department.picklist = [...manualReordersToAdd, ...this.department.picklist];

      if (this.department.picklist && this.department.picklist.length > 0) {
        for (const picklistArticle of this.department.picklist) {
          picklistArticle.proposedReorder -= picklistArticle.actuallyPicked;
        }
        this.department.picklist = this.department.picklist.filter(article => article.proposedReorder > 0);
      }

      const manualReorders = this.department.picklist.filter(article => article.manualReorderAmount > 0);
      this.department.picklist = this.department.picklist.filter(article => article.manualReorderAmount <= 0);

      manualReorders.sort((a, b) => {
        if (!a.availableStock.L.binLocations || a.availableStock.L.binLocations.length === 0) {
          return 1;
        }

        if (!b.availableStock.L.binLocations || b.availableStock.L.binLocations.length === 0) {
          return -1;
        }

        let binLocationA = a.availableStock.L.binLocations[0].code;
        let binLocationB = b.availableStock.L.binLocations[0].code;

        if (this.isNumeric(binLocationA) && !this.isNumeric(binLocationB)) {
          return -1;
        }

        if (!this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return 1;
        }

        if (this.isNumeric(binLocationA) && this.isNumeric(binLocationB)) {
          return binLocationA - binLocationB;
        }

        return binLocationA.localeCompare(binLocationB);
      });

      this.department.picklist = [...manualReorders, ...this.department.picklist];
    },
    isNumeric(value) {
      return !isNaN(value) && isFinite(value);
    },
    calculatePicklist() {
      let pickListTotalCount = 0;
      for (const product of this.department.picklist) {
        pickListTotalCount += product.proposedReorder;
      }
      this.department.picklist.totalCount = pickListTotalCount;
    },
    displayBinLocationsToolTip(item, event) {
      this.toolTipContent = [];
      for (const binLocation of item.availableStock.L.binLocations) {
        this.toolTipContent.push(binLocation.nameData.displayName);
      }
      if (event.target !== this.toolTipTarget) {
        this.showToolTip = true;
      } else {
        this.showToolTip = !this.showToolTip;
      }
      this.toolTipX = event.x;
      this.toolTipY = event.y;
      this.toolTipTarget = event.target;
    },
    closeToolTip() {
      this.showToolTip = false;
      this.toolTipContent = [];
    }
  }
};
</script>

<style lang="scss">
.tooltip-container {
  top: 0;
  left: 0;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 999;
  margin: 0;
  padding: 0;
  border: 0;
}

.proposed-reorder {
  font-size: medium;
  color: black;
}

.product-image-container {
  margin-right: 5px;
}

.palette-scan-button {

}

.list-item {
  padding: 2px 0 0 0;
  margin: 0;
  min-height: 150px;

  .quantity {
    padding: 0;
    margin-right: 5px !important;
  }

  .image {
    padding: 0;
    margin: 0;
  }
}

.manually-reordered-info {
  color: cornflowerblue;
}

</style>
