<template>
  <v-dialog
      v-model="show"
      fullscreen
      class="dialog"
      transition="dialog-bottom-transition">
    <LoadingIndicator :loading="isLoading"/>
    <div class="video-container">
      <video ref="video" id="video" width="320" height="534" autoplay></video>
      <v-btn class="capture-button" color="secondary" ref="take-photo" @click="capture">Bild speichern</v-btn>
    </div>
    <canvas ref="canvas" class="canvas">
    </canvas>
  </v-dialog>
</template>

<script>
import LoadingIndicator from "../../Others/LoadingIndicator";

export default {
  components: {LoadingIndicator},
  props: {
    show: Boolean
  },

  data: () => ({
    isLoading: true,
    streamHeight: 0,
    streamWidth: 0,
    streamObj: null
  }),

  watch: {
    show: function (newVal) {
      if (newVal) {
        this.stream();
      }
    }
  },

  computed: {
    video() {
      return this.$refs.video;
    },
    canvas() {
      return this.$refs.canvas;
    }
  },

  methods: {
    stream() {
      const me = this;
      const constrains = {
        audio: false,
        video: {
          width: {min: 1560}
        }
      };
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.getUserMedia(constrains).then(function (stream) {
          me.streamObj = stream;
          me.video.srcObject = stream;
          me.video.play();
          me.video.onloadedmetadata = function () {
            me.streamWidth = this.videoWidth;
            me.streamHeight = this.videoHeight;
            me.isLoading = false;
          }
        });
      }
    },
    capture() {
      this.$root.$emit('photoStart', true);
      this.canvas.width = this.streamWidth;
      this.canvas.height = this.streamHeight;
      this.canvas.getContext('2d').drawImage(this.video, 0, 0);
      this.photo = this.canvas.toDataURL('image/jpeg', 0.95);
      this.$root.$emit('photoTaken', this.photo);
      this.stopStream();
    },

    stopStream() {
      this.video.pause();
      for (let track of this.streamObj.getTracks()) {
        track.stop();
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.canvas {
  display: none;
}

.video-container {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;

  .capture-button {
    position: absolute;
    bottom: 15px;
    left: 30%;
  }

  .dialog {
    background-color: black;
  }
}
</style>