<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <LoadingIndicator
          :loading="isLoading"/>
      <ScannerInner
          :should-emit="dialog"
      />
      <ErrorDialog
          :show="isError"
          :errorContent="errorContent"
      />
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>Artikel einlagern</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="emitCloseProductRelocationDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container class="departments-container" v-if="dialogArticle">
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Produktinformationen
        </v-alert>

        <v-container class="xs12 product-info-container">
          <v-row>
            <v-col>
              <v-img
                  :src="dialogArticle.imagePath"
                  width="100px"
                  min-width="100px"
              >
                <template v-slot:placeholder>
                  <ImageLoader/>
                </template>
              </v-img>
            </v-col>
            <v-layout align-center>
              <v-col>
                <v-row v-text="dialogArticle.name"/>
                <v-row v-if="dialogArticle.configurator"
                       v-text="createNameFromConfigurator(dialogArticle.configurator)"/>
                <v-row v-text="dialogArticle.ordernumber"/>
                <v-row v-if="dialogArticle.agiqonArticleSupplierQuantity"
                       v-text="'VPE Lager: ' + dialogArticle.agiqonArticleSupplierQuantity "/>
              </v-col>
            </v-layout>
          </v-row>
          <VerticalSpacer/>

          <div>
            <p class="scan-information">Scannen Sie jetzt den Lagerplatz ein, wo der Artikel eingelagert werden
              soll.</p>
            <v-row>
              <v-text-field
                  label="Lagerplatz scannen"
                  outlined
                  readonly
                  ref="scannerResult"
                  dense
                  prepend-icon="mdi-barcode-scan"
                  :value="scannerResult ? scannerResult.code : ''"

              ></v-text-field>
            </v-row>

            <v-autocomplete
                class="search-box"
                auto-select-first
                clearable
                filled
                solo
                dense
                label="Lagerplatzsuche"
                prepend-inner-icon="mdi-card-search"
                v-model="selectedBinLocation"
                :items="binLocations"
                item-text="nameData.displayName"
                return-object
                @change="onBinLocationChange"
            ></v-autocomplete>
            <v-checkbox
                :disabled="!selectedBinLocation"
                v-model="markAsDefaultBinLocation"
                label="Als Standardlagerplatz markieren"
            />
          </div>

          <VerticalSpacer/>

          <v-row class="reorder-quantity-container">
            <v-col class="text-right">
              <v-row class="action-label">Menge</v-row>
              <v-row class="text-center reorder-needed-quantity" @click="setMaxQuantity"><strong>{{
                  paletteStock
                }}x</strong>
              </v-row>
            </v-col>
            <div class="horizontal-separator">
            </div>
            <v-col @click="handleToolTip('if-no-bin-location')">
              <v-row class="action-label">Menge manuell angeben</v-row>
              <v-row justify="center">
                <v-icon :disabled="!selectedBinLocation" color="green" @click="addQuantity">mdi-plus</v-icon>
                <v-text-field v-model="selectedQuantity"
                              min="0"
                              aria-valuemin="0"
                              :max="maxValue"
                              :aria-valuemax="maxValue"
                              type="number"
                              :disabled="!selectedBinLocation"
                              :rules="inputRules"
                              @input="handleInputChange"
                              @change="handleInputChange"
                />
                <v-icon :disabled="!selectedBinLocation" color="red" @click="removeQuantity">mdi-minus</v-icon>
                <v-tooltip
                    v-model="showToolTip"
                    top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-btn>
                  </template>
                  <span>Zuerst den Lagerplatz scannen/auswählen!</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="info--text action-text-container">
            <p v-text="actionText"/>
          </v-row>

          <v-row class="reorder-button-container" @click="handleToolTip('if-no-bin-location')">
            <v-btn
                v-show="showAddButton"
                small
                color="success"
                class="ma-2 white--text"
                @click="emitArticleEdit('add')"
                ref="add"
                :disabled="!selectedBinLocation"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Bestätigen
            </v-btn>
            <v-btn
                v-show="showDoNothingButton"
                small
                color="grey"
                class="ma-2 white--text"
                @click="emitArticleEdit('same')"
                ref="same"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Keine Änder.
            </v-btn>
            <vertical-spacer/>
            <v-btn
                small
                color="error"
                class="ma-2 white--text"
                @click="emitCloseProductRelocationDialog"
            >
              <v-icon
                  left
                  dark
              >
                mdi-close-circle
              </v-icon>
              Abbrechen
            </v-btn>
          </v-row>

        </v-container>

      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
import ScannerInner from "../../Others/ScannerInner";
import ErrorDialog from "../../Others/ErrorDialog";
import ImageLoader from "../../Others/ImageLoader";
import VerticalSpacer from "../../Others/VerticalSpacer";
import LoadingIndicator from "../../Others/LoadingIndicator";

export default {
  components: {LoadingIndicator, VerticalSpacer, ImageLoader, ErrorDialog, ScannerInner},
  props: {
    dialog: Boolean,
    dialogArticle: Object,
    paletteBinLocationId: Number,
    paletteName: String,
    paletteStock: Number,
    warehouseCodeFrom: String
  },
  data: () => ({
    actionText: '',
    radioGroup: 0,
    selectedQuantity: 0,
    startQuantity: 0,
    scannerResult: '',
    isError: false,
    maxValue: 0,
    errorContent: '',
    showToolTip: false,
    isLoading: true,
    inputRules: [
      value => value >= 0 || 'Wert muss größer oder gleich 0 sein!'
    ],
    binLocations: [],
    showAddButton: true,
    showDoNothingButton: false,
    selectedBinLocation: null,
    markAsDefaultBinLocation: false
  }),
  created() {
    this.$root.$on('onScannerInputInner', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
    this.getBinLocations();
  },

  beforeDestroy() {
    this.$root.$off('onScannerInputInner', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  beforeMount() {
    this.selectedQuantity = this.dialogArticle.packedAmount;
    this.startQuantity = this.dialogArticle.packedAmount;
    this.maxValue = this.dialogArticle.packedAmount;
  },
  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if (!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    getBinLocations() {
      this.$axios.get('binLocation?warehouseCode=L').then(response => {
        if (response) {
          let binLocations = response.data.data;
          this.binLocations = binLocations;
          this.setDefaultBinLocation();
          this.isLoading = false;
        }
      });
    },
    setDefaultBinLocation() {
      let binLocations = this.dialogArticle.availableStock.L.binLocations;
      if (!binLocations) {
        return;
      }

      let defaultBinLocation = binLocations[0];
      const target = this.binLocations.filter(binLocation => binLocation.code === defaultBinLocation.code);
      if (target.length > 0) {
        this.selectedBinLocation = target[0];
        this.onBinLocationChange();
      }
    },
    onBinLocationChange() {
      if (this.selectedBinLocation) {
        this.scannerResult = this.selectedBinLocation;
        this.handleInputChange();
      } else {
        this.scannerResult = '';
        this.handleInputChange();
      }
    },
    emitArticleEdit(action) {
      if (action === 'add') {
        this.addProductQuantity();
      } else {
        this.$root.$emit('articleDialogDataChange', 'same');
      }
      this.$root.$emit('articleDialogCloseAction', true);
    },
    addProductQuantity() {
      this.$root.$emit('articleDialogDataChangeStart', 'add');

      const relocateParams = [{
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        quantity: Number(this.selectedQuantity),
        source: {
          binLocation: {
            id: this.paletteBinLocationId
          }
        },
        destination: {
          binLocation: {
            id: Number(this.selectedBinLocation.id)
          }
        }
      }];
      const address = 'stock/relocate/' + this.$uuid.v4();
      this.$axios.put(address, relocateParams).then(response => {
        if (response) {
          this.bookLog(
            address,
              relocateParams,
            Number(this.selectedQuantity),
            this.paletteName,
            this.selectedBinLocation.code,
            this.warehouseCodeFrom,
              'L'
          );
          this.$root.$emit('articleDialogDataChange', 'add');
          const params = {
            reduceAction: true,
            articleDetailId: Number(this.dialogArticle.articleDetailId),
            desiredQuantity: Number(this.selectedQuantity),
          };
          this.$axios.put('manualReorder/1', params).then(() => {
            if (this.markAsDefaultBinLocation) {
              const params = {
                articleDetailId: Number(this.dialogArticle.articleDetailId),
                binLocationId: Number(this.selectedBinLocation.id),
                warehouseCode: 'L'
              };
              this.$axios.post('defaultBinLocation', params);
            }
          });
        }
      });
    },
    emitCloseProductRelocationDialog() {
      this.$root.$emit('articleDialogCloseAction', true);
    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const binLocationCode = scannerInput.dataAsText.split('$')[1];

      if (!binLocationCode) {
        this.isError = true;
        this.errorContent = scannerInput.dataAsText + ' ist kein gültiger Lagerplatz!';
        this.scannerResult = '';
        return this.$refs.scannerResult.blur();
      }

      const foundedBinLocation = this.binLocations.filter(binLocation => binLocation.code === binLocationCode);
      if (foundedBinLocation.length > 0) {
        this.isError = false;
        this.selectedBinLocation = foundedBinLocation[0];
        this.scannerResult = foundedBinLocation[0];
        this.onBinLocationChange();
      } else {
        this.isError = true;
        this.errorContent = 'Lagerplatz "' + binLocationCode + '" nicht gefunden im Markt!';
        this.scannerResult = '';
      }
      this.$refs.scannerResult.blur();
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    handleToolTip(condition = '') {
      if (condition === 'if-no-bin-location' && this.selectedBinLocation) {
        return;
      }
      if (this.radioGroup === 0) {
        this.showToolTip = true;
        setTimeout(() => {
          this.showToolTip = false;
        }, 1500);
      }
    },
    handleInputChange() {
      if (!this.selectedBinLocation) {
        this.selectedQuantity = this.maxValue;
        return this.actionText = '';
      }
      this.selectedQuantity = Number(this.selectedQuantity);
      if (this.selectedQuantity > this.maxValue && this.selectedQuantity === 0) {
        this.selectedQuantity = this.maxValue;
      }
      if (this.selectedQuantity === 0) {
        this.showAddButton = false;
        this.showDoNothingButton = true;
        this.actionText = '';
      } else {
        this.showAddButton = true;
        this.showDoNothingButton = false;
        this.actionText = `${this.paletteName} => ${Number(this.selectedQuantity)}x => ${this.selectedBinLocation.nameData.displayName}`;
      }
    },
    handleRadioGroup(binLocation) {
      this.maxValue = Number(binLocation.stock);
      if (this.maxValue < Number(this.selectedQuantity) && Number(this.startQuantity) === 0) {
        this.selectedQuantity = this.maxValue;
      }
    },
    setMaxQuantity() {
      this.selectedQuantity = this.paletteStock;
      this.handleInputChange();
    },
    addQuantity() {
      if (this.dialogArticle.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.dialogArticle.agiqonArticleSupplierQuantity) <= this.maxValue) {
          if (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity) {
            this.selectedQuantity += this.dialogArticle.agiqonArticleSupplierQuantity - (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity);
          } else {
            this.selectedQuantity += this.dialogArticle.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxValue;
        }
      } else {
        if ((this.selectedQuantity + 1) <= this.maxValue) {
          this.selectedQuantity = ++this.selectedQuantity;
        }
      }
      if (this.selectedQuantity > this.maxValue) {
        this.selectedQuantity = this.maxValue;
      }
      this.handleInputChange();
    },
    removeQuantity() {
      if (this.dialogArticle.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.startQuantity - this.dialogArticle.agiqonArticleSupplierQuantity) >= 0) {
          if (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity) {
            const rest = this.dialogArticle.agiqonArticleSupplierQuantity - this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity;
            this.selectedQuantity = this.selectedQuantity - (this.dialogArticle.agiqonArticleSupplierQuantity - rest);
          } else {
            this.selectedQuantity -= this.dialogArticle.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxValue;
        }
      } else {
        if ((this.selectedQuantity - 1) >= 0) {
          this.selectedQuantity = --this.selectedQuantity;
        }
      }
      if (this.selectedQuantity < 0) {
        this.selectedQuantity = 0;

      }
      this.handleInputChange();
    },

    async bookLog(payloadUrl, payload, quantity, binLocationCodeFrom, binLocationCodeTo, warehouseCodeFrom, warehouseCodeTo) {
      await this.$axios.post('bookingLog', {
        userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
        articleId: Number(this.dialogArticle.articleID),
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        articleNumber: this.dialogArticle.ordernumber,
        articleName: this.dialogArticle.name,
        quantity,
        binLocationCodeFrom: binLocationCodeFrom,
        binLocationCodeTo: binLocationCodeTo,
        warehouseCodeFrom,
        warehouseCodeTo,
        payload,
        payloadUrl,
        place: 'Artikel einlagern'
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.search-box {
  margin-top: 10px;
}

.action-label {
  font-size: small;
}

.scan-information {
  margin-bottom: 50px;
}
</style>