<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
  >
    <v-card>
      <ScannerInner
        :should-emit="dialog"
      />
      <ErrorDialog
          :show="isError"
          :errorContent="errorContent"
      />
      <v-toolbar
          dark
          color="primary"
      >
        <v-toolbar-title>Artikel zurück ins Lager</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
              icon
              dark
              @click="emitCloseProductRelocationDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container class="departments-container" v-if="dialogArticle">
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Produktinformationen
        </v-alert>

        <v-container class="xs12 product-info-container">
          <v-row>
            <v-col>
              <v-img
                  :src="dialogArticle.imagePath"
                  width="100px"
                  min-width="100px"
              >
                <template v-slot:placeholder>
                  <ImageLoader/>
                </template>
              </v-img>
            </v-col>
            <v-layout align-center>
              <v-col>
                <v-row v-text="dialogArticle.name"/>
                <v-row v-if="dialogArticle.configurator" v-text="createNameFromConfigurator(dialogArticle.configurator)"/>
                <v-row v-text="dialogArticle.ordernumber"/>
                <v-row v-if="dialogArticle.agiqonArticleSupplierQuantity" v-text="'VPE Lager: ' + dialogArticle.agiqonArticleSupplierQuantity "/>
              </v-col>
            </v-layout>
          </v-row>
          <VerticalSpacer/>
          <v-row>
            <v-text-field
                label="Lagerplatz scannen"
                outlined
                readonly
                dense
                ref="scannerResult"
                prepend-icon="mdi-barcode-scan"
                :value="scannerResult ? scannerResult : ''"

            ></v-text-field>
          </v-row>
          <v-row>
            <v-spacer/>
            <v-alert
                dense
                type="info"
                outlined
            >
              Lagerplätze inkl. Verfügbarkeit
            </v-alert>
            <v-spacer/>
            <v-simple-table class="table-full-width table-content">
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left">
                    Lagerplatz
                  </th>
                  <th class="text-left">
                    Verfügbare Stückzahl
                  </th>
                  <th class="text-left">
                    Auswählen
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="binLocation in dialogArticle.availableStock.M.binLocations"
                    :key="binLocation.code"
                >
                  <td>{{ binLocation.nameData.displayName }}</td>
                  <td>{{ binLocation.stock }}</td>
                  <td>
                    <v-radio-group v-model="radioGroup" @change="handleRadioGroup">
                      <v-radio
                          :key="binLocation.code"
                          :value="binLocation"
                      />
                    </v-radio-group>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-row>
          <VerticalSpacer/>

          <v-row class="reorder-quantity-container">
            <v-col class="text-right">
              <v-row>Verpackte</v-row>
              <v-row class="text-center reorder-needed-quantity"><strong>{{ startQuantity }}x</strong>
              </v-row>
            </v-col>
            <div class="horizontal-separator">
            </div>
            <v-col @click="handleToolTip">
              <v-row>Menge</v-row>
              <v-row justify="center">
                <v-icon :disabled="radioGroup === 0" color="green" @click="addQuantity">mdi-plus</v-icon>
                <v-text-field v-model="selectedQuantity"
                              min="0"
                              aria-valuemin="0"
                              :max="maxValue"
                              :aria-valuemax="maxValue"
                              type="number"
                              :disabled="radioGroup === 0"
                              :rules="inputRules"
                              @input="handleInputChange"
                              @change="handleInputChange"
                />
                <v-icon :disabled="radioGroup === 0" color="red" @click="removeQuantity">mdi-minus</v-icon>
                <v-tooltip
                    v-model="showToolTip"
                    top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                    >
                    </v-btn>
                  </template>
                  <span>Zuerst den Lagerplatz scannen/auswählen!</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="info--text action-text-container">
            <p v-text="actionText"/>
          </v-row>

          <v-row class="reorder-button-container">
            <v-btn
                v-show="showAddButton"
                small
                color="success"
                class="ma-2 white--text"
                @click="emitArticleEdit('add')"
                ref="add"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Bestätigen
            </v-btn>
            <v-btn
                v-show="showDoNothingButton"
                small
                color="grey"
                class="ma-2 white--text"
                @click="emitArticleEdit('same')"
                ref="same"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Keine Änder.
            </v-btn>
            <v-btn
                v-show="showSubtractButton"
                small
                color="info"
                class="ma-2 white--text"
                @click="emitArticleEdit('subtract')"
                ref="subtract"
            >
              <v-icon
                  left
                  dark
              >
                mdi-check-circle
              </v-icon>
              Zurückgeben
            </v-btn>
            <vertical-spacer/>
            <v-btn
                small
                color="error"
                class="ma-2 white--text"
                @click="emitCloseProductRelocationDialog"
            >
              <v-icon
                  left
                  dark
              >
                mdi-close-circle
              </v-icon>
              Abbrechen
            </v-btn>
          </v-row>

        </v-container>

      </v-container>
    </v-card>
  </v-dialog>

</template>

<script>
import ScannerInner from "../../Others/ScannerInner";
import ErrorDialog from "../../Others/ErrorDialog";
import ImageLoader from "../../Others/ImageLoader";
import VerticalSpacer from "../../Others/VerticalSpacer";

export default {
  components: {VerticalSpacer, ImageLoader, ErrorDialog, ScannerInner},
  props: {
    dialog: Boolean,
    dialogArticle: Object,
    paletteBinLocationId: Number,
    paletteName: String,
    warehouseCodeFrom: String
  },
  data: () => ({
    actionText: '',
    radioGroup: 0,
    selectedQuantity: 0,
    startQuantity: 0,
    scannerResult: '',
    isError: false,
    maxValue: 0,
    errorContent: '',
    showToolTip: false,
    inputRules: [
      value => value >= 0 || 'Wert muss größer oder gleich 0 sein!'
    ],
    showAddButton: false,
    showDoNothingButton: true,
    showSubtractButton: false
  }),
  created() {
    this.$root.$on('onScannerInputInner', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  beforeDestroy() {
    this.$root.$off('onScannerInputInner', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  beforeMount() {
    this.selectedQuantity = this.dialogArticle.packedAmount;
    this.startQuantity = this.dialogArticle.packedAmount;
  },
  methods: {
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    emitArticleEdit(action) {
      if (action === 'add') {
        this.addProductQuantity();
      } else if (action === 'subtract') {
        this.subtractProductQuantity();
      } else {
        this.$root.$emit('articleDialogDataChange', 'same');
      }
      this.$root.$emit('articleDialogCloseAction', true);
    },
    addProductQuantity() {
      this.$root.$emit('articleDialogDataChangeStart', 'add');

      const params = [{
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        quantity: Number(this.selectedQuantity) - Number(this.startQuantity),
        source: {
          binLocation: {
            id: Number(this.radioGroup.id)
          }
        },
        destination: {
          binLocation: {
            id: this.paletteBinLocationId
          }
        }
      }];
      const address = 'stock/relocate/' + this.$uuid.v4();
      this.$axios.put(address, params).then(response => {
        if (response) {
          this.bookLog(
              address,
              params,
              Number(this.selectedQuantity) - Number(this.startQuantity),
              this.radioGroup.code,
              this.paletteName,
              'M',
              this.warehouseCodeFrom
          );
          this.$root.$emit('articleDialogDataChange', 'add');
        }
      });
    },
    subtractProductQuantity() {
      this.$root.$emit('articleDialogDataChangeStart', 'subtract');

      const params = [{
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        quantity: Number(this.startQuantity) - Number(this.selectedQuantity),
        source: {
          binLocation: {
            id: this.paletteBinLocationId
          }
        },
        destination: {
          binLocation: {
            id: Number(this.radioGroup.id)
          }
        }
      }];
      const address = 'stock/relocate/' + this.$uuid.v4();
      this.$axios.put(address, params).then(response => {
        if (response) {
          this.bookLog(
              address,
              params,
              Number(this.startQuantity) - Number(this.selectedQuantity),
              this.paletteName,
              this.radioGroup.code,
              this.warehouseCodeFrom,
              'M'
          );
          this.$root.$emit('articleDialogDataChange', 'subtract');
        }
      });
    },
    emitCloseProductRelocationDialog() {
      this.$root.$emit('articleDialogCloseAction', true);
    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const binLocationCode = scannerInput.dataAsText.split('$')[1];
      if(!binLocationCode) {
        this.isError = true;
        this.errorContent = `${scannerInput.dataAsText} ist kein gültiger Lagerplatz!`;
        this.scannerResult = '';
        return this.$refs.scannerResult.blur();
      }
      const doesExists = this.dialogArticle.availableStock.M.binLocations.filter(binLocation => binLocation.code === binLocationCode).length > 0;
      if (doesExists) {
        this.isError = false;
        this.scannerResult = binLocationCode;
        const targetBinLocation = this.dialogArticle.availableStock.M.binLocations.filter(binLocation => binLocation.code === binLocationCode)[0];
        this.radioGroup = targetBinLocation;
        this.handleRadioGroup(targetBinLocation);
      } else {
        this.isError = true;
        this.errorContent = binLocationCode + ' nicht gefunden im ' + this.dialogArticle.availableStock.M.warehouseName + '!';
        this.scannerResult = '';
      }
      this.$refs.scannerResult.blur();
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    handleToolTip() {
      if (this.radioGroup === 0) {
        this.showToolTip = true;
        setTimeout(() => {
          this.showToolTip = false;
        }, 1500);
      }
    },
    handleInputChange() {
      this.selectedQuantity = Number(this.selectedQuantity);
      if (this.selectedQuantity > this.maxValue && this.selectedQuantity === 0) {
        this.selectedQuantity = this.maxValue;
      }
      if (this.selectedQuantity === this.startQuantity) {
        this.showAddButton = false;
        this.showDoNothingButton = true;
        this.showSubtractButton = false;
        this.actionText = '';

      } else if (this.selectedQuantity > this.startQuantity) {
        this.showAddButton = true;
        this.showDoNothingButton = false;
        this.showSubtractButton = false;
        this.actionText = `${this.radioGroup.nameData.displayName} => ${Number(this.selectedQuantity) - Number(this.startQuantity)}x => ${this.paletteName}`;
      } else {
        this.showAddButton = false;
        this.showDoNothingButton = false;
        this.showSubtractButton = true;
        this.actionText = `${this.paletteName} => ${Number(this.startQuantity) - Number(this.selectedQuantity)}x => ${this.radioGroup.nameData.displayName}`;
      }
    },
    handleRadioGroup(binLocation) {
      this.maxValue = Number(binLocation.stock);
      if (this.maxValue < Number(this.selectedQuantity) && Number(this.startQuantity) === 0) {
        this.selectedQuantity = this.maxValue;
      }
    },
    addQuantity() {
      if (this.dialogArticle.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.dialogArticle.agiqonArticleSupplierQuantity) <= this.maxValue) {
          if (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity) {
            this.selectedQuantity += this.dialogArticle.agiqonArticleSupplierQuantity - (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity);
          } else {
            this.selectedQuantity += this.dialogArticle.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxValue;
        }
      } else {
        if ((this.selectedQuantity + 1) <= this.maxValue) {
          this.selectedQuantity = ++this.selectedQuantity;
        }
      }
      if (this.selectedQuantity > this.maxValue) {
        this.selectedQuantity = this.maxValue;
      }
      this.handleInputChange();
    },
    removeQuantity() {
      if (this.dialogArticle.agiqonArticleSupplierQuantity) {
        if ((this.selectedQuantity + this.startQuantity - this.dialogArticle.agiqonArticleSupplierQuantity) >= 0) {
          if (this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity) {
            const rest = this.dialogArticle.agiqonArticleSupplierQuantity - this.selectedQuantity % this.dialogArticle.agiqonArticleSupplierQuantity;
            this.selectedQuantity = this.selectedQuantity - (this.dialogArticle.agiqonArticleSupplierQuantity - rest);
          } else {
            this.selectedQuantity -= this.dialogArticle.agiqonArticleSupplierQuantity;
          }
        } else {
          this.selectedQuantity = this.maxValue;
        }
      } else {
        if ((this.selectedQuantity - 1) >= 0) {
          this.selectedQuantity = --this.selectedQuantity;
        }
      }
      if (this.selectedQuantity < 0) {
        this.selectedQuantity = 0;
      }
      this.handleInputChange();
    },

    async bookLog(payloadUrl, payload, quantity, binLocationCodeFrom, binLocationCodeTo, warehouseCodeFrom, warehouseCodeTo) {
      await this.$axios.post('bookingLog', {
        userId: localStorage.getItem('userId') ? Number(localStorage.getItem('userId')) : null,
        userName: localStorage.getItem('userName') ? localStorage.getItem('userName') : null,
        articleId: Number(this.dialogArticle.articleID),
        articleDetailId: Number(this.dialogArticle.articleDetailId),
        articleNumber: this.dialogArticle.ordernumber,
        articleName: this.dialogArticle.name,
        quantity,
        binLocationCodeFrom: binLocationCodeFrom,
        binLocationCodeTo: binLocationCodeTo,
        warehouseCodeFrom,
        warehouseCodeTo,
        payload,
        payloadUrl,
        place: 'Palette zum Einlagern füllen'
      });
    }
  }
}
</script>