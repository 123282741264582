<template>
  <v-navigation-drawer
      class="main-navigation"
      app
      v-model="drawer"
      left
      temporary
      height="100%"
  >
    <v-list
        nav
        dense
        shaped
    >
      <v-subheader>NAVIGATION</v-subheader>
      <v-list-item-group
          v-model="group"
          color="primary"
          ref="navList"
      >

        <template v-for="navItem of navItems">
          <v-subheader v-if="navItem.subHeader" v-text="navItem.subHeader" v-bind:key="navItem.index"/>
          <v-list-item
              v-bind:key="navItem.index"
              v-else
              @change="navigationChanged"
              :to="{ name: navItem.route }"
              :tabindex="navItem.index"
              :exact="navItem.index === 0"
          >
            <v-list-item-icon>
              <v-icon>{{ navItem.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="navItem.name"/>
            </v-list-item-content>
          </v-list-item>
        </template>


      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data: () => ({
    drawer: false,
    group: null,
    navItems: [
      {index: 0, name: 'Login', icon: 'mdi-account', route: 'login'},
      {index: 1, name: 'Picklisten', icon: 'mdi-view-list', route: 'departments'},
      {index: 2, name: 'Produktinformationen', icon: 'mdi-clipboard-text-search', route: 'products'},
      {index: 3, name: 'Lagerplatzinformationen', icon: 'mdi-clipboard-text-search', route: 'binLocations'},
      {index: 91, subHeader: 'Paletten'},
      {index: 4, name: 'Umlagern', icon: 'mdi-database-export', route: 'rearrange.palette', subHeaderIndex: 91},
      {index: 5, name: 'Im Lager einlagern', icon: 'mdi-database-arrow-down', route: 'rearrange.warehouse', subHeaderIndex: 91},
      {index: 92, subHeader: 'Lager'},
      {index: 6, name: 'Artikel einlagern', icon: 'mdi-database-arrow-down', route: 'rearrange.warehouse_product', subHeaderIndex: 92},
      {index: 7, name: 'Artikel umlagern', icon: 'mdi-database-arrow-up', route: 'rearrange.between_warehouse', subHeaderIndex: 92},

      {index: 93, subHeader: 'Markt'},
      {index: 8, name: 'Artikel verräumen', icon: 'mdi-database-marker', route: 'clearaway.select_palette', subHeaderIndex: 93},
      {
        index: 9,
        name: 'Palette zum Einlagern füllen',
        icon: 'mdi-keyboard-return',
        route: 'return.articles',
        subHeaderIndex: 93
      },
      {index: 10, name: 'Artikel anfordern', icon: 'mdi-reload', route: 'reorder', subHeaderIndex: 93},
      {index: 94, subHeader: 'Reklamation'},
      {index: 11, name: 'Anlegen', icon: 'mdi-send', route: 'complaint.send', subHeaderIndex: 94},
      {index: 12, name: 'Übersicht', icon: 'mdi-playlist-edit', route: 'complaint.overview', subHeaderIndex: 94}
    ]
  }),
  mounted() {
    this.$root.$on('drawStatusChanged', this.handleDrawerHanged);
    this.navigationChanged();
  },

  beforeDestroy() {
    this.$root.$off('drawStatusChanged', this.handleDrawerHanged);
  },

  methods: {
    handleDrawerHanged(drawer) {
      this.drawer = drawer;
    },
    navigationChanged(targetIndex) {
      if (!targetIndex) {
        targetIndex = this.$el.getElementsByClassName('v-item--active')[0].getAttribute('tabindex');
        targetIndex = parseInt(targetIndex);
      }

      const targetNavItem = this.navItems.filter(navItem => navItem.index === targetIndex)[0];
      let subHeaderName = '';
      if (targetNavItem.subHeaderIndex) {
        subHeaderName = this.navItems.filter(navItem => navItem.index === targetNavItem.subHeaderIndex)[0]['subHeader'] + ': ';
      }
      this.$root.$emit('navigationItemChanged', subHeaderName + targetNavItem.name);
    },
  }
}
</script>

<style lang="scss">
a {
  text-decoration: none;
}

.main-navigation {
  height: 80vh;
}
</style>