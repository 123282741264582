<template>
  <v-container>
    <LoginRequired/>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"/>
    <ConfirmDialog
        v-if="marktObj && bahnhofMarktObj"
        :leftButton="marktObj.name"
        :right-button="bahnhofMarktObj.name"
        ref="pickWarehouse"
    />
    <template class="rearrange-palette-container" v-if="isLoaded">
      <template>
        <v-alert
            color="primary"
            elevation="24"
            icon="mdi-information-outline"
            type="info"
        >
          Einlagerung vom Markt zum Lager
        </v-alert>

        <v-container class="palette-information-container">
          <v-alert
              color="primary"
              elevation="24"
              outlined
              v-model="showStartInfo"
              @click="toggleInfo"
          >
            <v-icon color="primary">mdi-information-outline</v-icon>
            <p>Scannen Sie die gewünschte Palette, um den Pickvorgang zu starten.</p>
            <p>Den Barcode finden Sie auf der linken Seite neben dem EUR Zeichen.</p>
            <p>Alternativ können Sie die Palette auch manuell auswählen.</p>
          </v-alert>
          <v-layout justify-center>
            <v-alert
                color="primary"
                elevation="24"
                outlined
                width="60px"
                v-model="showIconOnly"
                @click="toggleInfo"
            >
              <v-icon color="primary">mdi-information-outline</v-icon>
            </v-alert>
          </v-layout>
        </v-container>

        <v-container class="text-align-center">
          <p>{{ selectedPalette ? selectedPalette.names.displayName : 'Palette wählen' }}</p>
          <v-text-field
              :value="selectedPalette ? selectedPalette.code : ''"
              label="Palette scannen"
              outlined
              readonly
              ref="scannerResult"
              dense
              prepend-icon="mdi-barcode-scan"
              style="margin: auto"
          ></v-text-field>
          <v-btn
              color="blue-grey"
              class="ma-2 white--text"
              :disabled="selectedPalette === null"
              @click="askFromWherePalette"
          >
            <v-icon
                left
                dark
            >
              mdi-cart-arrow-right
            </v-icon>
            Palette<br/>
            verwenden
          </v-btn>
        </v-container>
        <h5>Manuelle Auswahl der Palette</h5>
        <v-virtual-scroll
            :items="palettes"
            :item-height="55"
            :height="200"
            class="palette-scroll"
        >
          <template v-slot:default="{ item }">
            <v-list-item :key="item.code" class="palette-list-item">
              <v-btn
                  @click="selectPalette(item)"
                  block
                  color="secondary"
                  class="white--text palette-button"
                  :disabled="!item.isEmpty && item.usedIn[0].warehouseCode !== 'BhfM' && item.usedIn[0].warehouseCode !== 'M'"
              >
                {{ item.names.displayName }}
                <div v-if="item.usedIn && item.usedIn.length > 0" class="palette-button-subtitle">{{
                    item.usedIn[0].warehouseCode === 'BhfM' || item.usedIn[0].warehouseCode === 'M' ? ' - Fortsetzen' : '[' + item.usedIn[0].warehouseName + ']'
                  }}
                </div>
              </v-btn>
            </v-list-item>
          </template>
        </v-virtual-scroll>
      </template>
    </template>
    <v-container v-else>
      <v-progress-circular
          indeterminate
          color="primary"
      ></v-progress-circular>
    </v-container>

    <VerticalSpacer/>

  </v-container>
</template>


<script>
import VerticalSpacer from "../../Others/VerticalSpacer";
import LoginRequired from "../../Others/LoginRequired";
import Scanner from "../../Others/Scanner";
import ErrorDialog from "../../Others/ErrorDialog";
import ConfirmDialog from "../../Others/ConfirmDialog";

export default {
  components: {ConfirmDialog, ErrorDialog, Scanner, LoginRequired, VerticalSpacer},
  data: () => ({
    showStartInfo: false,
    showIconOnly: true,
    selectedPalette: null,
    warehouses: null,
    warehouseFrom: 'BhfM',
    warehouseFromObj: null,
    marktObj: null,
    bahnhofMarktObj: null,
    isLoaded: false,
    result: null,
    isError: false,
    errorContent: '',
    palettes: null
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);

    this.$axios.get('palette').then(response => {
      if (response && response.data && response.data.data) {
        this.warehouses = response.data.data;
        this.warehouseFromObj = this.warehouses.filter(warehouse => warehouse.code === this.warehouseFrom)[0];
        this.palettes = this.warehouseFromObj.palettes.filter(palette => palette.isEmpty || palette.usedIn[0].warehouseCode === 'BhfM' || palette.usedIn[0].warehouseCode === 'M');
        this.marktObj = this.warehouses.filter(warehouse => warehouse.code === 'M')[0];
        this.bahnhofMarktObj = this.warehouses.filter(warehouse => warehouse.code === 'BhfM')[0];
        this.isLoaded = true;
      }
    })
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  methods: {
    toggleInfo() {
      this.showStartInfo = !this.showStartInfo;
      this.showIconOnly = !this.showIconOnly;
    },
    selectPalette(palette) {
      this.selectedPalette = palette;
    },
    onScannerInputHandler(scannerInputRaw) {
      const scannerInput = this.parseJson(scannerInputRaw);
      const paletteCode = scannerInput.dataAsText.split('$')[1];
      if (!paletteCode) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`${scannerInput.dataAsText} ist keine gültige Eingabe für Lagerplatz!`);
      }
      const targetPaletteFrom = this.warehouseFromObj.palettes.filter(palette => palette.code === paletteCode)[0];

      if (!targetPaletteFrom) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} kann nicht gefunden werden!`);
      }

      if (!targetPaletteFrom.isEmpty && !this.usedInWarehouse(targetPaletteFrom, 'BhfM') && !this.usedInWarehouse(targetPaletteFrom, 'M')) {
        this.selectPalette(null);
        this.$refs.scannerResult.blur();
        return this.setScannerError(`Palette ${paletteCode} ist nicht leer!`);
      }

      this.selectPalette(targetPaletteFrom);
      this.$refs.scannerResult.blur();
    },

    handleCloseErrorDialog() {
      this.isError = false;
    },

    setScannerError(message) {
      this.isError = true;
      this.errorContent = message;
    },

    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },

    askFromWherePalette() {
      if (!this.selectedPalette.isEmpty) {
        const usedInWarehouseCode = this.selectedPalette.usedIn[0].warehouseCode;
        let binLocationId;
        if (usedInWarehouseCode === 'M') {
          binLocationId = Number(
              this.marktObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0].id
          );
        } else {
          binLocationId = Number(
              this.bahnhofMarktObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0].id
          );
        }
        this.$router.push({
          name: 'return.articles.edit_palette',
          params: {
            binLocationId: binLocationId,
            paletteName: this.selectedPalette.names.displayName,
            warehouseCodeFrom: usedInWarehouseCode
          }
        });
      } else {
        this.$refs.pickWarehouse.open('Lager auswählen', 'Woher kommt diese Palette?', {color: 'info'}).then((confirm) => {
          let binLocationId;
          let warehouseCode;
          if (confirm === true) {
            binLocationId = Number(
                this.marktObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0].id
            );
            warehouseCode = 'M';
          } else {
            binLocationId = Number(
                this.bahnhofMarktObj.palettes.filter(palette => palette.code === this.selectedPalette.code)[0].id
            );

            warehouseCode = 'BhfM';
          }

          this.$router.push({
            name: 'return.articles.edit_palette',
            params: {
              binLocationId: binLocationId,
              paletteName: this.selectedPalette.names.displayName,
              warehouseCodeFrom: warehouseCode
            }
          });
        });
      }
    },
    usedInWarehouse(targetPalette, warehouseCode) {
      let usedIn = true;
      for (const binLocation of targetPalette.usedIn) {
        if (binLocation.warehouseCode !== warehouseCode) {
          usedIn = false;
          break;
        }
      }
      return usedIn;
    }
  }
}
</script>