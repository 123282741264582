var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('LoginRequired'),_c('Scanner'),_c('ErrorDialog',{attrs:{"show":_vm.isError,"errorContent":_vm.errorContent}}),(_vm.isLoaded)?[[_c('v-alert',{attrs:{"color":"primary","elevation":"24","icon":"mdi-information-outline","type":"info"}},[_vm._v(" Komplette Palette einlagern ")]),_c('v-container',{staticClass:"palette-information-container"},[_c('v-alert',{attrs:{"color":"primary","elevation":"24","outlined":""},on:{"click":_vm.toggleInfo},model:{value:(_vm.showStartInfo),callback:function ($$v) {_vm.showStartInfo=$$v},expression:"showStartInfo"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information-outline")]),_c('p',[_vm._v("Scannen Sie die Palette, deren kompletten Artikelbestand Sie auf einen Lagerplatz im Lager buchen wollen.")])],1),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-alert',{attrs:{"color":"primary","elevation":"24","outlined":"","width":"60px"},on:{"click":_vm.toggleInfo},model:{value:(_vm.showIconOnly),callback:function ($$v) {_vm.showIconOnly=$$v},expression:"showIconOnly"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-information-outline")])],1)],1)],1),(_vm.palettes)?_c('v-container',{staticClass:"text-align-center"},[_c('v-text-field',{ref:"scannerResult",staticStyle:{"margin":"auto"},attrs:{"value":_vm.selectedPalette ? _vm.selectedPalette.code : '',"label":"Palette scannen","outlined":"","readonly":"","dense":"","prepend-icon":"mdi-barcode-scan"}}),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey","disabled":_vm.selectedPalette === null,"to":{
              name: 'rearrange.warehouse.select_bin_location',
              params: _vm.selectedPalette ? {
                binLocationId: _vm.selectedPalette.usedIn[0]['binLocationId'],
                paletteName: _vm.selectedPalette.names.displayName,
                warehouseName: _vm.selectedPalette.warehouseName,
                warehouseCode: _vm.selectedPalette.usedIn[0]['warehouseCode'],
              } : {
                binLocationId: 0,
                paletteName: 'none',
                warehouseName: 'none',
                warehouseCode: 'none'
              }}}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-cart-arrow-right ")]),_vm._v(" Palette"),_c('br'),_vm._v(" auswählen ")],1)],1):_vm._e(),_c('h5',[_vm._v("Manuelle Auswahl der Palette")]),_c('v-virtual-scroll',{staticClass:"palette-scroll",attrs:{"items":_vm.palettes,"item-height":55,"height":200},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('v-list-item',{key:item.code,staticClass:"palette-list-item"},[_c('v-btn',{staticClass:"white--text palette-button",attrs:{"block":"","color":"secondary","disabled":item.isEmpty},on:{"click":function($event){return _vm.selectPalette(item)}}},[_vm._v(" "+_vm._s(item.names.displayName)),(item.warehouseName)?_c('span',[_vm._v(" - ["+_vm._s(item.warehouseName)+"]")]):_vm._e()])],1)]}}],null,false,1938392904)})]]:_c('v-container',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1),_c('VerticalSpacer')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }