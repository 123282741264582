<template class="no-print">
  <v-container class="no-print" fluid>
    <LoginRequired/>
    <LoadingIndicator :loading="isLoading"/>
    <DetailDialog
        :show="showDetailDialog"
        :complaint="complaintData"
    />
    <br />
    <v-btn
        :to="{name: 'complaint.send'}"
        color="primary"
        block
    >
      Reklamation anmelden
    </v-btn>
    <br />
    <v-container fluid>
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Offenen Reklamationen
      </v-alert>
    </v-container>

    <v-card
        width="100%"
        class="mx-auto no-print"
        v-if="complaints.length > 0 && !virtualScrollUpdated"
    >
      <v-virtual-scroll
          class="no-print"
          :items="complaints"
          item-height="150"
          ref="virtualScroll"
      >
        <template v-slot:default="{ item }">
          <v-list-item
              :key="item.number"
              @click="openEditDialog(item)"
              class="picklist-item"
          >

            <v-list-item-action>
              <v-card
                  class="pa-2 product-image-container"
                  outlined
                  tile
                  width="80"
                  height="80"
              >
                <v-img :src="item.images[0].path"
                       :aspect-ratio="1"
                >
                  <template v-slot:placeholder>
                    <ImageLoader/>
                  </template>
                </v-img>
              </v-card>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.createdDate }} Uhr
              </v-list-item-title>
              <v-list-item-title>
                <strong>{{ item.quantity }} x {{ item.articleName }}</strong>
              </v-list-item-title>
              <v-list-item-title v-if="item.configurator">
                {{ createNameFromConfigurator(item.configurator) }}
              </v-list-item-title>
              <v-list-item-title>
                {{ item.articleNumber }}
              </v-list-item-title>
              <v-list-item-title>
                Reklamationsgrund: <strong>{{ item.reason }}</strong>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </v-card>
  </v-container>
</template>

<script>

import LoadingIndicator from "../../Others/LoadingIndicator";
import LoginRequired from "../../Others/LoginRequired";
import ImageLoader from "../../Others/ImageLoader";
import DetailDialog from "./DetailDialog";

export default {
  components: {DetailDialog, ImageLoader, LoginRequired, LoadingIndicator},
  data: () => ({
    isLoading: true,
    complaintData: null,
    showDetailDialog: false,
    complaints: [],
    virtualScrollUpdated: false
  }),

  created() {
    this.getComplaints();
    this.$root.$on('detailDialogClosed', this.closeEditDialog);
  },

  beforeDestroy() {
    this.$root.$off('detailDialogClosed', this.closeEditDialog);
  },

  methods: {
    getComplaints() {
      this.$axios.get('complaint').then(response => {
        if (response && response.data && response.data.data) {
          this.complaints = response.data.data;
          this.isLoading = false;
        }
      });
    },
    openEditDialog(item) {
      this.complaintData = item;
      this.showDetailDialog = true;
    },
    closeEditDialog() {
      this.complaintData = null;
      this.showDetailDialog = false;
    },
    createNameFromConfigurator(configurator) {
      let name = '';
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
  }

}
</script>

<style lang="scss" scoped>
.v-list-item__action {
  margin: 0 !important;
}
</style>