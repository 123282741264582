<template>
  <div>
    <v-container v-if="debug">
      <v-row>
        <v-col cols="8">
          <v-text-field v-model="debugInputValue" clearable dense ref="emitField" placeholder="To Emit..."
                        outlined></v-text-field>
        </v-col>
        <v-col cols="1">
          <v-btn dark color="red" @click="emitScannerResponse">Emit</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-btn dark color="red" @click="() => {emitScannerInput(this.exampleArticleScan)}">
            <v-icon dark>mdi-numeric-1-circle</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn dark color="red" @click="() => {emitScannerInput(this.exampleArticleEanScan)}">
            <v-icon dark>mdi-numeric-2-circle</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn dark color="red" @click="() => {emitScannerInput(this.exampleBinLocationScan)}">
            <v-icon dark>mdi-numeric-3-circle</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="3">
          <v-btn dark color="red" @click="() => {emitScannerInput(this.exampleBinLocationScanEscaped)}">
            <v-icon dark>mdi-numeric-4-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    listeners: [],
    running: false,
    lastReaderResponse: '',
    debug: false,
    exampleArticleScan: '4550000000000',
    exampleArticleEanScan: '123123123123',
    exampleBinLocationScan: '^3BhfM$99_Palette_01',
    exampleBinLocationScanEscaped: '%5E3BhfM$99_Palette_01',
    debugInputValue: '',
    xhr: null
  }),

  beforeDestroy() {
    if(this.xhr) {
      this.xhr.abort();
    }
    this.xhr = null;
  },

  props: {
    shouldEmit: {
      type: Boolean,
      required: true,
      default: false
    }
  },

  watch: {
    shouldEmit: {
      handler () {
        if(this.shouldEmit) {
          if(this.xhr) {
            this.xhr.abort();
          }
          this.readListener();
        } else {
          if(this.xhr) {
            this.xhr.abort();
          }
          this.xhr = null;
        }
      },
      immediate: true
    }
  },

  methods: {
    readListener: function () {
      const me = this;
      this.xhr = new XMLHttpRequest();
      const url = 'http://localhost:8080/scan?action=read_listener&output=json';
      this.xhr.open('GET', url);
      this.xhr.send();

      this.xhr.onerror = function (e) {
        console.error('ERROR OCCURRED', e);
      }

      this.xhr.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE && this.status === 200 && me.isStringJson(me.xhr.responseText)) {
          const jsonObject = JSON.parse(me.xhr.responseText);
          jsonObject.dataAsText = jsonObject.dataAsText.replace('\n', '');
          if(me.shouldEmit) {
            me.$root.$emit('onScannerInputInner', JSON.stringify(jsonObject));
            me.readListener();
          }
        }
      }
    },
    emitScannerResponse: function () {
      this.$root.$emit('onScannerInputInner', JSON.stringify({dataAsText: this.debugInputValue}));
    },
    emitScannerInput(input) {
      this.debugInputValue = input;
    },

    isStringJson(text) {
      try {
        JSON.parse(text);
      } catch (e) {
        return false;
      }
      return true;
    }
  },
}
</script>
