<template>
  <span class="article-status" :style="'background-color: ' + color + ';'">
  </span>
</template>

<style lang="scss">
.article-status {
  width: 5px;
  height: 50px;
}
</style>

<script>
export default {
  props: ['color']
}
</script>