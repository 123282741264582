<template>
  <div class="text-center">
    <v-dialog
        v-model="show"
        width="500"
        @click:outside="emitShowChange"
    >


      <v-card>
        <v-card-title class="headline error">
          Nicht gefunden!
        </v-card-title>

        <v-card-text>
          {{ errorContent }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              text
              @click="emitShowChange"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    show: Boolean,
    errorContent: String
  },
  methods: {
    emitShowChange() {
      this.$root.$emit('onCloseErrorDialog', false);
    }
  }
}
</script>