<template class="no-print">
  <v-dialog
      v-model="show"
      fullscreen
      :overlay-opacity="0"
      class="dialog no-print"
      transition="dialog-bottom-transition"
  >
    <PreviewPhoto
        :show="showImagePreview"
        :imageSrc="previewImageSrc"/>
    <PrintDialog
        v-if="showPrintDialog"
        :show="showPrintDialog"
        :complaint="complaint"/>

    <v-toolbar
        dark
        color="primary"
        class="no-print"
    >
      <v-toolbar-title>Details der Reklamation</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
            icon
            dark
            @click="closeErrorDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card>
      <v-container class="complaint-detail-container" v-if="complaint">
        <v-container class="xs12 product-info-container">
          <p>{{ complaint.createdDate }} <br/>
            <strong>{{ complaint.quantity }} x {{ complaint.articleName }}</strong> <br/>
            <span v-if="complaint.configurator">
              {{createNameFromConfigurator(complaint.configurator) }}<br/></span>
            {{ complaint.articleNumber }}<br/>

            Reklamationsgrund: <strong>{{ complaint.reason }}</strong> <br/>
            <span v-if="complaint.deliveryNumber">

          Lieferscheinnummer: <strong>{{ complaint.deliveryNumber }}</strong> <br/>
        </span>

            <template v-if="complaint.problem">
              Reklamationsdetail: <strong>{{ complaint.problem }}</strong> <br/>
            </template>
          </p>

          <template v-if="complaint.description">
          <p>Problembeschreibung: <br/>
            {{ complaint.description }}
          </p>

          <VerticalSpacer/>
          </template>
          <v-row>
            <v-btn
                @click="printComplaint"
                color="primary"
                block
                class="hide-from-print no-print"
            >
              Druckansicht
            </v-btn>
          </v-row>

          <VerticalSpacer/>

          <template v-if="complaint.images && !complaint.images[0].noPicture">
          Bilder: <br/>
          <v-row class="image-grid-container">
            <v-col
                v-for="image in complaint.images"
                :key="image.id"
                :value="image"
                class="d-flex child-flex"
                cols="4"
            >
              <v-img
                  :src="image.path"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  @click="openImagePreview(image.path)"
              >
                <template v-slot:placeholder>
                  <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                  >
                    <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>

          <VerticalSpacer/>
          </template>

          <v-row>
            <v-spacer/>
            <v-btn
                @click="closeErrorDialog"
                color="secondary"
                class="no-print"
            >
              Schließen
            </v-btn>
            <v-spacer/>
          </v-row>

        </v-container>

      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>

import PreviewPhoto from "../Send/PreviewPhoto";
import VerticalSpacer from "../../Others/VerticalSpacer";
import PrintDialog from "./PrintDialog";

export default {
  components: {PrintDialog, VerticalSpacer, PreviewPhoto},
  props: {
    show: Boolean,
    complaint: Object
  },

  data: () => ({
    showImagePreview: false,
    previewImageSrc: '',
    showPrintDialog: false
  }),

  created() {
    this.$root.$on('imagePreviewClosed', this.onImagePreviewClosed);
    this.$root.$on('printDialogClosed', this.onPrintDialogClosed);
  },

  beforeDestroy() {
    this.$root.$off('imagePreviewClosed', this.onImagePreviewClosed);
    this.$root.$off('printDialogClosed', this.onPrintDialogClosed);
  },

  methods: {
    closeErrorDialog() {
      this.$root.$emit('detailDialogClosed', true);
    },
    createNameFromConfigurator(configurator) {
      let name = '';
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    printComplaint() {
      this.showPrintDialog = true;
    },
    onImagePreviewClosed() {
      this.showImagePreview = false;
      this.previewImageSrc = '';
    },
    openImagePreview(imageSrc) {
      this.showImagePreview = true;
      this.previewImageSrc = imageSrc;
    },
    onPrintDialogClosed() {
      this.showPrintDialog = false;
    }
  }

}
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: black;
}
</style>