<template>
  <v-container fluid>
    <Scanner/>
    <ErrorDialog
        :show="isError"
        :errorContent="errorContent"
    />

    <v-container class="scanner-input">
      <v-row>
        <v-text-field
            v-model="scannedCode"
            @change="loadScannedBinLocation"
            label="Lagerplatz scannen"
            prepend-icon="mdi-barcode-scan"
            outlined
            ref="scannerResult"
            readonly
            dense
        >
        </v-text-field>
      </v-row>
    </v-container>

    <v-layout v-if="isLoading" align-content-center justify-center align-center>
      <v-progress-circular indeterminate
                           color="primary"/>
    </v-layout>
    <v-container class="departments-container" v-else-if="binLocationData">
      <v-alert
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
      >
        Lagerplatzinformationen
      </v-alert>
      <VerticalSpacer/>
      <v-card
          width="100%"
          class="mx-auto"
          v-if="binLocationData && binLocationData.length > 0"
      >
        <v-virtual-scroll
            :items="binLocationData"
            item-height="130"
            min-height="300"
            ref="virtualScroll"
        >
          <template v-slot:default="{ item }">
            <v-list-item
                :key="item.ordernumber"
                class="picklist-item"
                @click="goToProductInfo(item.ordernumber)"
            >
              <v-list-item-action class="quantity">
                <v-list-item-action-text class="proposed-reorder">{{ item.actualStock }}x</v-list-item-action-text>
              </v-list-item-action>
              <v-list-item-action>
                <v-card
                    class="pa-2 product-image-container"
                    outlined
                    tile
                    width="50"
                    height="50"
                >
                  <v-img :src="item.imagePath">
                    <template v-slot:placeholder>
                      <ImageLoader/>
                    </template>
                  </v-img>
                </v-card>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-title v-if="item.configurator">
                  {{ createNameFromConfigurator(item.configurator) }}
                </v-list-item-title>
                <v-list-item-title>
                  {{ item.ordernumber }}
                </v-list-item-title>
                <v-list-item-title v-if="item.agiqonArticleSupplierQuantity && parseInt(item.agiqonArticleSupplierQuantity) > 0">
                  VPE Lager: {{ item.agiqonArticleSupplierQuantity }}
                </v-list-item-title>

              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </template>
        </v-virtual-scroll>
      </v-card>
      <v-alert v-else
          color="primary"
          elevation="24"
          icon="mdi-information-outline"
          type="info"
          outlined
          rounded
      >
        Es befinden sich noch keine Artikel im gescannten Lagerplatz.
      </v-alert>
    </v-container>
  </v-container>
</template>

<script>
import ImageLoader from "../Others/ImageLoader";
import VerticalSpacer from "../Others/VerticalSpacer";
import Scanner from "../Others/Scanner";
import ErrorDialog from "../Others/ErrorDialog";

export default {
  components: {ErrorDialog, Scanner, VerticalSpacer, ImageLoader},
  data: () => ({
    showSearchIcon: false,
    ean: null,
    scannedCode: '',
    warehouseCode: '',
    binLocationCode: '',
    binLocationData: null,
    isLoading: false,
    isError: false,
    errorContent: '',
    total: 0
  }),
  created() {
    this.$root.$on('onScannerInput', this.onScannerInputHandler);
    this.$root.$on('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  mounted() {
    this.processInitialScannedCode();
  },

  beforeDestroy() {
    this.$root.$off('onScannerInput', this.onScannerInputHandler);
    this.$root.$off('onCloseErrorDialog', this.handleCloseErrorDialog);
  },

  methods: {
    processInitialScannedCode() {
      if(this.$route.params && this.$route.params.scannedPalette) {
        this.onScannerInputHandler(this.stringifyScannerInput(this.$route.params.scannedPalette));
      }
    },
    handleCloseErrorDialog(state) {
      this.isError = state;
    },
    createNameFromConfigurator(configurator) {
      let name = '';
      if(!configurator) {
        return name;
      }
      for (const [i, configuratorPair] of configurator.entries()) {
        const isLastIteration = configurator.length - 1 === i;
        name += configuratorPair.groupName + ': ' + configuratorPair.optionName;
        if (!isLastIteration) {
          name += ', ';
        }
      }
      return name;
    },
    loadScannedBinLocation() {
      if (this.scannedCode) {
        this.isLoading = true;
        this.$axios.get(`binLocationInfo?warehouseCode=${this.warehouseCode}&binLocationCode=${this.binLocationCode}`)
            .then(response => {
          this.isLoading = false;
          if (response.data && response.data.success === false) {
            this.isError = true;
            this.errorContent = response.data.message;
            this.resetScanner();
          }
          if (response.data && response.data.data) {
            this.isError = false;
            this.errorContent = '';
            this.binLocationData = response.data.data;
            this.calculateTotalStock();
          }
        })
      }
    },
    onScannerInputHandler(scannerOutput) {
      this.binLocationData = null;
      scannerOutput = this.parseJson(scannerOutput);
      const scannerOutputArray = scannerOutput.dataAsText.split('$');
      if(scannerOutputArray.length !== 2 || scannerOutputArray[0][0] !== '^') {
          return this.setScannerError(`Gescannter Code: ${scannerOutput.dataAsText} ist kein gültiger Lagerplatzcode.`);
      }

      this.scannedCode = scannerOutput.dataAsText;
      this.warehouseCode = scannerOutputArray[0].substring(2);
      this.binLocationCode = scannerOutputArray[1];
      this.$refs.scannerResult.$emit('change');
      this.$refs.scannerResult.blur();
    },
    parseJson(jsonString) {
      let jsonObj = {
        dataAsText: 'none'
      }
      try {
        jsonObj = JSON.parse(jsonString);
      } catch (e) {
        console.error(e);
      }
      return jsonObj;
    },
    calculateTotalStock() {
      let total = 0;
      if(this.binLocationData) {
        this.total = this.binLocationData.length;
      }

      this.total = total;
    },

    setScannerError(message) {
      this.isError = true;
      this.errorContent = message;
    },
    resetScanner() {
      this.scannedCode = '';
      this.warehouseCode = '';
      this.binLocationCode = '';
      this.binLocationData = null;
    },

    goToProductInfo(initialProductNumber) {
      this.$router.push({name: 'products', params: {initialProductNumber, scannedPalette: this.scannedCode}});
    },
    stringifyScannerInput(value) {
      return JSON.stringify({dataAsText: value});
    },
  }
};
</script>

<style lang="scss">
.table-full-width {
  width: 100%;
}

.reorder-button-container {
  margin-top: 50px !important;
}

.status-value {
  margin-left: 10px;
  font-size: large;
}

.table-content {
  max-height: 300px;
  overflow-y: scroll;
}
</style>