import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueRouter from 'vue-router';
import { uuid } from 'vue-uuid';

import Departments from './components/Departments/Departments';
import SingleDepartment from './components/Departments/SingleDepartment';
import Login from './components/Login';
import ProductInfo from './components/ProductInfo/ProductInfo';
import SelectPalette from './components/Departments/SelectPalette';
import Picklist from './components/Departments/Picklist';

import { default as RearrangeProductSelectPalette } from './components/Rearrange/Product/SelectPalette';
import { default as RearrangeProductSelectProduct } from './components/Rearrange/Product/SelectProduct';

import { default as RearrangePaletteSelectFromTo } from "./components/Rearrange/Palette/SelectFromTo";
import { default as RearrangePaletteSelectPalette } from './components/Rearrange/Palette/SelectPalette';

import { default as RearrangeToWarehouseSelectPalette } from './components/Rearrange/ToWarehouse/SelectPalette';
import { default as RearrangeToWarehouseSelectBinLocation } from './components/Rearrange/ToWarehouse/SelectBinLocation';

import { default as ArticleStorageSelectPalette }  from './components/Market/ArticleStorage/SelectPalette';
import { default as ArticleStorageEditPalette } from './components/Market/ArticleStorage/EditPalette';

import { default as BackToWarehouseSelectPalette } from './components/Market/BackToWarehouse/SelectPalette';
import { default as BackToWarehouseEditPalette } from './components/Market/BackToWarehouse/EditPalette';

import { default as ToWarehouseProductsSelectPalette } from './components/Rearrange/ToWarehouseProducts/SelectPalette';
import { default as ToWarehouseProductsEditPalette } from './components/Rearrange/ToWarehouseProducts/EditPalette';

import { default as BetweenWarehouseSelectWarehouse } from './components/Rearrange/BetweenWarehouse/SelectWarehouse';
import { default as BetweenWarehouseSelectProduct } from './components/Rearrange/BetweenWarehouse/SelectProduct';
import { default as BetweenWarehouseSelectFrom } from './components/Rearrange/BetweenWarehouse/FromBinLocation';
import { default as BetweenWarehouseSelectTo } from './components/Rearrange/BetweenWarehouse/ToBinLocation';


import Reorder from './components/Market/Reorder/Reorder';

import Send from './components/Complaint/Send/Send';
import Overview from './components/Complaint/Overview/Overview';
import BinLocationsInfo from "./components/BinLocationsInfo/BinLocationsInfo";

Vue.config.productionTip = false;

Vue.use(VueRouter);

let auth = '';
if(localStorage.getItem('apiAuthKey')) {
  auth = 'Basic ' + localStorage.getItem('apiAuthKey');
} else {
  auth = process.env.VUE_APP_API_AUTH;
}

Vue.prototype.$axios = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json',
    'Authorization': auth
  }
});

Vue.prototype.$uuid = uuid;

Vue.prototype.$warehouses = ['L', 'M', 'BhfL', 'BhfM'];

Vue.prototype.$placeholder = 'https://via.placeholder.com/100';

const routes = [

  { path: '/', name: 'login', component: Login },

  { path: '/departments', name: 'departments', component: Departments },
  { path: '/departments/:id', name: 'department', component: SingleDepartment },
  { path: '/departments/:id/selectPalette', name: 'selectPalette', component: SelectPalette },
  { path: '/departments/:id/selectPalette/:selectedPalette/', name: 'picklist', component: Picklist },

  { path: '/rearrange/product', name: 'rearrange.product.palette', component: RearrangeProductSelectPalette },
  { path: '/rearrange/product/:selectedPalette', name: 'rearrange.product.product', component: RearrangeProductSelectProduct },

  { path: '/rearrange/palette', name: 'rearrange.palette', component: RearrangePaletteSelectFromTo },
  { path: '/rearrange/palette/:from/:to', name: 'rearrange.palette.select_palette', component:  RearrangePaletteSelectPalette },

  { path: '/rearrange/warehouse', name: 'rearrange.warehouse', component: RearrangeToWarehouseSelectPalette },
  { path: '/rearrange/warehouse/:binLocationId/:paletteName/:warehouseName/:warehouseCode', name: 'rearrange.warehouse.select_bin_location', component: RearrangeToWarehouseSelectBinLocation },

  { path: '/rearrange/warehouseProduct', name: 'rearrange.warehouse_product', component: ToWarehouseProductsSelectPalette },
  { path: '/rearrange/warehouseProduct/:binLocationId/:warehouseCodeFrom/:paletteName', name: 'rearrange.warehouse_product.edit_palette', component: ToWarehouseProductsEditPalette },

  { path: '/rearrange/betweenWarehouse', name: 'rearrange.between_warehouse', component: BetweenWarehouseSelectWarehouse },
  { path: '/rearrange/betweenWarehouse/:warehouseId/:warehouseCode', name: 'rearrange.between_warehouse_select_product', component: BetweenWarehouseSelectProduct },
  { path: '/rearrange/betweenWarehouse/:warehouseId/:warehouseCode/:articleNumber/:articleDetailId', name: 'rearrange.between_warehouse_select_from', component: BetweenWarehouseSelectFrom },
  { path: '/rearrange/betweenWarehouse/:warehouseId/:warehouseCode/:articleNumber/:articleDetailId/:quantity/:binLocationId/:binLocationCode', name: 'rearrange.between_warehouse_select_to', component: BetweenWarehouseSelectTo },


  { path: '/clearaway/', name: 'clearaway.select_palette', component: ArticleStorageSelectPalette },
  { path: '/clearaway/editPalette/:binLocationId/:paletteName', name: 'clearaway.edit_palette', component: ArticleStorageEditPalette },

  { path: '/products/:initialProductNumber?/:scannedPalette?', name: 'products', component: ProductInfo },
  { path: '/binLocations/:scannedPalette?', name: 'binLocations', component: BinLocationsInfo },

  { path: '/return/', name: 'return.articles', component: BackToWarehouseSelectPalette },
  { path: '/return/:warehouseCodeFrom/:binLocationId/:paletteName', name: 'return.articles.edit_palette', component: BackToWarehouseEditPalette },

  { path: '/reorder', name: 'reorder', component: Reorder },

  { path: '/complaint/send', name: 'complaint.send', component: Send },
  { path: '/complaint/overview', name: 'complaint.overview', component: Overview }

]

const router = new VueRouter({
  routes
})

// Vue.component(VueNumberInput.name, VueNumberInput);

//to remove duplicates from merged arrays
Array.prototype.unique = function() {
  const a = this.concat();
  for(let i=0; i<a.length; ++i) {
    for(let j=i+1; j<a.length; ++j) {
      if(a[i] === a[j])
        a.splice(j--, 1);
    }
  }
  return a;
};


new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
