<template>
  <div class="preview-container" @click="onImageClick">
    <v-dialog
        v-model="show"
        fullscreen
        class="dialog"
        @click:outside="onImageClick"
        @click="onImageClick"
        transition="dialog-bottom-transition">
      <v-container fill-height fluid class="preview-container" @click="onImageClick">
        <v-row align="center"
               justify="center">
          <v-col>
            <v-img class="preview-image" :src="imageSrc" @click="onImageClick"/>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    show: Boolean,
    imageSrc: String
  },


  methods: {
    onImageClick() {
      this.$root.$emit('imagePreviewClosed', true);
    }
  }

}
</script>

<style lang="scss" scoped>
.preview-container {
  background-color: black;
}
</style>