<!-- empty template to stop debugger crying about no template -->
<template><span></span></template>

<script>
export default {
  created() {
    const isLogged = localStorage.getItem('isLogged');
    if (!isLogged) {
      this.$router.push({
        name: 'login', params: {
          errorMsg: 'Sie müssen angemeldet sein, um diese Funktionalität zu nutzen.',
          target: this.$route.name
        }
      });
    }
  }
}
</script>